<h2>{{'AGREEMENT_COLLECTION_AND_USE_TITLE'|translate}}</h2>
<div class="panel panel-default panel-noborder">
    <div class="panel-body text-justify">
        <ng-container *ngIf="translate.currentLang === 'fr'">
            <ng-container *ngTemplateOutlet="agreementCollectionAndUseFr"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="agreementCollectionAndUseEn"></ng-container>
        </ng-container>
    </div>
</div>

<h2>{{'AGREEMENT_CONSENT_AND_DELIVERY_ELECTRONIC_DOC_TITLE'|translate}}</h2>
<div class="panel panel-default panel-noborder">
    <div class="panel-body text-justify">
        <ng-container *ngIf="translate.currentLang === 'fr'">
            <ng-container *ngTemplateOutlet="agreementConsentAndDeliveryElectronicDocTitleFr"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="agreementConsentAndDeliveryElectronicDocTitleEn"></ng-container>
        </ng-container>
    </div>
</div>

<h2>{{'AGREEMENT_DISCLOSURE_AND_ACKNOWLEDGEMENT_TITLE'|translate}}</h2>
<div class="panel panel-default panel-noborder">
    <div class="panel-body text-justify">
        <ng-container *ngIf="translate.currentLang === 'fr'">
            <ng-container *ngTemplateOutlet="agreementDisclosureAndAcknowledgementFr"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="agreementDisclosureAndAcknowledgementEn"></ng-container>
        </ng-container>
    </div>
</div>

<div class="btn-resp btn-resp-1colonne-inline m-t-4">
    <div class="btn-resp-col btn-resp-demi">
        <button type="button"
                class="btn btn-primary loadable"
                [class.loading]="loading"
                [attr.disabled]="loading || isSaving ? true : null"
                (click)="submitForm()">
            {{'BTN_NEXT' | translate}}
        </button>
    </div>
    <div class="btn-resp-col btn-resp-demi">
        <button *ngIf="showBack()" type="button"
                class="btn btn-default"
                [attr.disabled]="loading || isSaving ? true : null"
                (click)="navBack()">
            {{'BTN_BACK' | translate}}
        </button>
    </div>
</div>

<div *ngIf="error != null" class="alert alert-danger">{{'GLOBAL_ERROR_MSG' | translate}}</div>

<ng-template #agreementCollectionAndUseFr>
    <p>Pour vous servir au quotidien et pour respecter nos obligations légales, nous devons recueillir, utiliser et
        partager des renseignements personnels à votre sujet. Nous vous invitons à lire notre Politique de
        confidentialité, disponible sur notre site Web à www.disnat.com/avis-legaux, pour obtenir plus de détails.</p>
    <hr>
    <p>Je comprends et j'accepte que ce consentement ait préséance sur tout autre consentement signé par le passé. Ce
        consentement demeure en vigueur tant et aussi longtemps que j'aurai une relation d'affaires avec une composante
        du Mouvement Desjardins.</p>
    <p>En signant ce formulaire, j'autorise Valeurs mobilières Desjardins inc. (VMD), dans le cadre de ses activités de
        courtage à escompte sous la dénomination commerciale Desjardins Courtage en ligne (DCL), à collecter, à utiliser
        et à partager mes renseignements personnels conformément à la réglementation en matière de protection des
        renseignements personnels et à sa Politique de confidentialité qui m'a été présentée avant de signer ce
        consentement.</p>
</ng-template>

<ng-template #agreementDisclosureAndAcknowledgementFr>
    <p>Valeurs mobilières Desjardins inc. (VMD), dans le cadre de ses activités de courtage à escompte sous la
        dénomination commerciale Desjardins Courtage en ligne (DCL), n’offre qu’un service de courtier exécutant. DCL
        ne fait pas et ne fera pas de recommandations à ses clients et n’a pas l’obligation de procéder à une
        évaluation de la convenance des opérations à l’occasion de l’acceptation des ordres d’un client. À titre de
        client de DCL, je reconnais et conviens que je suis le seul responsable de mes décisions de placement et que
        DCL ne tient pas et ne tiendra pas compte, au moment de l’acceptation de mes ordres, de ma situation financière,
        de mes connaissances en matière de placement, de mes objectifs de placement ni de ma tolérance à l’égard du
        risque. Je représente et garantis à DCL que je possède les connaissances et l’expérience requises pour prendre
        mes propres décisions en matière de placement et la capacité financière et une tolérance au risque suffisantes
        pour supporter des pertes ou des pertes de profits anticipées qui pourraient survenir en raison de mes choix
        d’investissement.</p>
    <p><b>Je suis informé que le Document d'information</b> est disponible en tout temps sur le site Web de DCL et qu'il me
        sera également transmis par courrier postal ou électronique à l'ouverture de mon compte. Ce document comprend
        les éléments suivants : (a) Modalités de la relation entre DCL et son client; (b) Description des produits et
        services; (c) Responsabilités de la société de courtage; (d) Grille tarifaire; (e) L'information sur les
        activités dans vos comptes; (f) Politique de confidentialité; (g) Traitement des plaintes; (h) Personne de
        confiance; (i) Convention générale de compte et conventions spécifiques; (j) Déclaration de principes sur les
        conflits d’intérêts; (k) Renseignements sur les marchés multiples; (l) Imposition américaine relative à
        certains types de revenus; (m) Obligations à coupons détachés et ensembles d’obligations à coupons détachés;
        (n) Opérations sur les contrats à terme et les options; et (o) Emprunts à des fins de placement sans inscription
        aux livres.</p>
    <p><b>Je déclare avoir lu et compris</b> non seulement chacune des dispositions du présent formulaire, mais également
        celles incluses dans le Document d’information appropriées aux types de comptes que je désire ouvrir et
        conviens que je suis lié par celles-ci, lesdites dispositions faisant partie intégrante des présentes. Je
        reconnais que DCL m’a expressément référé à ces dispositions et m’a fourni toutes les explications adéquates à
        l’égard de la nature et de la portée de telles dispositions.</p>
    <p>Je reconnais et garantis à DCL que j’ai reçu et compris la mise en garde fournie par DCL dans le paragraphe qui
        précède. Je peux m’adresser à un représentant de DCL pour toute question sur le contenu de ce document.</p>
    <p>Je déclare que tous les renseignements indiqués dans ce formulaire sont exacts et je m’engage à communiquer à
        DCL, dans les meilleurs délais, tout changement relatif aux présentes. J’autorise DCL à effectuer toute enquête
        de solvabilité qu’elle jugera appropriée.</p>
    <p>Je confirme avoir compris que Valeurs mobilières Desjardins inc. (VMD) utilise la dénomination commerciale
        Desjardins Courtage en ligne (DCL) pour ses activités de courtage à escompte. Les produits et services de
        courtage à escompte sont regroupés sous la marque de commerce Disnat. VMD est une entité distincte de la
        Fédération des caisses Desjardins du Québec ainsi que de ses caisses membres (ci-après, collectivement : les
        « Caisses »). Je comprends que dans certains cas, les places d’affaires de ces entités sont situées à la même
        adresse et partagent des bureaux. De plus, je comprends que les représentants de DCL exercent leur fonction
        uniquement pour le compte de VMD.</p>
    <p>D’autres parts, les titres achetés par l’entremise de DCL présentent les caractéristiques suivantes : (a) ils ne
        sont pas garantis par un organisme public d’assurance-dépôts; (b) ils ne sont pas garantis par les Caisses; et
        (c) ils peuvent subir les fluctuations de valeur. Toutefois, les CPG sont des dépôts au sens de la Loi sur les
        sociétés de fiducie et de prêt, et conforme aux dispositions de la Loi sur la société d’assurance dépôts du
        Canada. Ces dépôts sont assurables auprès de la Société d’assurance-dépôts du Canada (SADC).</p>
    <p>VMD est membre de l’Organisme canadien de réglementation du commerce des valeurs mobilières (OCRCVM) et du Fonds
        canadien de protection des épargnants (FCPE).</p>
    <p><b>Je reconnais avoir pris connaissance</b> de la section 8 et autorise la collecte, l’utilisation et la communication
        de mes renseignements personnels de la manière exposée dans le présent consentement et dans la Politique de
        confidentialité du Mouvement Desjardins. Ces renseignements seront conservés tant et aussi longtemps que VMD en
        aura besoin aux fins énoncées aux présentes, même si je ne faisais plus affaire avec VMD.</p>
    <p>Dans le cas d’une <b>demande d’ouverture de compte sur marge</b>, je reconnais avoir lu et j’accepte les modalités de
        la Convention générale de compte et de la Convention de compte sur marge incluses dans le Document
        d’information. Je comprends que l’exécution d’opérations dans un compte sur marge entraîne obligatoirement un
        emprunt de fonds.</p>
    <p>Dans le cas d’une <b>demande d’ouverture de compte sur marge-options</b>, je reconnais avoir lu et j’accepte les
        modalités de la Convention générale de compte, de la Convention de compte sur marge ainsi que la Convention de
        compte d’options incluses dans le Document d’information. Je comprends qu’un tel compte comporte des risques et
        que j’ai reçu et lu le Document d’information sur les risques à l’égard des contrats à terme et des options
        inclus dans le Document d’information.</p>
</ng-template>

<ng-template #agreementConsentAndDeliveryElectronicDocTitleFr>
    <p>Tel qu’autorisé par la législation en valeurs mobilières, Valeurs mobilières Desjardins inc. (VMD), dans le cadre
        de ses activités de courtage à escompte sous la dénomination commerciale Desjardins Courtage en ligne (DCL),
        peut me transmettre certains documents par voie électronique en les déposant sur une adresse URL. Afin de
        pouvoir recourir à ce mode de transmission de documents, DCL doit recueillir mon consentement à l’égard de ce
        qui suit.</p>
    <p>J’atteste avoir lu, compris et accepte le présent « Consentement à la transmission électronique de documents et
        conditions d’utilisation » et consens à la transmission des documents suivants : (a) Document d’information sur
        la relation avec les clients et Conventions; (b) Comment l’OCRCVM protège les investisseurs; (c) Fonds canadien
        de protection des épargnants (FCPE); (d) Dépôt d’une plainte – Guide de l’investisseur; et (e) Comment puis-je
        récupérer mon argent ? – Guide de l’investisseur. À ce titre, je consens et accepte que la remise de l’adresse
        URL donnant accès aux documents énumérés ci-dessus soit en lieu et place de la remise directe des documents.</p>
    <p>Je peux recevoir de DCL une copie papier, sans frais, de tout document transmis par voie électronique en
        communiquant avec l’équipe clientèle via les coordonnées indiquées à la section « Nous joindre ». Je ne suis pas
        tenu d’accepter la transmission électronique des documents et le présent consentement peut donc être révoqué en
        communiquant avec l’équipe clientèle de DCL. DCL peut modifier les conditions de ce consentement par suite d’un
        préavis de trente (30) jours transmis à mon adresse électronique.</p>
    <p>Je reconnais qu’en transmettant mon adresse électronique à DCL, je consens à ce que les documents énumérés
        ci-dessus me soient remis par voie électronique. Je confirme également avoir la capacité et les ressources
        technologiques pour y accéder. Enfin, j’accepte que ces documents me soient remis par l’accès à une adresse URL
        qui me sera transmise par DCL.</p>
</ng-template>

<ng-template #agreementCollectionAndUseEn>
    <p>To serve you effectively every day, provide you with products and services and fulfill our legal obligations, we
        need to collect, use and disclose information about you. You can read our Privacy Policy, available on our
        website at www.disnat.com/en/legal-notes, for full details.</p>
    <hr>
    <p>I acknowledge and accept that this consent takes precedence over any other consent I have previously signed. This
        consent remains in effect for as long as I maintain a business relationship with Desjardins Group.</p>
    <p>By signing this form, I authorize Desjardins Securities Inc. (Desjardins Securities), within its discount
        brokerage activities under the Desjardins Online Brokerage (DOB) trade name, to collect, use and disclose my
        personal information in accordance with privacy regulations and the Privacy Policy that was presented to me
        before signing this consent.</p>
</ng-template>
<!-- Troisieme section -->
<ng-template #agreementDisclosureAndAcknowledgementEn>
    <p>Desjardins Securities Inc., as part of its discount brokerage activities under the trade name Desjardins Online
        Brokerage, offers only an executing broker service. Desjardins Online Brokerage does not make and will not make
        recommendations to its clients and does not have the obligation to assess the suitability of transactions when
        accepting a client's orders. As a Desjardins Online Brokerage client, I acknowledge and agree that I am solely
        responsible for my investment decisions and that Desjardins Online Brokerage does not, and will not, take into
        account, when accepting my orders, my financial situation, investment knowledge, investment objectives or risk
        tolerance. I represent and warrant to Desjardins Online Brokerage that I have the knowledge and experience
        required to make my own investment decisions as well as sufficient financial capacity and risk tolerance to
        withstand anticipated losses or profit losses that may arise as a result of my investment choices.</p>
    <p><b>I have been informed that the Relationship Disclosure Document</b> is available at all times on the Desjardins
        Online Brokerage website and that it will also be sent to me by postal or electronic mail when my account is
        opened. This document includes the following elements: (a) Terms of the relationship between Desjardins Online
        Brokerage and its client; (b) Description of products and services; (c) Responsibilities of the brokerage firm;
        (d) Commission schedules; (e) Information regarding activities in your accounts; (f) Privacy Policy; (g)
        Handling of complaints; (h) Trusted contact person; (i) General account agreement and specific agreements; (j)
        Statement of principles on conflicts of interest; (k) Information on multiple marketplaces; (l) U.S. tax rules
        regarding certain types of revenues; (m) Strip bonds and strip bond packages; (n) Options and futures trading;
        and (o) Off book borrowing for investment purposes</p>
    <p><b>I declare that I have read and understood</b> not only each of the provisions of this form, but also those
        included in the Relationship Disclosure Document appropriate for the types of accounts I wish to open, and agree
        that I am bound by these provisions, which form an integral part hereof. I acknowledge that Desjardins Online
        Brokerage specifically referred me to these provisions and provided me with all appropriate explanations
        regarding the nature and scope of such provisions.</p>
    <p>I acknowledge and warrant to Desjardins Online Brokerage that I have received and understood the warning provided
        by Desjardins Online Brokerage in the preceding paragraph. I may contact a Desjardins Online Brokerage
        representative for any questions about the content of this document.</p>
    <p>I declare that all the information contained in this form is accurate and I undertake to communicate to
        Desjardins Online Brokerage, as soon as possible, any change hereto. I authorize Desjardins Online Brokerage to
        carry out any credit investigation it deems appropriate.</p>
    <p>I confirm that I understand that Desjardins Securities Inc. uses the trade name Desjardins Online Brokerage for
        its discount brokerage activities. The discount brokerage products and services are grouped under the Disnat
        trademark. Desjardins Securities Inc. is an entity separate from the Fédération des caisses Desjardins du Québec
        and its member caisses (hereinafter, collectively, the "Caisses"). I understand that, in some cases, these
        entities' places of business are located at the same address and share offices. In addition, I understand that
        Desjardins Online Brokerage representatives perform their duties solely on behalf of Desjardins Securities Inc.
    </p>
    <p>Moreover, securities purchased through Desjardins Online Brokerage have the following characteristics: (a) they
        are not guaranteed by a government deposit insurer; (b) they are not guaranteed by the Caisses; and (c) they may
        be subject to fluctuations in value. However, GICs are deposits within the meaning of the Trust and Loan
        Companies Act and in accordance with the provisions of the Canada Deposit Insurance Corporation Act. These
        deposits are insurable with the Canada Deposit Insurance Corporation (CDIC).</p>
    <p>Desjardins Securities Inc. is a member of the Investment Industry Regulatory Organization of Canada (IIROC) and
        the Canadian Investor Protection Fund (CIPF).</p>
    <p><b>I acknowledge having read</b> section 8 and authorize the collection, use and disclosure of my personal
        information
        in the manner set out in this consent form and the Desjardins Group Privacy Policy. This information will be
        retained as long as Desjardins Securities Inc. requires it for the purposes set out herein, even if I am no
        longer doing business with Desjardins Securities Inc.</p>
    <p>In the case of a <b>margin account application</b>, I acknowledge that I have read and accept the terms of the General
        Account Agreement and Margin Account Agreement included in the Relationship Disclosure Document. I understand
        that the execution of transactions in a margin account necessarily results in a borrowing of funds.</p>
    <p>In the case of a <b>margin options account application</b>, I acknowledge that I have read and accept the terms of the
        General Account Agreement, the Margin Account Agreement and the Option Account Agreement included in the
        Relationship Disclosure Document. I understand that such an account involves risks and that I have received and
        read the Risk Disclosure Statement for Futures and Options included in the Relationship Disclosure Document.</p>
</ng-template>

<ng-template #agreementConsentAndDeliveryElectronicDocTitleEn>
    <p>As authorized by securities legislation, Desjardins Securities Inc., as part of its discount brokerage activities
        under the trade name Desjardins Online Brokerage, may send me certain documents electronically by making them
        available online on a website. In order to use this method of document delivery, Desjardins Online Brokerage
        must obtain the following consent from me.</p>
    <p>I certify that I have read, understood and accept the "Consent to deliver documents electronically and conditions
        of use" and consent to having the following documents sent to me electronically: (a) Relationship Disclosure
        Document and Agreements; (b) How IIROC protects investors; (c) Canadian Investor Protection Fund (CIPF); (d)
        Making a Complaint – A Guide for Investors; and (e) How Can I Get My Money Back? – A Guide for Investors. In
        this respect, I consent and accept that a website address providing access to the documents listed above will be
        provided in lieu of the delivery of a paper copy of the documents by mail.</p>
    <p>I may obtain a paper copy from Desjardins Online Brokerage, free of charge, of any documents sent electronically
        by contacting the client relations team using the information indicated in the "Contact Us" section. I am not
        required to accept the electronic delivery of documents and this consent may therefore be revoked by contacting
        the Desjardins Online Brokerage client relations team. Desjardins Online Brokerage may modify the terms of this
        consent form by providing 30 days' notice by email.</p>
    <p>I acknowledge that by providing my email address to Desjardins Online Brokerage, I consent to the above-mentioned
        documents being delivered to me electronically. I also confirm that I have the technical resources and
        capabilities to access them. Lastly, I accept that these documents will be given to me via access to a website
        address that Desjardins Online Brokerage will send me.</p>
</ng-template>

<ng-template #helpConsentCaisseFr>
    <p><strong>Important:</strong> Amener le client à lire la section 9 du formulaire d’ouverture de compte de façon détaillée. Les points listés sont principaux, mais non exhaustifs.</p>
    <h2>Collecte, utilisation et partage de vos renseignements personnels:</h2>
    <p>Le client accepte la collecte et l’utilisation des renseignements personnels (décrite dans la section 8 du formulaire d’ouverture de compte).</p>
    <h2>Consentement à la transmission électronique de document et conditions d’utilisation:</h2>
    <ul>
        <li>Il est important d’informer le client qu’en fournissant son adresse électronique, il consent à recevoir la documentation par voie électronique et accepte la remise des documents par l’accès à une adresse URL transmise pas Desjardins Courtage en ligne.</li>
        <li>Toutefois, il est toujours possible de recevoir une copie papier, sans frais, en communiquant avec le service à la clientèle de Desjardins Courtage en ligne.</li>
    </ul>
    <h2>Déclaration et consentements du client:</h2>
    <ul>
        <li>Desjardins Courtage en ligne ne fait pas et ne fera pas de recommandations et n’a pas l’obligation de procéder à une évaluation de la convenance des opérations à l’occasion de l’acceptation des ordres d’un client.</li>
        <li>Le client reconnaît et convient qu’il est le seul responsable de ses décisions de placement.</li>
        <li>Le client garantit à Desjardins Courtage en ligne qu’il possède les connaissances et l’expérience requises pour prendre ses propres décisions en matière de placement ainsi que la capacité financière et une tolérance au risque suffisantes pour supporter des pertes ou des pertes de profits anticipées qui pourraient survenir en raison de ses choix d’investissement.</li>
        <li>Le client reconnaît avoir pris connaissance des Conventions – Sections 2, 3 et 4 appropriées aux types de comptes qu’il désire ouvrir.</li>
        <li>Le client reconnaît et comprend la mise en garde fournie par Desjardins Courtage en ligne citée précédemment.</li>
        <li>Le client est informé que le document « CE QUE VOUS DEVEZ SAVOIR – Document d’information sur la relation avec les clients » est disponible en tout temps sur le site Internet de Desjardins Courtage en ligne.</li>
        <li>Le client peut s’adresser à un représentant de Desjardins Courtage en ligne pour toute question sur le contenu de ce document.</li>
        <li>Dans le cas d’une demande d’ouverture de compte sur marge, le client reconnaît avoir lu et accepte les modalités de la Convention générale de compte et de la Convention de compte sur marge incluses dans les Conventions – Sections 2 et 3.</li>
        <li>Dans le cas d’une demande d’ouverture d’un compte sur marge-options, le client reconnaît avoir lu et accepte les modalités de la Convention générale de compte, de la Convention de compte sur marge ainsi que la Convention de compte d’options incluses dans les Conventions – Sections 2, 3 et 4.</li>
    </ul>
</ng-template>
<ng-template #helpConsentCaisseEn>
    <p><strong>Important:</strong> Have the client read Section 9 of the account opening form in detail. The points listed are important but not exhaustive.</p>
    <h2>Collection, use and disclosure of your personal information:</h2>
    <p>The client agrees to the collection and use of personal information (described in Section 8 of the Account Opening Form).</p>
    <h2>Consent to deliver documents electronically and conditions of use:</h2>
    <ul>
        <li>It is important to inform the client that by providing their email address, they agree to receive the documentation electronically and accept the delivery of the documents by accessing a URL sent by Desjardins Online Brokerage.</li>
        <li>However, it is still possible to receive a paper copy free of charge by contacting Desjardins Online Brokerage's client service department.</li>
    </ul>
    <h2>Client’s declaration and consent:</h2>
    <ul>
        <li>Desjardins Online Brokerage does not make and will not make recommendations and is under no obligation to make a transactional suitability assessment when accepting a client's orders.</li>
        <li>The client acknowledges and agrees that they are solely responsible for their investment decisions.</li>
        <li>The client guarantees Desjardins Online Brokerage that they have the knowledge and experience required to make their own investment decisions as well as the financial capacity and a sufficient risk tolerance to withstand anticipated losses or loss of profits that may arise because of their investment choices.</li>
        <li>The client acknowledges having read the Agreements - Sections 2, 3 and 4 appropriate for the types of accounts they wish to open.</li>
        <li>The client recognizes and understands the warning provided by Desjardins Online Brokerage mentioned above.</li>
        <li>The client is informed that the document "WHAT YOU SHOULD KNOW - Relationship Disclosure Document" is available at any time on the Desjardins Online Brokerage website.</li>
        <li>The client may contact a representative of Desjardins Online Brokerage for any questions regarding the content of this document.</li>
        <li>In the case of an application to open a margin account, the client acknowledges having read and accepted the terms of the General Accounts Agreements and the Margin Account Agreement included in the Sections 2 and 3 Agreements.</li>
        <li>In the case of a request to open a margin-option account, the client acknowledges having read and accepted the terms and conditions of the General Accounts Agreements, the Margin Account Agreement and the Options Account Agreements included in the Agreements - Sections 2, 3 and 4.</li>
    </ul>
</ng-template>
