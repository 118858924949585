import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {CountryCompleterService} from '../../components/services/country-completer.service';
import {ModalService} from '../../components/services/modal.service';
import {MomentPipe} from '../../components/pipes/moment.pipe';
import {UserIdentity} from '../../components/models/user-identity';

declare let $: any;

@Component({
    selector: 'app-review-client-identity',
    templateUrl: './review-client-identity.component.html'
})
export class ReviewClientIdentityComponent extends BaseNavComponent implements OnInit {

    identityPaperList: HtmlSelectKV[] = [];
    allowedIdentityPaperList: HtmlSelectKV[] = [];
    provincesList: HtmlSelectKV[] = [];
    provincesListComplete: HtmlSelectKV[] = [];
    provincesListForHealthInsuranceCard: HtmlSelectKV[] = [];
    countries: HtmlSelectKV[] = [];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService,
                private countryCompleter: CountryCompleterService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.formService.applyRules();

        this.form = this.fb.group({
            userMetAtOffice: [this.formService.getForm().requesters[this.requesterIndex].userMetAtOffice, [this.requiredValidator('userMetAtOffice')]],
            clientIdentity: this.fb.group({

                userIdentityAdvisorFamilyName: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFamilyName, [this.requiredBranchValidator('userIdentityAdvisorFamilyName')]],
                userIdentityAdvisorFirstName: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFirstName, [this.requiredBranchValidator('userIdentityAdvisorFirstName')]],
                userIdentityAdvisorBranchName: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorBranchName, [this.requiredBranchValidator('userIdentityAdvisorBranchName')]],
                userIdentityAdvisorInstitution: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorInstitution, [this.requiredBranchValidator('userIdentityAdvisorInstitution')]],
                userIdentityAdvisorTransit: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorTransit, [this.requiredBranchValidator('userIdentityAdvisorTransit')]],
                userIdentityVerificationDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentityVerificationDate, [this.requiredBranchValidator('userIdentityVerificationDate'), VmdValidators.date]],

                userIdentityCode: [this.formService.getForm().requesters[this.requesterIndex].userIdentityCode, [this.requiredValidator('userIdentityCode')]],
                userIdentityIDNumber: [this.formService.getForm().requesters[this.requesterIndex].userIdentityIDNumber, [this.requiredValidator('userIdentityIDNumber')]],
                userIdentityExpirationDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentityExpirationDate, [this.requiredValidator('userIdentityExpirationDate'), VmdValidators.date, VmdValidators.dateInFuture]],

                userIdentityProvince: [this.formService.getForm().requesters[this.requesterIndex].userIdentityProvince, [this.requiredValidator('userIdentityProvince')]],
                userIdentityCountry: [this.formService.getForm().requesters[this.requesterIndex].userIdentityCountry, [this.requiredValidator('userIdentityCountry')]]
            })
        });

        const identityPaperListId = this.isGpdOrSfd() ? 'identityPaperGpdList' : 'identityPaperList';
        this.listModelService.getListModel(identityPaperListId, (data: HtmlSelectKV[]) => {
            this.initIdentityPaperLists(data);
        });

        this.listModelService.getListModel('provincesList', data => this.setProvincesLists(data));
        this.listModelService.getListModel('paysList', data => this.setCountries(data));

        this.form.get('userMetAtOffice').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('clientIdentity'), value);
            this.updateControlEnabledProvinceAndCountry();
            if (this.isBranch() && value) {
                this.recoverAdvisorInfos();
            }
        });
        this.updateControlEnabled(this.form.get('clientIdentity'), this.form.get('userMetAtOffice').value);
        this.updateControlEnabledProvinceAndCountry();

        this.form.get('clientIdentity.userIdentityCode').valueChanges.subscribe(value => {
            this.updateControlEnabledProvinceAndCountry();
        });

        if (this.isBranch() && this.form.get('userMetAtOffice').value) {

            this.recoverAdvisorInfos();
        }
    }

    requiredBranchValidator(fieldname: string): ValidatorFn | null {
        if (!this.isBranch()) {
            return Validators.nullValidator;
        }
        return super.requiredValidator(fieldname);
    }

    // OCE-6352
    submit() {

        if (this.isPleinEx()) {
            this.submitForm();
        } else {
            if (!this.form.get('userMetAtOffice').value && (this.formService.isCaisseContext() || this.formService.isFromSmartD())) {
                this.modalService.openModal('USER_IDENTITY_TITLE', 'USER_IDENTITY_CAISSE_ERROR_MESSAGE');
            } else {
                this.submitForm();
            }

            this.mapUserIdentityInfos();
        }
    }

    private mapUserIdentityInfos() {

        const requester = this.formService.getForm().requesters[this.requesterIndex];
        this.formService.getForm().requesters[this.requesterIndex].userIdentities = [];

        const userIdentityInfos : UserIdentity = new UserIdentity();
        userIdentityInfos.code = requester.userIdentityCode;
        userIdentityInfos.idNumber = requester.userIdentityIDNumber;
        userIdentityInfos.expirationDate = requester.userIdentityExpirationDate;
        userIdentityInfos.province = requester.userIdentityProvince;
        userIdentityInfos.country = requester.userIdentityCountry;

        this.formService.getForm().requesters[this.requesterIndex].userIdentities.push(userIdentityInfos);
    }

    chooseProvincesList(): void {
        if (this.form.get('clientIdentity.userIdentityCode').value === this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD) {
            this.provincesList = this.provincesListForHealthInsuranceCard;
        } else {
            this.provincesList = this.provincesListComplete;
        }
    }

    displayIdentityProvince(): boolean {
        return this.form.get('userMetAtOffice').value
            && (
                this.form.get('clientIdentity.userIdentityCode').value === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE
                || this.form.get('clientIdentity.userIdentityCode').value === this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD
                || this.form.get('clientIdentity.userIdentityCode').value === this.constants.USER_IDENTITY_CODE_PROVINCIAL_TERRITORIAL_CARD
            );
    }

    displayIdentityCountry(): boolean {
        return this.form.get('userMetAtOffice').value && this.form.get('clientIdentity.userIdentityCode').value === this.constants.USER_IDENTITY_CODE_PASSPORT;
    }

    initializeCountryTypeahead(): void {
        setTimeout(() => {
            if ($('#userIdentityCountry').length > 0 && this.countries.length > 0) {
                this.countryCompleter.initialize($('#userIdentityCountry'), this.countries);
            }
        }, 0);

    }

    initIdentityPaperLists(data: HtmlSelectKV[]) {
        this.identityPaperList = data;
        this.allowedIdentityPaperList = data;
        if (this.useSwitchLba() && this.isNonResident()) {
            this.allowedIdentityPaperList = this.identityPaperList.filter(item => item.itemValue === this.constants.USER_IDENTITY_CODE_PASSPORT);
        }
    }

    private isNonResident(): boolean {
        return  this.formService.getForm().requesters[this.requesterIndex].userAddressCountry !== this.constants.COUNTRIES.CANADA;
    }

    isHealthInsuranceException(province: string): boolean {
        for (const item of this.constants.PROVINCES_HEALTH_INSURANCE_EXCEPTION) {
            if (province === item) {
                return true;
            }
        }
        return false;
    }

    setCountries(list: HtmlSelectKV[]): void {
        this.countries = list;
        this.initializeCountryTypeahead();
    }

    setProvincesLists(list: HtmlSelectKV[]) {
        this.provincesListComplete = list;
        this.provincesListForHealthInsuranceCard = [];
        for (const item of list) {
            if (!this.isHealthInsuranceException(item.itemValue)) {
                this.provincesListForHealthInsuranceCard.push(item);
            }
        }
        this.chooseProvincesList();
    }

    private updateControlEnabledProvinceAndCountry(): void {
        const displayProvince = this.displayIdentityProvince();
        const displayCountry = this.displayIdentityCountry();
        this.updateControlEnabled(this.form.get('clientIdentity.userIdentityProvince'), displayProvince);
        this.updateControlEnabled(this.form.get('clientIdentity.userIdentityCountry'), displayCountry);

        this.chooseProvincesList();

        this.initializeCountryTypeahead();

        if (!displayProvince) {
            this.formService.getForm().requesters[this.requesterIndex].userIdentityProvince = null;

            this.form.get('clientIdentity').patchValue({
                userIdentityProvince: null
            });
            this.formService.setFormProperty('clientIdentity.userIdentityProvince', null);
        }

        if (!displayCountry) {
            this.formService.getForm().requesters[this.requesterIndex].userIdentityCountry = null;

            this.form.get('clientIdentity').patchValue({
                userIdentityCountry: null
            });
            this.formService.setFormProperty('clientIdentity.userIdentityCountry', null);
        }

    }

    recoverAdvisorInfos() {

        if (this.areAdvisorFieldsEmpty()) {

            const advisorIdentity = this.formService.getForm().advisorIdentity;

            if (advisorIdentity) {

                if (this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFamilyName == null) {
                    this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFamilyName = advisorIdentity.nom;
                    this.form.get('clientIdentity').get('userIdentityAdvisorFamilyName').setValue(advisorIdentity.nom);
                }

                if (this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFirstName == null) {
                    this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFirstName = advisorIdentity.prenom;
                    this.form.get('clientIdentity').get('userIdentityAdvisorFirstName').setValue(advisorIdentity.prenom);
                }

                if (this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorInstitution == null) {
                    this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorInstitution = Number(advisorIdentity.numeroInstitution);
                    this.form.get('clientIdentity').get('userIdentityAdvisorInstitution').setValue(advisorIdentity.numeroInstitution);
                }

                if (this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorTransit == null) {
                    this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorTransit = Number(advisorIdentity.numeroTransit);
                    this.form.get('clientIdentity').get('userIdentityAdvisorTransit').setValue(advisorIdentity.numeroTransit);
                }

                if (this.formService.getForm().requesters[this.requesterIndex].userIdentityVerificationDate == null) {
                    const todayDate = new MomentPipe().transform(new Date(), 'YYYY-MM-DD');
                    this.formService.getForm().requesters[this.requesterIndex].userIdentityVerificationDate = todayDate;
                    this.form.get('clientIdentity').get('userIdentityVerificationDate').setValue(todayDate);
                }
            }
        }
        this.updateControlAdvisorFields();
    }

    updateControlAdvisorFields() {
        const advisorIdentity = this.formService.getForm().advisorIdentity;

        if (advisorIdentity) {
            this.updateControlEnabled(this.form.get('clientIdentity.userIdentityAdvisorFamilyName'), !advisorIdentity.nom);
            this.updateControlEnabled(this.form.get('clientIdentity.userIdentityAdvisorFirstName'), !advisorIdentity.prenom);
            this.updateControlEnabled(this.form.get('clientIdentity.userIdentityAdvisorInstitution'), !advisorIdentity.numeroInstitution);
            this.updateControlEnabled(this.form.get('clientIdentity.userIdentityAdvisorTransit'), !advisorIdentity.numeroTransit);
        }
    }

    areAdvisorFieldsEmpty(): boolean {
        return !this.form.get('clientIdentity.userIdentityAdvisorFamilyName').value
            && !this.form.get('clientIdentity.userIdentityAdvisorFirstName').value
            && !this.form.get('clientIdentity.userIdentityAdvisorBranchName').value
            && !this.form.get('clientIdentity.userIdentityAdvisorInstitution').value
            && !this.form.get('clientIdentity.userIdentityAdvisorTransit').value;
    }

    isBranch(): boolean {
        return this.formService.isCaisseContext();
    }

}
