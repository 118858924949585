import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {FormService} from '../../../components/services/form.service';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {WebException} from '../../../components/models';
import {ModalService} from '../../../components/services/modal.service';
import {ProfileService} from '../../../components/services/profile.service';
import {NavigationService} from '../../../components/services/navigation.service';
import {Functions} from '../../../components/utils/functions';
import {ErrorCodeConstants} from '../../../components/constants/error-code-constants';
import {HttpStatusCode} from '@angular/common/http';
import {PostLoginActionService} from '../../../components/services/post-login-action.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-aside',
    templateUrl: './aside.component.html'
})
export class AsideComponent implements OnInit, OnDestroy {
    @Input() item: any;

    loading = false;
    loadingPrint = false;
    fromLogin = true;
    saveError: string = null;
    updatedFormError: string = null;
    printError: string = null;
    constants = VmdConstants;
    email: string = null;
    isSaving = false;
    isUpdatingForm = false;
    saved = false;
    protected subscriptions: any[] = [];

    private messageEvent = null;

    constructor(protected formService: FormService,
                private navigationService: NavigationService,
                private modalService: ModalService,
                private profileService: ProfileService,
                public translate: TranslateService,
                private postLoginActionService: PostLoginActionService) {
    }

    ngOnInit() {
        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));

        this.subscriptions.push(this.formService.isUpdatingFormEmitted.subscribe((isUpdatingForm) => {
            this.isUpdatingForm = isUpdatingForm;
        }));

        this.subscriptions.push(this.profileService.initiateSave().subscribe(() => {
            this.save();
        }));

        this.subscriptions.push(this.navigationService.nextEmitted.subscribe(() => {
            this.saved = false;
            this.saveError = null;
            this.printError = null;
            this.updatedFormError = null;
        }));

        this.subscriptions.push(this.navigationService.backEmitted.subscribe(() => {
            this.saved = false;
            this.saveError = null;
            this.printError = null;
            this.updatedFormError = null;
        }));
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: any) {
        this.receiveMessage(event);
    }

    displayAside(): boolean {
        return this.displaySaveBlock()
            || this.displayAssistanceBlock()
            || this.displayConsultAllBlock()
            || this.displayPreparationBlock()
            || this.displayConfirmationBlock();
    }

    isPleinEx(): boolean {
        return this.formService.isPleinEx();
    }

    isUpdateAccount(): boolean {
        return this.formService.isUpdateAccount();
    }

    displayAssistanceBlock(): boolean {
        return this.isNotPleinEx() && !this.formService.isUpdateAccount();
    }

    displayConfirmationBlock(): boolean {
        return this.item && this.item.aside === 'ASIDE_CONFIRMATION' && this.isNotPleinEx();
    }

    displayConsultAllBlock(): boolean {
        return this.item && this.item.aside === 'ASIDE_CONSULT_ALL' && this.isNotPleinEx();
    }

    displayPreparationBlock(): boolean {
        return this.item && this.item.aside === 'ASIDE_PREPARATION' && this.isNotPleinEx();
    }

    displayPrintBlock(): boolean {
        if (this.isReadOnly()) {
            return false;
        }
        return (this.formService.isPleinEx()
                && !this.hidePrint())
            || (this.formService.isUpdateAccount()
                && !this.formService.isPilotMemberOrModeUpdateAutomatedOpeningActive());
    }

    displaySaveBlock(): boolean {
        if (this.isReadOnly()) {
            return false;
        }
        return this.item && this.item.saveAvailable;
    }

    isNotPleinEx(): boolean {
        return !this.formService.isPleinEx();
    }

    saveOnClick(): void {
        if (this.formService.clientIsResidentOfQuebec() && this.formService.isClientTypeOtherAccount()) {
            this.modalService.showCuratorshipResidentQuebecModal();
            return;
        }

        if (this.formService.isAddAccountPleinExPath()) {
            const currentComposant = this.formService.getCurrentComposant();

            if (currentComposant.form.valid) {
                this.messageEvent = null;
                this.save();
            } else {
                const form = currentComposant.getForm();
                const tfsaAccountMgntType = form.get('tfsaAccount.tfsaAccountMgntType');
                const tfsaCurrency = form.get('tfsaAccount.tfsaCurrency');
                if (tfsaAccountMgntType === null || tfsaCurrency === null ||
                    Functions.isNullOrEmpty(tfsaAccountMgntType.value) || Functions.isNullOrEmpty(tfsaCurrency.value)) {
                    currentComposant.formSubmitted = true;
                    currentComposant.markAsTouched(form);
                    currentComposant.focusOnErrorsList();
                }
            }

        } else {
            this.messageEvent = null;
            this.save();
        }
    }

    save(): void {

        this.saveError = null;
        this.printError = null;

        if (this.formService.isUpdateAccount()) {
            if (!this.formService.isCompleted() && !this.formService.isPrinted()) {
                this.saveProcess();
            } else {
                this.onSaveError(null, 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED');
            }
        } else if (!this.isValidEmail() && this.isNotPleinEx()) {
            this.saveError = 'ODC_QUESTIONNAIRE_SAVE_EMAIL';
            this.postSavedMessage();
        } else if (this.processRules()) {
            this.formService.getCurrentComposant().clickSubmitButton();
            this.postSavedMessage(false);
            return null;
        } else {
            this.saveProcess();
        }
    }

    print(): void {
        if (this.formService.clientIsResidentOfQuebec() && this.formService.isClientTypeOtherAccount()) {
            this.modalService.showCuratorshipResidentQuebecModal();
            return;
        }

        this.saveError = null;
        this.printError = null;

        if (this.processRules()) {
            this.formService.getCurrentComposant().clickSubmitButton();
            return null;
        } else {
            if (this.formService.isAddAccountPleinExPath()) {
                const currentComposant = this.formService.getCurrentComposant();

                if (currentComposant.form.valid) {
                    this.printProcess();
                } else {
                    const form = currentComposant.getForm();
                    const tfsaAccountMgntType = form.get('tfsaAccount.tfsaAccountMgntType');
                    const tfsaCurrency = form.get('tfsaAccount.tfsaCurrency');
                    if (tfsaAccountMgntType === null || tfsaCurrency === null ||
                        Functions.isNullOrEmpty(tfsaAccountMgntType.value) || Functions.isNullOrEmpty(tfsaCurrency.value)) {
                        currentComposant.formSubmitted = true;
                        currentComposant.markAsTouched(form);
                        currentComposant.focusOnErrorsList();
                    }
                }
            } else {
                this.printProcess();
            }
        }
    }

    isReadOnly() {
        return this.formService.isConfigReadOnly();
    }

    // Fonction to disable the save/print pdf buttons while the request is saving or processing
    isRequestInProgressOrSaving(): boolean {
        return this.formService.saveInProgressWithoutModal();
    }

    isSaveDisabled(): boolean {
        return this.formService.isSaveDisabled;
    }

    addressFormTypeIsNotSelectedOnUpdateAccount(): boolean {
        return this.formService.addressFormTypeIsNotSelectedOnUpdateAccount();
    }

    protected allowedAutoSavedCaisse(): boolean {
        return false;
    }

    private isValidEmail(): boolean {
        return VmdConstants.EMAIL_REGEXP.test(this.formService.getForm().requesters[0].userEmail);
    }

    private processRules(): boolean {
        return this.formService.getCurrentComposant() && this.formService.getCurrentComposant().processRules();
    }

    private hidePrint() {
        return (this.navigationService.currentTab === 1 && this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT)
            || !this.formService.isPrintMode();
    }

    private saveProcess() {

        if (!this.formService.saveInProgress()) {

            this.loading = true;
            this.formService.emitIsSaving(true);

            if (this.formService.isTransmitted() && this.formService.getForm().transmissionMethod !== VmdConstants.TRANSMISSION_MAIL) {
                this.formService.isEsignInProgress().subscribe(isInProgress => {
                        if (isInProgress) {
                            this.formService.saveTransmittedForm().subscribe(
                                data => this.onSaveSuccess(data),
                                (error: WebException) => this.onSaveError(error)
                            );
                        } else {
                            this.onSaveError(null, 'ESIGN_UNAVAILABLE');
                        }
                    },
                    (error: WebException) => this.onSaveError(error)
                );
            } else if (this.formService.isTransmitted()) {
                this.formService.saveTransmittedForm().subscribe(
                    data => this.onSaveSuccess(data),
                    (error: WebException) => this.onSaveError(error)
                );
            } else if (this.formService.isCompleted()) {
                if (this.isNotPleinEx()) {
                    this.formService.saveCompletedForm().subscribe(
                        data => this.onSaveSuccess(data),
                        (error: WebException) => this.onSaveError(error)
                    );
                } else {
                    this.onSaveError(null, 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED');
                    this.postSavedMessage();
                }
            } else {
                this.formService.saveForm().subscribe(
                    data => this.onSaveSuccess(data),
                    (error: WebException) => {
                        this.postLoginActionService.registerAction(() => this.saveOnClick());
                        this.onSaveError(error);
                    },
                ).add(() => {
                    this.loading = false;
                    this.formService.emitIsSaving(false);
                });
            }
        }
    }

    private onSaveSuccess(data: any) {
        this.loading = false;
        this.formService.emitIsSaving(false);

        if (data.status !== 'OK') {
            this.saveError = 'ODC_QUESTIONNAIRE_SAVE_ERROR';
        } else {
            this.saved = true;
            this.fromLogin = false;
        }

        this.postSavedMessage();
    }

    private onSaveError(error: WebException, labelKey: string = ErrorCodeConstants.ODC_QUESTIONNAIRE_SAVE_ERROR) {
        if (error) {
            if (error.status !== HttpStatusCode.Unauthorized && error.status !== HttpStatusCode.Forbidden) {
                this.saveError = labelKey;
            }
        } else {
            this.saveError = labelKey;
        }

        this.postSavedMessage();
    }

    private printProcess() {

        if (!this.formService.saveInProgress()) {

            this.loadingPrint = true;
            this.formService.emitIsSaving(true);
            this.formService.getForm().transmissionMethod = VmdConstants.TRANSMISSION_PRINT;

            this.modalService.openModal(null, null, '#modal-pdf');
            this.modalService.emitPdfLoading(true);

            this.formService.printForm().subscribe(
                data => this.onPrintSuccess(data),
                () => this.onPrintError()
            );
        }
    }

    private onPrintSuccess(data: any) {
        this.loadingPrint = false;
        this.formService.emitIsSaving(false);

        if (data.status !== 'OK') {
            this.printError = 'ODC_QUESTIONNAIRE_PRINT_ERROR';
            this.modalService.emitError(this.printError);
        } else {
            if (this.formService.isPleinEx() || this.isNotPleinEx() && this.formService.isUpdateAccount()) {
                this.saved = true;
                this.fromLogin = false;
                this.formService.setPrinted(true);

                this.downloadPDF();
            }
        }
    }

    private onPrintError() {
        this.loadingPrint = false;
        this.formService.emitIsSaving(false);
        this.printError = 'ODC_QUESTIONNAIRE_SAVE_ERROR';
        this.modalService.emitError(this.printError);
    }

    private downloadPDF() {
        this.modalService.emitPdfLink();
    }

    private allowedAutoSaved(): boolean {
        return this.allowedAutoSavedPleinEx() || this.allowedAutoSavedCaisse();
    }

    private allowedAutoSavedPleinEx(): boolean {
        return this.formService.isPleinEx()
            && (
                this.formService.getForm().requestStatus === null
                || this.formService.getForm().requestStatus === this.constants.REQUEST_STATUS_SAVED
                || this.formService.getForm().requestStatus === this.constants.REQUEST_STATUS_PRINTED
            );
    }

    private receiveMessage(event: any) {
        if (window.opener && event.data.action === 'save' && event.data.origin === event.origin) {
            this.messageEvent = event;
            if (this.allowedAutoSaved()) {
                this.save();
            } else {
                this.saveError = null;
                this.postSavedMessage();
            }
        }
    }

    private postSavedMessage(success: boolean = true) {
        if (window.opener && window.opener.window && this.messageEvent && this.messageEvent.data) {
            window.opener.window.postMessage({
                action: 'save',
                success: this.saveError == null && success,
                url: this.messageEvent.data.url,
                origin: this.messageEvent.data.target
            }, this.messageEvent.data.origin);
        }
    }

}

