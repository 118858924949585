// noinspection LanguageDetectionInspection

import {AccountTransferRequest} from './accountTransferRequest';
import {AttachedDocument} from './attachedDocument';
import {Requester} from './requester';
import {VmdConstants} from '../constants/vmd-constants';
import {BeneficiaryRespBean} from './BeneficiaryRespBean';
import {Entity} from './entity';
import {Branch} from './Branch';
import {AdvisorIdentity} from './advisorIdentity';
import {Functions} from '../utils/functions';

export class ODCForm {

    requestID: string;
    pleinEx: boolean;
    gpd: boolean;
    sfd: boolean;
    ssd: boolean;
    repId: string;
    lang: string;

    currentSection: string;
    requestStatus: string;

    newExistAct: string; // NEWACT, EXSITACT.

    updateContext: boolean;

    // Plateforme transactionnelle
    plateformDisnat: string;

    // Type de gestion (plein ex)
    investmentAdviceComissionBased: boolean;
    investmentAdviceEliteBasic: boolean;
    investmentAdviceEliteIntermediate: boolean;
    investmentAdviceEliteFlexible: boolean;
    portfolioManagementAdvisorProgram: boolean;
    portfolioManagementMandateOnly: boolean;
    portfolioManagementAssetAllocation: boolean;

    optionCode: string;
    registeredOptionCode: string;

    // Choix de compte non enregistré
    actType: string; // CASH, MARGIN.
    optionAct: boolean; // OPTACT.
    shortSellAct: boolean; // SSACT - Vente a decouvert.
    cltNo: string; // Numéro de compte client existant.
    cltType: string; // client Type - INDIVIDU, CONJOINT, COMPAGNIE
    currency: string; // currency CAD ou USD.

    // type de gestion PleinEx Auto mode only
    cashAccountMgntType: string;
    tfsaAccountMgntType: string;
    fhsaAccountMgntType: string;
    rrspAccountMgntType: string;
    rrspAccountMgntTypeSec: string;
    rrspAccountMgntTypeTer: string;
    liraAccountMgntType: string;
    liraAccountMgntTypeSec: string;
    rrifAccountMgntType: string;
    rrifAccountMgntTypeSec: string;
    lifAccountMgntType: string;
    respAccountMgntType: string;
    ftarrspAccountMgntType: string;
    ftarrspAccountMgntTypeSec: string;
    ftarrspAccountMgntTypeTer: string;

    accountNumberCad: string;
    productNumberCad: string;
    accountNumberUsd: string;
    productNumberUsd: string;

    // Comptes enregistrés

    addRRSP: boolean; // Ajout REER.
    rrspCurrency: string; // currency CAD ou USD.
    rrspAnnuitant: string; // Rentier / Conjoint / les deux (plein-ex).
    rrspContributingSpouseIsCurrentSpouse: boolean;
    addRRSPSec: boolean; // Ajout REER.
    rrspCurrencySec: string; // currency CAD ou USD.
    rrspAnnuitantSec: string; // Rentier / Conjoint / les deux (plein-ex).
    rrspContributingSpouseIsCurrentSpouseSec: boolean;
    addRRSPTer: boolean; // Ajout REER.
    rrspCurrencyTer: string; // currency CAD ou USD.
    rrspAnnuitantTer: string; // Rentier / Conjoint / les deux (plein-ex).
    rrspContributingSpouseIsCurrentSpouseTer: boolean;

    addTFSA: boolean; // Ajout CELI.
    tfsaCurrency: string; // currency CAD ou USD.
    addFHSA: boolean; // Ajout CELIAPP.
    fhsaEligibility: boolean;
    fhsaCurrency: string; // currency CAD.

    addRESP: boolean; // Ajout REEE.
    respType: string; // family | individual | les deux (plein-ex)
    respNumberOfBeneficiaries: number;
    respIsSubscriberResponsible: boolean;

    addLIRA: boolean; // Ajout CRI.
    liraCurrency: string; // currency CAD ou USD.
    liraJurisdiction: string;
    addLIRASec: boolean; // Ajout CRI.
    liraCurrencySec: string; // currency CAD ou USD.
    liraJurisdictionSec: string;

    addRRIF: boolean; // Ajout FERR.
    rrifCurrency: string; // currency CAD ou USD.
    rrifAnnuitant: string; // Rentier / Conjoint / les deux (plein-ex).
    rrifContributingSpouseIsCurrentSpouse: boolean;
    addRRIFSec: boolean; // Ajout FERR.
    rrifCurrencySec: string; // currency CAD ou USD.
    rrifAnnuitantSec: string; // Rentier / Conjoint / les deux (plein-ex).
    rrifContributingSpouseIsCurrentSpouseSec: boolean;

    addLIF: boolean; // Ajout FRV.
    lifCurrency: string; // currency CAD ou USD.
    lifJurisdiction: string;

    addFTARRSP: boolean;
    ftarrspAnnuitant: string;
    ftarrspContributingSpouseIsCurrentSpouse: boolean;
    ftarrspRevocableBeneficiaryIndicator: boolean;
    addFTARRSPSec: boolean;
    ftarrspAnnuitantSec: string;
    ftarrspContributingSpouseIsCurrentSpouseSec: boolean;
    ftarrspRevocableBeneficiaryIndicatorSec: boolean;
    addFTARRSPTer: boolean;
    ftarrspAnnuitantTer: string;
    ftarrspContributingSpouseIsCurrentSpouseTer: boolean;
    ftarrspRevocableBeneficiaryIndicatorTer: boolean;

    // Informations personelles du client
    requesters: Requester[];

    // Entities
    entities: Entity[];

    // Lieu de résidence aux fins fiscales
    userOtherFiscal1: boolean; // OTHER si coché
    userOtherFiscal2: boolean; // OTHER si coché
    userOtherCountry1: string; // Select Pays
    userOtherCountry2: string; // 2nd Select pays

    // Occupation et Employeur
    userEmployerAddress: string; // Adresse de l'employeur
    userEmployerAddressUnit: string;
    userEmployerAddressCity: string;
    userEmployerAddressProv: string;
    userEmployerAddressPostalCode: string;

    // Borrowed for investment purpose PleinEx only
    borrowedForInvestmentPurposeIndicator: boolean;
    borrowingAmountOfLoan: number;
    borrowingInterestRate: number;
    borrowingLoanStartingDate: string;
    borrowingLoanMaturityDate: string;
    borrowingAmountInvested: number;
    borrowingReceivedAndReadBorrowingRisksIndicator: boolean;

    // User Investment Objectives PleinEx only
    userInvestmentObjectivesIntendedUse: string;
    userInvestmentObjectivesNonRegInvHorizon: string;
    userInvestmentObjectivesRegInvHorizon: string;
    userInvestmentObjectivesRskTol: string;
    userInvestmentObjectivesLowRiskNonReg: number;
    userInvestmentObjectivesModerateRiskNonReg: number;
    userInvestmentObjectivesModHighRiskNonReg: number;
    userInvestmentObjectivesSpeculativeStrategiesNonReg: number;
    userInvestmentObjectivesLowRiskReg: number;
    userInvestmentObjectivesModerateRiskReg: number;
    userInvestmentObjectivesModHighRiskReg: number;
    userInvestmentObjectivesSpeculativeStrategiesReg: number;
    includeInvestorProfile: boolean;

    // Désignation de bénéficiaires (REER)
    beneficiaryReerActiveIndicator: boolean;
    beneficiaryReerGender: string;
    beneficiaryReerFirstName: string;
    beneficiaryReerLastName: string;
    beneficiaryReerDOB: string;
    beneficiaryReerSIN: string;
    beneficiaryReerAdressStreet: string;
    beneficiaryReerAdressUnit: string;
    beneficiaryReerAdressCity: string;
    beneficiaryReerAdressProv: string;
    beneficiaryReerAdressPostalCode: string;
    beneficiaryReerAdressCountry: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryReerParentLink: string;
    beneficiaryReerPart: string;
    beneficiaryReerGenderSec: string;
    beneficiaryReerFirstNameSec: string;
    beneficiaryReerLastNameSec: string;
    beneficiaryReerDOBSec: string;
    beneficiaryReerSINSec: string;
    beneficiaryReerAdressStreetSec: string;
    beneficiaryReerAdressUnitSec: string;
    beneficiaryReerAdressCitySec: string;
    beneficiaryReerAdressProvSec: string;
    beneficiaryReerAdressPostalCodeSec: string;
    beneficiaryReerAdressCountrySec: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryReerParentLinkSec: string;
    beneficiaryReerPartSec: string;
    beneficiaryReerGenderTer: string;
    beneficiaryReerFirstNameTer: string;
    beneficiaryReerLastNameTer: string;
    beneficiaryReerDOBTer: string;
    beneficiaryReerSINTer: string;
    beneficiaryReerAdressStreetTer: string;
    beneficiaryReerAdressUnitTer: string;
    beneficiaryReerAdressCityTer: string;
    beneficiaryReerAdressProvTer: string;
    beneficiaryReerAdressPostalCodeTer: string;
    beneficiaryReerAdressCountryTer: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryReerParentLinkTer: string;
    beneficiaryReerPartTer: string;

    // Désignation de bénéficiaires (CELI)
    beneficiaryCeliConjointTitulaire: boolean;
    beneficiaryCeliActiveIndicator: boolean;
    beneficiaryCeliGender: string;
    beneficiaryCeliFirstName: string;
    beneficiaryCeliLastName: string;
    beneficiaryCeliDOB: string;
    beneficiaryCeliSIN: string;
    beneficiaryCeliAdressStreet: string;
    beneficiaryCeliAdressUnit: string;
    beneficiaryCeliAdressCity: string;
    beneficiaryCeliAdressProv: string;
    beneficiaryCeliAdressPostalCode: string;
    beneficiaryCeliAdressCountry: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryCeliParentLink: string;
    beneficiaryCeliPart: string;
    beneficiaryCeliGenderSec: string;
    beneficiaryCeliFirstNameSec: string;
    beneficiaryCeliLastNameSec: string;
    beneficiaryCeliDOBSec: string;
    beneficiaryCeliSINSec: string;
    beneficiaryCeliAdressStreetSec: string;
    beneficiaryCeliAdressUnitSec: string;
    beneficiaryCeliAdressCitySec: string;
    beneficiaryCeliAdressProvSec: string;
    beneficiaryCeliAdressPostalCodeSec: string;
    beneficiaryCeliAdressCountrySec: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryCeliParentLinkSec: string;
    beneficiaryCeliPartSec: string;
    beneficiaryCeliGenderTer: string;
    beneficiaryCeliFirstNameTer: string;
    beneficiaryCeliLastNameTer: string;
    beneficiaryCeliDOBTer: string;
    beneficiaryCeliSINTer: string;
    beneficiaryCeliAdressStreetTer: string;
    beneficiaryCeliAdressUnitTer: string;
    beneficiaryCeliAdressCityTer: string;
    beneficiaryCeliAdressProvTer: string;
    beneficiaryCeliAdressPostalCodeTer: string;
    beneficiaryCeliAdressCountryTer: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryCeliParentLinkTer: string;
    beneficiaryCeliPartTer: string;

    // Désignation de Titulaire Remplaçante et bénéficiaires (CELIAPP)
    successorHolderCeliAppActiveIndicator: boolean;
    beneficiaryCeliAppActiveIndicator: boolean;
    successorHolderCeliAppGender: string;
    successorHolderCeliAppFirstName: string;
    successorHolderCeliAppLastName: string;
    successorHolderCeliAppSIN: string;
    successorHolderCeliAppPart: string;

    // Désignation de bénéficiaires (CELIAPP)
    beneficiaryCeliAppGender: string;
    beneficiaryCeliAppFirstName: string;
    beneficiaryCeliAppLastName: string;
    beneficiaryCeliAppDOB: string;
    beneficiaryCeliAppSIN: string;
    beneficiaryCeliAppParentLink: string;
    beneficiaryCeliAppPart: string;
    beneficiaryCeliAppGenderSec: string;
    beneficiaryCeliAppFirstNameSec: string;
    beneficiaryCeliAppLastNameSec: string;
    beneficiaryCeliAppDOBSec: string;
    beneficiaryCeliAppSINSec: string;
    beneficiaryCeliAppParentLinkSec: string;
    beneficiaryCeliAppPartSec: string;
    beneficiaryCeliAppGenderTer: string;
    beneficiaryCeliAppFirstNameTer: string;
    beneficiaryCeliAppLastNameTer: string;
    beneficiaryCeliAppDOBTer: string;
    beneficiaryCeliAppSINTer: string;
    beneficiaryCeliAppParentLinkTer: string;
    beneficiaryCeliAppPartTer: string;


    // Désignation de bénéficiaires (REEE)
    beneficiaryRespBrotherAndSisterOption: boolean;
    beneficiaryRespReason: string;
    requestCESGForResp: boolean;
    beneficiaryRespList: BeneficiaryRespBean[];

    // Désignation de mandataire
    attorneyGender: string;
    attorneyAddressCity: string;
    attorneyAddressPostalCode: string;
    attorneyAddressCountry: string = VmdConstants.COUNTRIES.CANADA;
    attorneyAddressProv: string;
    attorneyAddressStreet: string;
    attorneyAddressUnit: string;
    attorneyDOB: string;
    attorneyFirstName: string;
    attorneyHomePhone: string;
    attorneyJobStatus: string;
    attorneyLastName: string;
    attorneyOtherPhone: string;
    attorneySIN: string;
    attorneyClientNumber: number;
    attorneyClientRelationship: string;
    attorneyInitieIndicator: boolean;
    attorneyInitieCompanyName: string;
    attorneyInitieCompanySymbol: string;
    attorneyInitieMarket: string;
    attorneyMainShareholderIndicator: boolean;
    attorneyMainShareholderCompanyName: string;
    attorneyMainShareholderCompanySymbol: string;
    attorneyMainShareholderMarket: string;
    attorneyEmployerIsBroker: string; // BKR_EMP_CDE
    attorneyEmployerName: string; // Requester.EMP_NAM - Nom de l.employeur
    attorneyEmployerActivity: string; // Requester.ENT_ATY_SCT - Secteur d'activité
    attorneyJobTitle: string; // Requester.JOB_TTL - Job title
    attorneyBankId: string;
    attorneyBankBranchNumber: number;
    attorneyBankAccountNumber: number;
    attorneyBankPhone: number;

    // Comptes d'options
    optionActOtherBrokerIndicator: boolean;
    optionActOtherBrokerFirmName: string;
    optionActTradingExperienceIndicator: boolean;
    optionActExperienceSpecification: string;
    optionActTradingTheoriqueKnowledgeIndicator: boolean;
    optionActTradingPlannedOperationLevel1: boolean;
    optionActTradingPlannedOperationLevel2: boolean;
    optionActTradingPlannedOperationLevel3: boolean;
    optionActTradingPlannedOperationLevel4: boolean;
    optionActTradingExperienceYears1: string;
    optionActTradingExperienceYears2: string;
    optionActTradingExperienceYears3: string;
    optionActTradingExperienceYears4: string;
    optionActTradingExperienceOperationLevel1: boolean;
    optionActTradingExperienceOperationLevel2: boolean;
    optionActTradingExperienceOperationLevel3: boolean;
    optionActTradingExperienceOperationLevel4: boolean;

    // Demande de transfert
    transfer: boolean;
    rrspTransfer: boolean;
    tfsaTransfer: boolean;
    fhsaTransfer: boolean;
    respTransfer: boolean;
    liraTransfer: boolean;
    rrifTransfer: boolean;
    lifTransfer: boolean;
    ftarrspTransfer: boolean;

    transferNumberCopy: number;
    rrspTransferNumberCopy: number;
    tfsaTransferNumberCopy: number;
    fhsaTransferNumberCopy: number;
    respTransferNumberCopy: number;
    liraTransferNumberCopy: number;
    rrifTransferNumberCopy: number;
    lifTransferNumberCopy: number;
    ftarrspTransferNumberCopy: number;
    jointTransferNumberCopy: number;
    otherTransferNumberCopy: number;

    // Demande de transfert (Par compte)
    accountTransferList: AccountTransferRequest[];
    rrspAccountTransferList: AccountTransferRequest[];
    tfsaAccountTransferList: AccountTransferRequest[];
    fhsaAccountTransferList: AccountTransferRequest[];
    respAccountTransferList: AccountTransferRequest[];
    liraAccountTransferList: AccountTransferRequest[];
    rrifAccountTransferList: AccountTransferRequest[];
    lifAccountTransferList: AccountTransferRequest[];

    // Choix de transmission
    transmissionMethod: string;
    userIdentityFile: AttachedDocument;
    userSpecimenFile: AttachedDocument;
    userAllFiles: AttachedDocument;
    userIdentityFiles: [AttachedDocument, AttachedDocument][];
    esignUserPhone: string;
    esignAttorneyPhone: number;
    esignAttorneyEmail: string;
    esignCompleted: boolean;

    // Survivor ship
    resQc: boolean;
    survivorShip: string;

    significantChange: boolean;

    // Parcours conjoint
    jointActPeople: number;
    jointTransfer: boolean;
    jointSecondAccountholderSpouse: boolean;

    // Parcours autre compte
    /*
    * OTH_MBR_NBR
    * EST_EXC_NBR (Combien y a-t-il de liquidateurs à la succession?)
    *
    * */
    otherActPeople: number;
    actAmericanPeople: number;
    otherHoldingAccount: boolean;
    otherLinkedAccount: string;
    otherOneShareholder: string;
    otherAddSpouse: boolean;
    otherFiducieType: string;
    otherAccountHasAmericanResident: boolean;
    otherDeceasedProvince: string; // EST_PRV_DEAD
    otherAccountValueGreaterLimit: boolean; // EST_ACT_VAL (La valeur du compte est-elle supérieure à 50 000 $ ?)
    otherWill: boolean; // EST_WILL (Le défunt lègue-t-il un testament ou un contrat de mariage?)
    otherTransfer: boolean;
    otherIppRegistrationNumber: string;
    otherFirstName: string;
    otherLastName: string;
    otherGouvOrgCity: string;

    // account holder information
    accountHolderCompanyName: string;
    accountHolderFederalBusinessNumber: string;
    accountHolderProvincialBusinessNumber: string;
    accountHolderProvinceOfBusinessNumber: string;
    fiscalPeriodEndDate: string;

    entryMode: string;

    branch: Branch;

    advisorIdentity: AdvisorIdentity;

    srcCde: string;

    conversionIndicator: string;

    irsExpirationDate: string;

    mostRecentFormDate: string;

    requestUpdatePath: string;


    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    private getNumber(value): number {
        if (value == null) { // == is for null and undefined
            return null;
        } else {
            return +value;
        }
    }

    hasRegisteredAccount(): boolean {
        return this.addTFSA || this.addFHSA
            || this.addRRSP || this.addRRSPSec || this.addRRSPTer
            || this.addLIRA
            || this.addLIRASec
            || this.addRRIF
            || this.addRRIFSec
            || this.addLIF
            || this.addRESP
            || this.addFTARRSP || this.addFTARRSPSec || this.addFTARRSPTer;
    }

    constructor(obj?: any) {

        this.requesters = [];
        this.requesters.push(new Requester({}));

        if (obj) {
            this.requestID = this.init(obj.requestID);
            this.pleinEx = this.init(obj.pleinEx);
            this.gpd = this.init(obj.gpd);
            this.sfd = this.init(obj.sfd);
            this.ssd = this.init(obj.ssd);
            this.repId = this.init(obj.repId);

            this.lang = this.init(obj.lang);
            this.updateContext = this.init(obj.updateContext);

            this.currentSection = this.init(obj.currentSection);
            this.requestStatus = this.init(obj.requestStatus);

            this.newExistAct = this.init(obj.newExistAct);

            // Plateforme transactionnelle
            this.plateformDisnat = this.init(obj.plateformDisnat);

            this.accountNumberCad = this.init(obj.accountNumberCad);
            this.productNumberCad = this.init(obj.productNumberCad);
            this.accountNumberUsd = this.init(obj.accountNumberUsd);
            this.productNumberUsd = this.init(obj.productNumberUsd);

            // Type de gestion (plein ex)
            this.investmentAdviceComissionBased = this.init(obj.investmentAdviceComissionBased);
            this.investmentAdviceEliteBasic = this.init(obj.investmentAdviceEliteBasic);
            this.investmentAdviceEliteIntermediate = this.init(obj.investmentAdviceEliteIntermediate);
            this.investmentAdviceEliteFlexible = this.init(obj.investmentAdviceEliteFlexible);
            this.portfolioManagementAdvisorProgram = this.init(obj.portfolioManagementAdvisorProgram);
            this.portfolioManagementMandateOnly = this.init(obj.portfolioManagementMandateOnly);
            this.portfolioManagementAssetAllocation = this.init(
                (obj.portfolioManagementAssetAllocation && !Functions.isStatusInjection(obj.requestStatus)) ?
                    null : obj.portfolioManagementAssetAllocation
            );

            this.optionCode = this.init(obj.optionCode);
            this.registeredOptionCode = this.init(obj.registeredOptionCode);

            // Choix de compte non enregistré
            this.actType = this.init(obj.actType);
            this.optionAct = this.init(obj.optionAct);
            this.shortSellAct = this.init(obj.shortSellAct);
            this.cltNo = this.init(obj.cltNo);
            this.cltType = this.init(obj.cltType);
            this.currency = this.init(obj.currency);

            // Management type PleinEx mode Auto:
            this.cashAccountMgntType = Functions.initMgntType(obj.cashAccountMgntType, obj.requestStatus);
            this.tfsaAccountMgntType = Functions.initMgntType(obj.tfsaAccountMgntType, obj.requestStatus);
            this.fhsaAccountMgntType = Functions.initMgntType(obj.fhsaAccountMgntType, obj.requestStatus);
            this.rrspAccountMgntType = Functions.initMgntType(obj.rrspAccountMgntType, obj.requestStatus);
            this.rrspAccountMgntTypeSec = Functions.initMgntType(obj.rrspAccountMgntTypeSec, obj.requestStatus);
            this.rrspAccountMgntTypeTer = Functions.initMgntType(obj.rrspAccountMgntTypeTer, obj.requestStatus);
            this.liraAccountMgntType = Functions.initMgntType(obj.liraAccountMgntType, obj.requestStatus);
            this.liraAccountMgntTypeSec = Functions.initMgntType(obj.liraAccountMgntTypeSec, obj.requestStatus);
            this.rrifAccountMgntType = Functions.initMgntType(obj.rrifAccountMgntType, obj.requestStatus);
            this.rrifAccountMgntTypeSec = Functions.initMgntType(obj.rrifAccountMgntTypeSec, obj.requestStatus);
            this.lifAccountMgntType = Functions.initMgntType(obj.lifAccountMgntType, obj.requestStatus);
            this.respAccountMgntType = Functions.initMgntType(obj.respAccountMgntType, obj.requestStatus);
            this.ftarrspAccountMgntType = Functions.initMgntType(obj.ftarrspAccountMgntType, obj.requestStatus);
            this.ftarrspAccountMgntTypeSec = Functions.initMgntType(obj.ftarrspAccountMgntTypeSec, obj.requestStatus);
            this.ftarrspAccountMgntTypeTer = Functions.initMgntType(obj.ftarrspAccountMgntTypeTer, obj.requestStatus);

            this.addRRSP = this.init(obj.addRRSP);
            this.rrspCurrency = this.init(obj.rrspCurrency);
            this.rrspAnnuitant = this.init(obj.rrspAnnuitant);
            this.rrspContributingSpouseIsCurrentSpouse = this.init(obj.rrspContributingSpouseIsCurrentSpouse);
            this.addRRSPSec = this.init(obj.addRRSPSec);
            this.rrspCurrencySec = this.init(obj.rrspCurrencySec);
            this.rrspAnnuitantSec = this.init(obj.rrspAnnuitantSec);
            this.rrspContributingSpouseIsCurrentSpouseSec = this.init(obj.rrspContributingSpouseIsCurrentSpouseSec);
            this.addRRSPTer = this.init(obj.addRRSPTer);
            this.rrspCurrencyTer = this.init(obj.rrspCurrencyTer);
            this.rrspAnnuitantTer = this.init(obj.rrspAnnuitantTer);
            this.rrspContributingSpouseIsCurrentSpouseTer = this.init(obj.rrspContributingSpouseIsCurrentSpouseTer);

            this.addTFSA = this.init(obj.addTFSA);
            this.tfsaCurrency = this.init(obj.tfsaCurrency);

            this.addFHSA = this.init(obj.addFHSA);
            this.fhsaEligibility = this.init(obj.fhsaEligibility);
            this.fhsaCurrency = this.init(obj.fhsaCurrency);

            this.addRESP = this.init(obj.addRESP);
            this.respType = this.init(obj.respType);
            this.respNumberOfBeneficiaries = this.init(obj.respNumberOfBeneficiaries);
            this.respIsSubscriberResponsible = this.init(obj.respIsSubscriberResponsible);

            this.addLIRA = this.init(obj.addLIRA);
            this.liraCurrency = this.init(obj.liraCurrency);
            this.liraJurisdiction = this.init(obj.liraJurisdiction);
            this.addLIRASec = this.init(obj.addLIRASec);
            this.liraCurrencySec = this.init(obj.liraCurrencySec);
            this.liraJurisdictionSec = this.init(obj.liraJurisdictionSec);

            this.addRRIF = this.init(obj.addRRIF);
            this.rrifCurrency = this.init(obj.rrifCurrency);
            this.rrifAnnuitant = this.init(obj.rrifAnnuitant);
            this.rrifContributingSpouseIsCurrentSpouse = this.init(obj.rrifContributingSpouseIsCurrentSpouse);
            this.addRRIFSec = this.init(obj.addRRIFSec);
            this.rrifCurrencySec = this.init(obj.rrifCurrencySec);
            this.rrifAnnuitantSec = this.init(obj.rrifAnnuitantSec);
            this.rrifContributingSpouseIsCurrentSpouseSec = this.init(obj.rrifContributingSpouseIsCurrentSpouseSec);

            this.addLIF = this.init(obj.addLIF);
            this.lifCurrency = this.init(obj.lifCurrency);
            this.lifJurisdiction = this.init(obj.lifJurisdiction);

            this.addFTARRSP = this.init(obj.addFTARRSP);
            this.ftarrspAnnuitant = this.init(obj.ftarrspAnnuitant);
            this.ftarrspContributingSpouseIsCurrentSpouse = this.init(obj.ftarrspContributingSpouseIsCurrentSpouse);
            this.ftarrspRevocableBeneficiaryIndicator = this.init(obj.ftarrspRevocableBeneficiaryIndicator);
            this.addFTARRSPSec = this.init(obj.addFTARRSPSec);
            this.ftarrspAnnuitantSec = this.init(obj.ftarrspAnnuitantSec);
            this.ftarrspContributingSpouseIsCurrentSpouseSec = this.init(obj.ftarrspContributingSpouseIsCurrentSpouseSec);
            this.ftarrspRevocableBeneficiaryIndicatorSec = this.init(obj.ftarrspRevocableBeneficiaryIndicatorSec);
            this.addFTARRSPTer = this.init(obj.addFTARRSPTer);
            this.ftarrspAnnuitantTer = this.init(obj.ftarrspAnnuitantTer);
            this.ftarrspContributingSpouseIsCurrentSpouseTer = this.init(obj.ftarrspContributingSpouseIsCurrentSpouseTer);
            this.ftarrspRevocableBeneficiaryIndicatorTer = this.init(obj.ftarrspRevocableBeneficiaryIndicatorTer);

            // Informations personelles du client

            // Demande de transfert (Par compte)
            if (obj.requesters && obj.requesters.length > 0) {
                this.requesters = [];

                for (const requester of obj.requesters) {

                    this.requesters.push(new Requester(requester));
                }
            }

            // Lieu de résidence aux fins fiscales
            this.userOtherFiscal1 = this.init(obj.userOtherFiscal1);
            this.userOtherFiscal2 = this.init(obj.userOtherFiscal2);
            this.userOtherCountry1 = this.init(obj.userOtherCountry1);
            this.userOtherCountry2 = this.init(obj.userOtherCountry2);

            // Occupation et Employeur
            this.userEmployerAddress = this.init(obj.userEmployerAddress);
            this.userEmployerAddressUnit = Functions.initByLength(obj.userEmployerAddressUnit, 8);
            this.userEmployerAddressCity = Functions.initByLength(obj.userEmployerAddressCity, 32);
            this.userEmployerAddressProv = this.init(obj.userEmployerAddressProv);
            this.userEmployerAddressPostalCode = this.init(obj.userEmployerAddressPostalCode);

            // Borrowed for investment purpose PleinEx only
            this.borrowedForInvestmentPurposeIndicator = this.init(obj.borrowedForInvestmentPurposeIndicator);
            this.borrowingAmountOfLoan = this.init(obj.borrowingAmountOfLoan);
            this.borrowingInterestRate = this.init(obj.borrowingInterestRate);
            this.borrowingLoanStartingDate = this.init(obj.borrowingLoanStartingDate);
            this.borrowingLoanMaturityDate = this.init(obj.borrowingLoanMaturityDate);
            this.borrowingAmountInvested = this.init(obj.borrowingAmountInvested);
            this.borrowingReceivedAndReadBorrowingRisksIndicator = this.init(obj.borrowingReceivedAndReadBorrowingRisksIndicator);

            // User Investment Objectives - PleinEx only
            this.userInvestmentObjectivesIntendedUse = this.init(obj.userInvestmentObjectivesIntendedUse);
            this.userInvestmentObjectivesNonRegInvHorizon = this.init(obj.userInvestmentObjectivesNonRegInvHorizon);
            this.userInvestmentObjectivesRegInvHorizon = this.init(obj.userInvestmentObjectivesRegInvHorizon);
            this.userInvestmentObjectivesRskTol = this.init(obj.userInvestmentObjectivesRskTol);
            this.userInvestmentObjectivesLowRiskNonReg = this.init(obj.userInvestmentObjectivesLowRiskNonReg);
            this.userInvestmentObjectivesModerateRiskNonReg = this.init(obj.userInvestmentObjectivesModerateRiskNonReg);
            this.userInvestmentObjectivesModHighRiskNonReg = this.init(obj.userInvestmentObjectivesModHighRiskNonReg);
            this.userInvestmentObjectivesSpeculativeStrategiesNonReg = this.init(obj.userInvestmentObjectivesSpeculativeStrategiesNonReg);
            this.userInvestmentObjectivesLowRiskReg = this.init(obj.userInvestmentObjectivesLowRiskReg);
            this.userInvestmentObjectivesModerateRiskReg = this.init(obj.userInvestmentObjectivesModerateRiskReg);
            this.userInvestmentObjectivesModHighRiskReg = this.init(obj.userInvestmentObjectivesModHighRiskReg);
            this.userInvestmentObjectivesSpeculativeStrategiesReg = this.init(obj.userInvestmentObjectivesSpeculativeStrategiesReg);
            this.includeInvestorProfile = this.init(obj.includeInvestorProfile);

            // Désignation de bénéficiaires (REER)
            this.beneficiaryReerActiveIndicator = this.init(obj.beneficiaryReerActiveIndicator);
            this.beneficiaryReerGender = this.init(obj.beneficiaryReerGender);
            this.beneficiaryReerFirstName = this.init(obj.beneficiaryReerFirstName);
            this.beneficiaryReerLastName = this.init(obj.beneficiaryReerLastName);
            this.beneficiaryReerDOB = this.init(obj.beneficiaryReerDOB);
            this.beneficiaryReerSIN = this.init(obj.beneficiaryReerSIN);
            this.beneficiaryReerAdressStreet = this.init(obj.beneficiaryReerAdressStreet);
            this.beneficiaryReerAdressUnit = Functions.initByLength(obj.beneficiaryReerAdressUnit, 8);
            this.beneficiaryReerAdressCity = Functions.initByLength(obj.beneficiaryReerAdressCity, 32);
            this.beneficiaryReerAdressProv = this.init(obj.beneficiaryReerAdressProv);
            this.beneficiaryReerAdressPostalCode = this.init(obj.beneficiaryReerAdressPostalCode);
            this.beneficiaryReerAdressCountry = Functions.initCountryAddress(obj.beneficiaryReerAdressCountry);
            this.beneficiaryReerParentLink = this.init(obj.beneficiaryReerParentLink);
            this.beneficiaryReerPart = this.init(obj.beneficiaryReerPart);
            this.beneficiaryReerGenderSec = this.init(obj.beneficiaryReerGenderSec);
            this.beneficiaryReerFirstNameSec = this.init(obj.beneficiaryReerFirstNameSec);
            this.beneficiaryReerLastNameSec = this.init(obj.beneficiaryReerLastNameSec);
            this.beneficiaryReerDOBSec = this.init(obj.beneficiaryReerDOBSec);
            this.beneficiaryReerSINSec = this.init(obj.beneficiaryReerSINSec);
            this.beneficiaryReerAdressStreetSec = this.init(obj.beneficiaryReerAdressStreetSec);
            this.beneficiaryReerAdressUnitSec = Functions.initByLength(obj.beneficiaryReerAdressUnitSec, 8);
            this.beneficiaryReerAdressCitySec = Functions.initByLength(obj.beneficiaryReerAdressCitySec, 32);
            this.beneficiaryReerAdressProvSec = this.init(obj.beneficiaryReerAdressProvSec);
            this.beneficiaryReerAdressPostalCodeSec = this.init(obj.beneficiaryReerAdressPostalCodeSec);
            this.beneficiaryReerAdressCountrySec = Functions.initCountryAddress(obj.beneficiaryReerAdressCountrySec);
            this.beneficiaryReerParentLinkSec = this.init(obj.beneficiaryReerParentLinkSec);
            this.beneficiaryReerPartSec = this.init(obj.beneficiaryReerPartSec);
            this.beneficiaryReerGenderTer = this.init(obj.beneficiaryReerGenderTer);
            this.beneficiaryReerFirstNameTer = this.init(obj.beneficiaryReerFirstNameTer);
            this.beneficiaryReerLastNameTer = this.init(obj.beneficiaryReerLastNameTer);
            this.beneficiaryReerDOBTer = this.init(obj.beneficiaryReerDOBTer);
            this.beneficiaryReerSINTer = this.init(obj.beneficiaryReerSINTer);
            this.beneficiaryReerAdressStreetTer = this.init(obj.beneficiaryReerAdressStreetTer);
            this.beneficiaryReerAdressUnitTer = Functions.initByLength(obj.beneficiaryReerAdressUnitTer, 8);
            this.beneficiaryReerAdressCityTer = Functions.initByLength(obj.beneficiaryReerAdressCityTer, 32);
            this.beneficiaryReerAdressProvTer = this.init(obj.beneficiaryReerAdressProvTer);
            this.beneficiaryReerAdressPostalCodeTer = this.init(obj.beneficiaryReerAdressPostalCodeTer);
            this.beneficiaryReerAdressCountryTer = Functions.initCountryAddress(obj.beneficiaryReerAdressCountryTer);
            this.beneficiaryReerParentLinkTer = this.init(obj.beneficiaryReerParentLinkTer);
            this.beneficiaryReerPartTer = this.init(obj.beneficiaryReerPartTer);

            // Désignation de bénéficiaires (CELI)
            this.beneficiaryCeliConjointTitulaire = this.init(obj.beneficiaryCeliConjointTitulaire);
            this.beneficiaryCeliActiveIndicator = this.init(obj.beneficiaryCeliActiveIndicator);
            this.beneficiaryCeliGender = this.init(obj.beneficiaryCeliGender);
            this.beneficiaryCeliFirstName = this.init(obj.beneficiaryCeliFirstName);
            this.beneficiaryCeliLastName = this.init(obj.beneficiaryCeliLastName);
            this.beneficiaryCeliDOB = this.init(obj.beneficiaryCeliDOB);
            this.beneficiaryCeliSIN = this.init(obj.beneficiaryCeliSIN);
            this.beneficiaryCeliAdressStreet = this.init(obj.beneficiaryCeliAdressStreet);
            this.beneficiaryCeliAdressUnit = Functions.initByLength(obj.beneficiaryCeliAdressUnit, 8);
            this.beneficiaryCeliAdressCity = Functions.initByLength(obj.beneficiaryCeliAdressCity, 32);
            this.beneficiaryCeliAdressProv = this.init(obj.beneficiaryCeliAdressProv);
            this.beneficiaryCeliAdressPostalCode = this.init(obj.beneficiaryCeliAdressPostalCode);
            this.beneficiaryCeliAdressCountry = Functions.initCountryAddress(obj.beneficiaryCeliAdressCountry);
            this.beneficiaryCeliParentLink = this.init(obj.beneficiaryCeliParentLink);
            this.beneficiaryCeliPart = this.init(obj.beneficiaryCeliPart);
            this.beneficiaryCeliGenderSec = this.init(obj.beneficiaryCeliGenderSec);
            this.beneficiaryCeliFirstNameSec = this.init(obj.beneficiaryCeliFirstNameSec);
            this.beneficiaryCeliLastNameSec = this.init(obj.beneficiaryCeliLastNameSec);
            this.beneficiaryCeliDOBSec = this.init(obj.beneficiaryCeliDOBSec);
            this.beneficiaryCeliSINSec = this.init(obj.beneficiaryCeliSINSec);
            this.beneficiaryCeliAdressStreetSec = this.init(obj.beneficiaryCeliAdressStreetSec);
            this.beneficiaryCeliAdressUnitSec = Functions.initByLength(obj.beneficiaryCeliAdressUnitSec, 8);
            this.beneficiaryCeliAdressCitySec = Functions.initByLength(obj.beneficiaryCeliAdressCitySec, 32);
            this.beneficiaryCeliAdressProvSec = this.init(obj.beneficiaryCeliAdressProvSec);
            this.beneficiaryCeliAdressPostalCodeSec = this.init(obj.beneficiaryCeliAdressPostalCodeSec);
            this.beneficiaryCeliAdressCountrySec = Functions.initCountryAddress(obj.beneficiaryCeliAdressCountrySec);
            this.beneficiaryCeliParentLinkSec = this.init(obj.beneficiaryCeliParentLinkSec);
            this.beneficiaryCeliPartSec = this.init(obj.beneficiaryCeliPartSec);
            this.beneficiaryCeliGenderTer = this.init(obj.beneficiaryCeliGenderTer);
            this.beneficiaryCeliFirstNameTer = this.init(obj.beneficiaryCeliFirstNameTer);
            this.beneficiaryCeliLastNameTer = this.init(obj.beneficiaryCeliLastNameTer);
            this.beneficiaryCeliDOBTer = this.init(obj.beneficiaryCeliDOBTer);
            this.beneficiaryCeliSINTer = this.init(obj.beneficiaryCeliSINTer);
            this.beneficiaryCeliAdressStreetTer = this.init(obj.beneficiaryCeliAdressStreetTer);
            this.beneficiaryCeliAdressUnitTer = Functions.initByLength(obj.beneficiaryCeliAdressUnitTer, 8);
            this.beneficiaryCeliAdressCityTer = Functions.initByLength(obj.beneficiaryCeliAdressCityTer, 32);
            this.beneficiaryCeliAdressProvTer = this.init(obj.beneficiaryCeliAdressProvTer);
            this.beneficiaryCeliAdressPostalCodeTer = this.init(obj.beneficiaryCeliAdressPostalCodeTer);
            this.beneficiaryCeliAdressCountryTer = Functions.initCountryAddress(obj.beneficiaryCeliAdressCountryTer);
            this.beneficiaryCeliParentLinkTer = this.init(obj.beneficiaryCeliParentLinkTer);
            this.beneficiaryCeliPartTer = this.init(obj.beneficiaryCeliPartTer);

            // Désignation du Titulaire Remplaçante (CELIAPP)
            this.successorHolderCeliAppActiveIndicator = this.init(obj.successorHolderCeliAppActiveIndicator);
            this.successorHolderCeliAppGender = this.init(obj.successorHolderCeliAppGender);
            this.successorHolderCeliAppFirstName = this.init(obj.successorHolderCeliAppFirstName);
            this.successorHolderCeliAppLastName = this.init(obj.successorHolderCeliAppLastName);
            this.successorHolderCeliAppSIN = this.init(obj.successorHolderCeliAppSIN);
            this.successorHolderCeliAppPart = this.init(obj.successorHolderCeliAppPart);

            // Désignation de bénéficiaires (CELIAPP)
            this.beneficiaryCeliAppActiveIndicator = this.init(obj.beneficiaryCeliAppActiveIndicator);
            this.beneficiaryCeliAppGender = this.init(obj.beneficiaryCeliAppGender);
            this.beneficiaryCeliAppFirstName = this.init(obj.beneficiaryCeliAppFirstName);
            this.beneficiaryCeliAppLastName = this.init(obj.beneficiaryCeliAppLastName);
            this.beneficiaryCeliAppDOB = this.init(obj.beneficiaryCeliAppDOB);
            this.beneficiaryCeliAppSIN = this.init(obj.beneficiaryCeliAppSIN);
            this.beneficiaryCeliAppParentLink = this.init(obj.beneficiaryCeliAppParentLink);
            this.beneficiaryCeliAppPart = this.init(obj.beneficiaryCeliAppPart);
            this.beneficiaryCeliAppGenderSec = this.init(obj.beneficiaryCeliAppGenderSec);
            this.beneficiaryCeliAppFirstNameSec = this.init(obj.beneficiaryCeliAppFirstNameSec);
            this.beneficiaryCeliAppLastNameSec = this.init(obj.beneficiaryCeliAppLastNameSec);
            this.beneficiaryCeliAppDOBSec = this.init(obj.beneficiaryCeliAppDOBSec);
            this.beneficiaryCeliAppSINSec = this.init(obj.beneficiaryCeliAppSINSec);
            this.beneficiaryCeliAppParentLinkSec = this.init(obj.beneficiaryCeliAppParentLinkSec);
            this.beneficiaryCeliAppPartSec = this.init(obj.beneficiaryCeliAppPartSec);
            this.beneficiaryCeliAppGenderTer = this.init(obj.beneficiaryCeliAppGenderTer);
            this.beneficiaryCeliAppFirstNameTer = this.init(obj.beneficiaryCeliAppFirstNameTer);
            this.beneficiaryCeliAppLastNameTer = this.init(obj.beneficiaryCeliAppLastNameTer);
            this.beneficiaryCeliAppDOBTer = this.init(obj.beneficiaryCeliAppDOBTer);
            this.beneficiaryCeliAppSINTer = this.init(obj.beneficiaryCeliAppSINTer);
            this.beneficiaryCeliAppParentLinkTer = this.init(obj.beneficiaryCeliAppParentLinkTer);
            this.beneficiaryCeliAppPartTer = this.init(obj.beneficiaryCeliAppPartTer);

            // Désignation de bénéficiaires (REEE)
            this.beneficiaryRespBrotherAndSisterOption = this.init(obj.beneficiaryRespBrotherAndSisterOption);
            this.beneficiaryRespReason = this.init(obj.beneficiaryRespReason);
            this.requestCESGForResp = this.init(obj.requestCESGForResp);

            this.beneficiaryRespList = [];
            if (obj.beneficiaryRespList) {

                for (const beneficiary of obj.beneficiaryRespList) {
                    this.beneficiaryRespList.push(new BeneficiaryRespBean(beneficiary));
                }
            }

            // Désignation de mandataire
            this.attorneyGender = this.init(obj.attorneyGender);
            this.attorneyAddressCity = Functions.initByLength(obj.attorneyAddressCity, 32);
            this.attorneyAddressPostalCode = this.init(obj.attorneyAddressPostalCode);
            this.attorneyAddressCountry = Functions.initCountryAddress(obj.attorneyAddressCountry);
            this.attorneyAddressProv = this.init(obj.attorneyAddressProv);
            this.attorneyAddressStreet = this.init(obj.attorneyAddressStreet);
            this.attorneyAddressUnit = Functions.initByLength(obj.attorneyAddressUnit, 8);
            this.attorneyDOB = this.init(obj.attorneyDOB);
            this.attorneyFirstName = this.init(obj.attorneyFirstName);
            this.attorneyHomePhone = this.init(obj.attorneyHomePhone);
            this.attorneyJobStatus = this.init(obj.attorneyJobStatus);
            this.attorneyLastName = this.init(obj.attorneyLastName);
            this.attorneyOtherPhone = this.init(obj.attorneyOtherPhone);
            this.attorneySIN = this.init(obj.attorneySIN);
            this.attorneyClientNumber = this.init(obj.attorneyClientNumber);
            this.attorneyClientRelationship = this.init(obj.attorneyClientRelationship);
            this.attorneyInitieIndicator = this.init(obj.attorneyInitieIndicator);
            this.attorneyInitieCompanyName = this.init(obj.attorneyInitieCompanyName);
            this.attorneyInitieCompanySymbol = this.init(obj.attorneyInitieCompanySymbol);
            this.attorneyInitieMarket = this.init(obj.attorneyInitieMarket);
            this.attorneyMainShareholderIndicator = this.init(obj.attorneyMainShareholderIndicator);
            this.attorneyMainShareholderCompanyName = this.init(obj.attorneyMainShareholderCompanyName);
            this.attorneyMainShareholderCompanySymbol = this.init(obj.attorneyMainShareholderCompanySymbol);
            this.attorneyMainShareholderMarket = this.init(obj.attorneyMainShareholderMarket);
            this.attorneyEmployerIsBroker = this.init(obj.attorneyEmployerIsBroker);
            this.attorneyEmployerName = this.init(obj.attorneyEmployerName);
            this.attorneyEmployerActivity = this.init(obj.attorneyEmployerActivity);
            this.attorneyJobTitle = this.init(obj.attorneyJobTitle);
            this.attorneyBankId = this.init(obj.attorneyBankId);
            this.attorneyBankBranchNumber = this.init(obj.attorneyBankBranchNumber);
            this.attorneyBankAccountNumber = this.init(obj.attorneyBankAccountNumber);
            this.attorneyBankPhone = this.init(obj.attorneyBankPhone);

            // Comptes d'options
            this.optionActOtherBrokerIndicator = this.init(obj.optionActOtherBrokerIndicator);
            this.optionActOtherBrokerFirmName = this.init(obj.optionActOtherBrokerFirmName);
            this.optionActTradingExperienceIndicator = this.init(obj.optionActTradingExperienceIndicator);
            this.optionActExperienceSpecification = this.init(obj.optionActExperienceSpecification);
            this.optionActTradingTheoriqueKnowledgeIndicator = this.init(obj.optionActTradingTheoriqueKnowledgeIndicator);
            this.optionActTradingPlannedOperationLevel1 = this.init(obj.optionActTradingPlannedOperationLevel1);
            this.optionActTradingPlannedOperationLevel2 = this.init(obj.optionActTradingPlannedOperationLevel2);
            this.optionActTradingPlannedOperationLevel3 = this.init(obj.optionActTradingPlannedOperationLevel3);
            this.optionActTradingPlannedOperationLevel4 = this.init(obj.optionActTradingPlannedOperationLevel4);
            this.optionActTradingExperienceYears1 = this.init(obj.optionActTradingExperienceYears1);
            this.optionActTradingExperienceYears2 = this.init(obj.optionActTradingExperienceYears2);
            this.optionActTradingExperienceYears3 = this.init(obj.optionActTradingExperienceYears3);
            this.optionActTradingExperienceYears4 = this.init(obj.optionActTradingExperienceYears4);
            this.optionActTradingExperienceOperationLevel1 = this.init(obj.optionActTradingExperienceOperationLevel1);
            this.optionActTradingExperienceOperationLevel2 = this.init(obj.optionActTradingExperienceOperationLevel2);
            this.optionActTradingExperienceOperationLevel3 = this.init(obj.optionActTradingExperienceOperationLevel3);
            this.optionActTradingExperienceOperationLevel4 = this.init(obj.optionActTradingExperienceOperationLevel4);

            // Demande de transfert
            this.transfer = this.init(obj.transfer);
            this.rrspTransfer = this.init(obj.rrspTransfer);
            this.tfsaTransfer = this.init(obj.tfsaTransfer);
            this.fhsaTransfer = this.init(obj.fhsaTransfer);
            this.respTransfer = this.init(obj.respTransfer);
            this.liraTransfer = this.init(obj.liraTransfer);
            this.rrifTransfer = this.init(obj.rrifTransfer);
            this.lifTransfer = this.init(obj.lifTransfer);
            this.ftarrspTransfer = this.init(obj.ftarrspTransfer);

            this.transferNumberCopy = this.init(obj.transferNumberCopy);
            this.rrspTransferNumberCopy = this.init(obj.rrspTransferNumberCopy);
            this.tfsaTransferNumberCopy = this.init(obj.tfsaTransferNumberCopy);
            this.fhsaTransferNumberCopy = this.init(obj.fhsaTransferNumberCopy);
            this.respTransferNumberCopy = this.init(obj.respTransferNumberCopy);
            this.liraTransferNumberCopy = this.init(obj.liraTransferNumberCopy);
            this.rrifTransferNumberCopy = this.init(obj.rrifTransferNumberCopy);
            this.lifTransferNumberCopy = this.init(obj.lifTransferNumberCopy);
            this.ftarrspTransferNumberCopy = this.init(obj.ftarrspTransferNumberCopy);
            this.jointTransferNumberCopy = this.init(obj.jointTransferNumberCopy);
            this.otherTransferNumberCopy = this.init(obj.otherTransferNumberCopy);

            // Demande de transfert (Par compte)
            this.accountTransferList = [];
            if (obj.accountTransferList) {

                for (const accountTransfer of obj.accountTransferList) {

                    this.accountTransferList.push(new AccountTransferRequest(accountTransfer));
                }
            }

            this.rrspAccountTransferList = [];
            if (obj.rrspAccountTransferList) {

                for (const rrspAccountTransfer of obj.rrspAccountTransferList) {

                    this.rrspAccountTransferList.push(new AccountTransferRequest(rrspAccountTransfer));
                }
            }

            this.tfsaAccountTransferList = [];
            if (obj.tfsaAccountTransferList) {

                for (const tfsaAccountTransfer of obj.tfsaAccountTransferList) {

                    this.tfsaAccountTransferList.push(new AccountTransferRequest(tfsaAccountTransfer));
                }
            }

            this.fhsaAccountTransferList = [];
            if (obj.fhsaAccountTransferList) {

                for (const fhsaAccountTransfer of obj.fhsaAccountTransferList) {

                    this.fhsaAccountTransferList.push(new AccountTransferRequest(fhsaAccountTransfer));
                }
            }

            this.respAccountTransferList = [];
            if (obj.respAccountTransferList) {

                for (const respAccountTransfer of obj.respAccountTransferList) {

                    this.respAccountTransferList.push(new AccountTransferRequest(respAccountTransfer));
                }
            }

            this.liraAccountTransferList = [];
            if (obj.liraAccountTransferList) {

                for (const liraAccountTransfer of obj.liraAccountTransferList) {

                    this.liraAccountTransferList.push(new AccountTransferRequest(liraAccountTransfer));
                }
            }

            this.rrifAccountTransferList = [];
            if (obj.rrifAccountTransferList) {

                for (const rrifAccountTransfer of obj.rrifAccountTransferList) {

                    this.rrifAccountTransferList.push(new AccountTransferRequest(rrifAccountTransfer));
                }
            }

            this.lifAccountTransferList = [];
            if (obj.lifAccountTransferList) {

                for (const lifAccountTransfer of obj.lifAccountTransferList) {

                    this.lifAccountTransferList.push(new AccountTransferRequest(lifAccountTransfer));
                }
            }

            // Choix de transmission
            this.transmissionMethod = this.init(obj.transmissionMethod);
            this.userIdentityFile = new AttachedDocument(obj.userIdentityFile || null);
            this.userSpecimenFile = new AttachedDocument(obj.userSpecimenFile || null);
            this.userAllFiles = new AttachedDocument(obj.userAllFiles || null);
            this.userIdentityFiles = [];
            if (obj.userIdentityFiles) {

                for (const userIdentityFile of obj.userIdentityFiles) {

                    this.userIdentityFiles.push([new AttachedDocument(userIdentityFile[0]), new AttachedDocument(userIdentityFile[1])]);
                }
            }
            this.esignUserPhone = this.init(obj.esignUserPhone);
            this.esignAttorneyPhone = this.init(obj.esignAttorneyPhone);
            this.esignAttorneyEmail = this.init(obj.esignAttorneyEmail);
            this.esignCompleted = this.init(obj.esignCompleted);

            this.resQc = this.init(obj.resQc);
            this.survivorShip = this.init(obj.survivorShip);
            this.significantChange = this.init(obj.significantChange);

            this.jointActPeople = this.init(this.getNumber(obj.jointActPeople));
            this.jointTransfer = this.init(obj.jointTransfer);
            this.jointSecondAccountholderSpouse = this.init(obj.jointSecondAccountholderSpouse);

            this.otherActPeople = this.init(obj.otherActPeople);
            this.actAmericanPeople = this.init(obj.actAmericanPeople);
            this.otherHoldingAccount = this.init(obj.otherHoldingAccount);
            this.otherLinkedAccount = this.init(obj.otherLinkedAccount);
            this.otherOneShareholder = this.init(obj.otherOneShareholder);
            this.otherAddSpouse = this.init(obj.otherAddSpouse);
            this.otherFiducieType = this.init(obj.otherFiducieType);
            this.otherAccountHasAmericanResident = this.init(obj.otherAccountHasAmericanResident);
            this.otherDeceasedProvince = this.init(obj.otherDeceasedProvince);
            this.otherAccountValueGreaterLimit = this.init(obj.otherAccountValueGreaterLimit);
            this.otherWill = this.init(obj.otherWill);
            this.otherTransfer = this.init(obj.otherTransfer);
            this.otherIppRegistrationNumber = this.init(obj.otherIppRegistrationNumber);
            this.otherFirstName = this.init(obj.otherFirstName);
            this.otherLastName = this.init(obj.otherLastName);
            this.otherGouvOrgCity = this.init(obj.otherGouvOrgCity);

            this.accountHolderCompanyName = this.init(obj.accountHolderCompanyName);
            this.accountHolderFederalBusinessNumber = this.init(obj.accountHolderFederalBusinessNumber);
            this.accountHolderProvincialBusinessNumber = this.init(obj.accountHolderProvincialBusinessNumber);
            this.accountHolderProvinceOfBusinessNumber = this.init(obj.accountHolderProvinceOfBusinessNumber);
            this.fiscalPeriodEndDate = this.init(obj.fiscalPeriodEndDate);

            this.entryMode = this.init(obj.entryMode) || VmdConstants.MODES.PRINT;

            // Entity
            this.entities = this.init(obj.entities);

            this.branch = this.init(obj.branch || null);

            this.srcCde = this.init(obj.srcCde);

            this.advisorIdentity = this.init(obj.advisorIdentity);

            this.conversionIndicator = this.init(obj.conversionIndicator);

            this.irsExpirationDate = this.init(obj.irsExpirationDate);

            this.mostRecentFormDate = this.init(obj.mostRecentFormDate);

            this.requestUpdatePath = this.init(obj.requestUpdatePath);
        }
    }
}
