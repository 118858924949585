<h1>
    <!--    OCE-7985    -->
    {{isUpdateAccount() ? ('HEADER_TITLE_PART1_UPDATE_ACCOUNT' | translate) : ('HEADER_TITLE_PART1' | translate) }}
    <small>{{ printCurrentCltType() }}</small>
</h1>

<div *ngIf="showTab()" id="odc-etape-bulle">

    <div class="etape-bulle" data-initplugin="n3EtapeBulle">
        <p class="nbEtapeTxt">
            <strong>{{'STEP_NAV_STEPS_SIZE'|translate: {size: getStepSize(), plural: (getStepSize() > 1) ? 's' : ''} }}</strong>
        </p>
        <div class="etape-bulle-pagination">
            <button class="etape-bulle-button etape-bulle-button-previous js-previous hide" type="button"><span
                class="sr-only">{{'STEP_NAV_PREVIOUS_STEPS'|translate}}</span></button>
            <ul class="etape-bulle-list" role="tablist">
                <ng-container *ngFor="let item of routesConfig; let i = index">

                    <li class="etape-bulle-item"
                        role="presentation"
                        *ngIf="item.showTab && getEnabledSubStepLength(i) > 0"
                        [class.visited]="isVisitedTab(i) && !isActiveTab(i)"
                        [class.active]="isActiveTab(i)"
                        [class.disabled]="isDisabledTab(i)"
                    >
                        <a class="etape-bulle-cercle"
                           href="javascript:void(0)"
                           data-toggle="tab"
                           role="tab"
                           [attr.aria-selected]="isActiveTab(i)"
                           [attr.aria-expanded]="isActiveTab(i)"
                           [attr.aria-disabled]="isDisabledTab(i)"
                           (click)="backToStepLink((isActiveTab(i) || isDisabledTab(i)) ? null : getFirstEnabledSubStep(item))"
                           [class.disabled]="isSaving || isDisabledTab(i)"
                        >
                            <span class="sr-only">{{'STEP_NAV_ITEM_STEP'|translate}}</span>
                            <span class="etape-bulle-nombre">{{item.step}}</span>
                            <span *ngIf="isVisitedTab(i) && !isActiveTab(i)" class="etape-bulle-complete sr-only">
                                {{'STEP_NAV_ITEM_COMPLETED'|translate}}
                            </span>
                            <span *ngIf="isActiveTab(i)" class="etape-bulle-active sr-only">
                                {{'STEP_NAV_ITEM_SELECTED'|translate}}
                            </span>
                        </a>
                    </li>
                </ng-container>
            </ul>
            <button class="etape-bulle-button etape-bulle-button-next js-next" type="button"><span
                class="sr-only">{{'STEP_NAV_NEXT_STEPS'|translate}}</span></button>
        </div>
    </div>

    <h2>
        <ng-container *ngFor="let item of routesConfig; let i = index">
            <span *ngIf="isActiveTab(i)" [innerHTML]="item.label | translate"></span>
        </ng-container>
        <app-help *ngIf="showTitleHelp()" [templateRefFr]="getTitleHelpTemplateFr()"
                  [templateRefEn]="getTitleHelpTemplateEn()"></app-help>
    </h2>
</div>

<div *ngIf="showSubTab()" role="navigation">
    <ul class="nav nav-pills onglets-group" role="tablist">
        <ng-container *ngFor="let subitem of getCurrentSubTabs(); let j = index">
            <li
                *ngIf="!subitem.disable"
                [class.completed]="!isPleinEx() && !isActiveSubTab(j) && !isDisabledSubTab(j)"
                [class.active]="isActiveSubTab(j)"
                [class.disabled]="isDisabledSubTab(j)"
                role="presentation"
            >
                <a
                    href="#content-tabpills-1"
                    data-toggle="tab"
                    [attr.aria-selected]="isActiveSubTab(j) ? 'true' : false"
                    [attr.aria-expanded]="isActiveSubTab(j) ? 'true' : false"
                    [class.disabled]="isSaving"
                    role="tab"
                    (click)="backToStepLink((isActiveSubTab(j) || isDisabledSubTab(j)) ? null : getSubStepLink(subitem))"
                >
                    <span *ngIf="subitem.label !== 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED'">
                        {{subitem.label | translate}}
                    </span>
                    <span *ngIf="subitem.label === 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED'">
                        <span *ngIf="formService.getForm().actType === constants.ACCOUNT_TYPE_CASH">
                            {{'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CASH'|translate}}
                        </span>
                        <span *ngIf="formService.getForm().actType !== constants.ACCOUNT_TYPE_CASH">
                            {{'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_MARGIN'|translate}}
                        </span>
                    </span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>

<div class="row">

    <div class="col-md-16">
        <div class="panel panel-simple">
            <div id="questionnaire-content" class="panel-body" tabindex="0">

                <app-help-warning [messageKey]="getHelpWarningMessageKey()"></app-help-warning>

                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <div *ngIf="!isBranch()" class="col-md-8">
        <app-aside [item]="getCurrentSubTab()"></app-aside>
    </div>
    <div *ngIf="isBranch()" class="col-md-8">
        <app-aside-caisse
            [item]="getCurrentSubTab()"
            [showConsult]="showAsideCaisseConsult()"
            [showSave]="isCurrentStep2OrAfter()"
            [showUpdateForm]="showAsideCaisseUpdateForm()">
        </app-aside-caisse>
    </div>

</div>
