<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
        {{'BROKERAGE_TITLE' | translate}}
    </h2>

    <app-error-list [ngFormRef]="ngForm" [form]="form"></app-error-list>

    <div *ngIf="showMention()">
        {{ (isGpdOrSfd() ? 'BROKER_QUESTIONS_NATURAL_PERSONS_HOLDING_25_PERCENT' : 'BROKER_QUESTIONS_NATURAL_PERSONS_HOLDING_VDX62_25_PERCENT') | translate }}
        <br/><br/>
    </div>

    <div *ngIf="!isPleinEx()">

        <!--brokerageOtherAccountsIndicator-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_OTHER_ACCOUNTS_TITLE' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'brokerageOtherAccountsIndicator'"
            [name]="'brokerageOtherAccountsIndicator'"
            [path]="'brokerageOtherAccountsIndicator'"
            [binding]="getRequesterPath('brokerageOtherAccountsIndicator')"
        ></app-odc-field>

        <div *ngIf="form.get('brokerageOtherAccountsIndicator').value" formGroupName="brokerageOtherAccounts">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form.get('brokerageOtherAccounts')"
                [submitted]="isFormSubmitted()"
                [label]="'BROKERAGE_FIRM_NAME' | translate"
                [id]="'brokerageOtherAccountsFirmName'"
                [name]="'brokerageOtherAccountsFirmName'"
                [path]="'brokerageOtherAccountsFirmName'"
                [binding]="getRequesterPath('brokerageOtherAccountsFirmName')"
                [attr]="{maxlength: 32}"
            ></app-odc-field>

            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form.get('brokerageOtherAccounts')"
                [submitted]="isFormSubmitted()"
                [label]="'BROKERAGE_ACCOUNT_TYPE' | translate"
                [id]="'brokerageOtherAccountsAccountType'"
                [name]="'brokerageOtherAccountsAccountType'"
                [path]="'brokerageOtherAccountsAccountType'"
                [list]="brokerageOtherAccountsAccountTypeList"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="getRequesterPath('brokerageOtherAccountsAccountType')"
            ></app-odc-field>
        </div>

    </div>

    <!--userInitieIndicator-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'BROKERAGE_USER_REPORTING_INSIDER_TITLE' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'userInitieIndicator'"
        [name]="'userInitieIndicator'"
        [path]="'userInitieIndicator'"
        [binding]="getRequesterPath('userInitieIndicator')"
        [templateRefFr]="helpUserInitieFr"
        [templateRefEn]="helpUserInitieEn"
    ></app-odc-field>

    <div *ngIf="form.get('userInitieIndicator').value" formGroupName="userInitie">
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('userInitie')"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_COMPAGNY_NAME' | translate"
            [id]="'userInitieCompanyName'"
            [name]="'userInitieCompanyName'"
            [path]="'userInitieCompanyName'"
            [binding]="getRequesterPath('userInitieCompanyName')"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('userInitie')"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_COMPANY_SYMBOL' | translate"
            [id]="'userInitieCompanySymbol'"
            [name]="'userInitieCompanySymbol'"
            [path]="'userInitieCompanySymbol'"
            [binding]="getRequesterPath('userInitieCompanySymbol')"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form.get('userInitie')"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_MARKET' | translate"
            [choices]="[{label: 'BROKERAGE_MARKET_CAN' | translate, value: constants.MARKET_CAN}, {label: 'BROKERAGE_MARKET_US' | translate, value: constants.MARKET_US}]"
            [id]="'userInitieMarket'"
            [name]="'userInitieMarket'"
            [path]="'userInitieMarket'"
            [binding]="getRequesterPath('userInitieMarket')"
        ></app-odc-field>

    </div>


    <!--userMainShareholderIndicator-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'BROKERAGE_MAIN_SHAREHOLDER_TITLE' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'userMainShareholderIndicator'"
        [name]="'userMainShareholderIndicator'"
        [path]="'userMainShareholderIndicator'"
        [binding]="getRequesterPath('userMainShareholderIndicator')"
        [templateRefFr]="helpMainShareholderFr"
        [templateRefEn]="helpMainShareholderEn"
    ></app-odc-field>

    <div *ngIf="form.get('userMainShareholderIndicator').value" formGroupName="userMainShareholder">
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('userMainShareholder')"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_COMPAGNY_NAME' | translate"
            [id]="'userMainShareholderCompanyName'"
            [name]="'userMainShareholderCompanyName'"
            [path]="'userMainShareholderCompanyName'"
            [binding]="getRequesterPath('userMainShareholderCompanyName')"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('userMainShareholder')"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_COMPANY_SYMBOL' | translate"
            [id]="'userMainShareholderCompanySymbol'"
            [name]="'userMainShareholderCompanySymbol'"
            [path]="'userMainShareholderCompanySymbol'"
            [binding]="getRequesterPath('userMainShareholderCompanySymbol')"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form.get('userMainShareholder')"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_MARKET' | translate"
            [choices]="[{label: 'BROKERAGE_MARKET_CAN' | translate, value: constants.MARKET_CAN}, {label: 'BROKERAGE_MARKET_US' | translate, value: constants.MARKET_US}]"
            [id]="'userMainShareholderMarket'"
            [name]="'userMainShareholderMarket'"
            [path]="'userMainShareholderMarket'"
            [binding]="getRequesterPath('userMainShareholderMarket')"
        ></app-odc-field>

    </div>


    <!--userPoliticallyExposedForeignPersonIndicator-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'BROKERAGE_EPV_NPV_TITLE' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'userPoliticallyExposedForeignPersonIndicator'"
        [name]="'userPoliticallyExposedForeignPersonIndicator'"
        [path]="'userPoliticallyExposedForeignPersonIndicator'"
        [binding]="getRequesterPath('userPoliticallyExposedForeignPersonIndicator')"
        [templateRefFr]="helpPoliticalyExposedFr"
        [templateRefEn]="helpPoliticalyExposedEn"
    ></app-odc-field>

    <!--userFinancialInterestIndicator-->
    <app-odc-field
        *ngIf="isFirstAccountHolder()"
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'BROKERAGE_FINANCIAL_INTERESET_TITLE' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'userFinancialInterestIndicator'"
        [name]="'userFinancialInterestIndicator'"
        [path]="'userFinancialInterestIndicator'"
        [binding]="getRequesterPath('userFinancialInterestIndicator')"
    ></app-odc-field>

    <div id="panelDefault">
        <div *ngIf="isPleinEx() && !isEnfRefOrEenfEsQualites()">
            <!--userOtherGuarantingPersonIndicator-->
            <app-odc-field
                *ngIf="showUserOtherGuarantingPersonIndicator()"
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_LEGAL_USER_OTHER_GUARANTING_PERSON_TITLE' | translate"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="'userOtherGuarantingPersonIndicator'"
                [name]="'userOtherGuarantingPersonIndicator'"
                [path]="'userOtherGuarantingPersonIndicator'"
                [binding]="getRequesterPath('userOtherGuarantingPersonIndicator')"
            ></app-odc-field>
        </div>
    </div>

    <div *ngIf="!isOtherExceptEnfRef_EenfEsQualites_EsQualitesMandate()" id="panelDefault">
        <!--trustedperson-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_TRUSTED_PERSON' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'userTrustedPersonIndicator'"
            [name]="'userTrustedPersonIndicator'"
            [path]="'userTrustedPersonIndicator'"
            [binding]="getRequesterPath('userTrustedPersonIndicator')"
        ></app-odc-field>
    </div>

    <div id="panelDefault">
        <div *ngIf="!isPleinEx()">
            <!--brokeragePurpose-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'BROKERAGE_PURPOSE' | translate"
                [id]="'brokeragePurpose'"
                [name]="'brokeragePurpose'"
                [path]="'brokeragePurpose'"
                [list]="brokeragePurposeList"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="getRequesterPath('brokeragePurpose')"
                [className]="''"
            ></app-odc-field>
            <div *ngIf="brokeragePurposeIsOther()">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form"
                    [submitted]="isFormSubmitted()"
                    [label]="'BROKERAGE_PURPOSE_OTHER' | translate"
                    [id]="'brokeragePurposeOther'"
                    [name]="'brokeragePurposeOther'"
                    [path]="'brokeragePurposeOther'"
                    [binding]="getRequesterPath('brokeragePurposeOther')"
                    [attr]="{maxlength: 32}"
                    [className]="''"
                ></app-odc-field>
            </div>
        </div>

        <!--userBrokerageKnowledge-->
        <div *ngIf="isFirstAccountHolder()">
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'BROKERAGE_KNOWLEDGE' | translate"
                [id]="'userBrokerageKnowledge'"
                [name]="'userBrokerageKnowledge'"
                [path]="'userBrokerageKnowledge'"
                [list]="userBrokerageKnowledgeList"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="getRequesterPath('userBrokerageKnowledge')"
                [className]="''"
            ></app-odc-field>
        </div>
    </div>

    <div *ngIf="isPleinEx() && isFirstAccountHolder() && !isUpdateAccount()">

        <h2 *ngIf="!isGpdOrSfd()" class="clearfix">
            <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
            {{'BROKERAGE_CREDIT_BALANCE_TITLE' | translate}}
        </h2>

        <h2 *ngIf="isGpdOrSfd()" class="clearfix">
            <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
            {{'DIRECT_DEPOSIT_TO_ACCOUNT' | translate}}
        </h2>

        <!--userBrokerageCreditBalanceList-->
        <app-odc-field
            *ngIf="!isGpdOrSfd()"
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getBrokerageCreditBalanceLabel() | translate"
            [id]="'userBrokerageCreditBalanceList'"
            [name]="'userBrokerageCreditBalanceList'"
            [path]="'userBrokerageCreditBalanceList'"
            [list]="userBokerageCreditBalanceList"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [binding]="getRequesterPath('userBrokerageCreditBalanceList')"
        ></app-odc-field>

        <!--userBrokerageDirectDeposit-->
        <app-odc-field
            *ngIf="!isGpdOrSfd()"
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'BROKERAGE_DIRECT_DEPOSIT_AUTH' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'DIRECT_DEPOSIT_YES' | translate, value: true}]"
            [id]="'userBrokerageDirectDeposit'"
            [name]="'userBrokerageDirectDeposit'"
            [path]="'userBrokerageDirectDeposit'"
            [binding]="getRequesterPath('userBrokerageDirectDeposit')"
        ></app-odc-field>

        <!--userBrokerageDirectDepositGPD-->
        <app-odc-field
            *ngIf="isGpdOrSfd()"
            [type]="constants.FIELD_TYPES.CHECKBOX"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'DIRECT_DEPOSIT_DIRECT_WITHDRAWAL' | translate"
            [choices]="[
                {label: 'DIRECT_DEPOSIT' | translate, id: 'userBrokerageDirectDeposit', binding: getRequesterPath('userBrokerageDirectDeposit')},
                {label: 'DIRECT_WITHDRAWAL' | translate, id: 'userBrokerageWithdrawal', binding: getRequesterPath('userBrokerageWithdrawal')}]"

            [id]="'userBrokerageWithdrawal'"
            [name]="'userBrokerageWithdrawal'"
            [path]="'userBrokerageWithdrawal'"
            [stacked]="true"
        ></app-odc-field>

    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpUserInitieFr>
    <app-help-insider></app-help-insider>
</ng-template>
<ng-template #helpUserInitieEn>
    <app-help-insider></app-help-insider>
</ng-template>

<ng-template #helpMainShareholderFr>
    <h2>Actionnaire important</h2>
    <p>{{'ATTORNEY_MAINSHAREHOLDER_TOOLTIP'|translate}}</p>
</ng-template>
<ng-template #helpMainShareholderEn>
    <h2>Significant Shareholder</h2>
    <p>{{'ATTORNEY_MAINSHAREHOLDER_TOOLTIP'|translate}}</p>
</ng-template>
<ng-template #helpPoliticalyExposedFr>
    <h2>Définition d’un EPV</h2>
    <p>Un Étranger politiquement vulnérable (EPV) se définit comme un individu qui a occupé ou qui occupe l’une des
        charges suivantes au sein d’un <strong>pays étranger</strong> ou pour le compte de celui-ci : </p>

    <ul>
        <li>chef d'État ou de gouvernement;</li>
        <li>membre du conseil exécutif de gouvernement ou membre d'une assemblée législative;</li>
        <li>sous-ministre ou titulaire d'une charge de rang équivalent;</li>
        <li>ambassadeur, ou attaché ou conseiller d'un ambassadeur;</li>
        <li>officier ayant le rang de général ou un rang supérieur;</li>
        <li>président d’une société ou d’une banque d’État;</li>
        <li>chef d'un organisme gouvernemental;</li>
        <li>juge de la coup suprême, de la cour constitutionnelle ou d'une autre couche de dernier ressort;</li>
        <li>leader ou président d’un parti politique représenté au sein d’une assemblée législative.</li>
    </ul>

    <p>Est également considéré Étranger politiquement vulnérable (EPV) <strong>tout membre de la famille</strong> d’une
        personne
        susmentionnée. Dans ce contexte, un membre de la famille signifie les personnes suivantes : </p>

    <ul>
        <li>sa mère ou son père;</li>
        <li>son enfant;</li>
        <li>son époux ou conjoint de fait;</li>
        <li>son ex-époux ou ex-conjoint de fait;</li>
        <li>la mère ou le père de son époux ou de son conjoint de fait;</li>
        <li>son frère ou sa soeur.</li>
    </ul>

    <h2>Définition d’un NPV</h2>
    <p>Un National politiquement vulnérable (NPV) est une personne qui occupe (ou a occupé au cours des cinq dernières
        années) l’une des fonctions suivantes au sein du <strong>gouvernement fédéral canadien, d’un gouvernement
            provincial
            canadien ou d’une administration municipale canadienne,</strong> ou pour le compte d’une de ces dernières :
    </p>

    <ul>
        <li>gouverneur général, lieutenant-gouverneur ou chef de gouvernement;</li>
        <li>membre du Sénat ou de la Chambre des communes ou membre d’une assemblée législative;</li>
        <li>sous-ministre ou titulaire d'une charge de rang équivalent;</li>
        <li>ambassadeur, ou attaché ou conseiller d'un ambassadeur;</li>
        <li>officier ayant le rang de général ou un rang supérieur;</li>
        <li>dirigeant d’une société appartenant directement à cent pour cent à Sa Majesté du chef du Canada ou d’une
            province;
        </li>
        <li>chef d'un organisme gouvernemental;</li>
        <li>juge d’une cour d’appel provinciale, de la Cour d’appel fédérale ou de la Cour suprême du Canada;</li>
        <li>chef ou président d’un parti politique représenté au sein d’une assemblée législative;</li>
        <li>maire.</li>
    </ul>

    <p>Est également considéré National politiquement vulnérable (NPV) <strong>tout membre de la famille</strong> d’une
        personne
        susmentionnée. Dans ce contexte, un membre de la famille signifie les personnes suivantes : </p>

    <ul>
        <li>sa mère ou son père;</li>
        <li>son enfant;</li>
        <li>son époux ou conjoint de fait;</li>
        <li>son ex-époux ou ex-conjoint de fait;</li>
        <li>la mère ou le père de son époux ou de son conjoint de fait;</li>
        <li>son frère ou sa soeur.</li>
    </ul>

    <h2>Définition d’un DOI</h2>
    <p>Un Dirigeant d’organisation internationale est une personne qui occupe (ou a occupé au cours des cinq dernières
        années) l’une des fonctions suivantes : </p>

    <ul>
        <li>dirigeant d’une organisation internationale mise sur pied par les gouvernements de différents États;</li>
        <li>dirigeant d'une institution créée par une organisation internationale.</li>
    </ul>

    <p>On entend par dirigeant d’une organisation internationale ou d’une institution créée par une <strong>organisation
        internationale</strong> la principale personne dirigeant cette organisation (exemple : son président ou son
        président-
        directeur général).</p>

    <h2>Définition d’une « Personne étroitement associée » à un EPV / NPV ou à un DOI</h2>

    <p>Une Personne étroitement associée peut être une personne ayant des liens étroits avec un EPV / NPV ou un DOI
        pour des raisons personnelles ou professionnelles. Le terme « Personne étroitement associée » ne couvre pas
        l’ensemble des personnes qui sont associées à un EPV / NPV ou à un DOI.</p>

    <p>Voici quelques exemples de Personnes étroitement associées à un EPV / NPV ou à un DOI : </p>

    <ul>
        <li>un partenaire d’affaire d’un EPV / NPV ou d’un DOI, ou une personne qui détient, directement ou
            indirectement,
            une entreprise conjointement avec un EPV / NPV ou un DOI;
        </li>
        <li>une personne engagée dans une relation romantique avec un EPV / NPV ou un DOI (amoureux ou amoureuse, amant
            ou amante);
        </li>
        <li>une personne effectuant des opérations financières avec un EPV / NPV ou un DOI;</li>
        <li>un membre important du même parti politique ou du même syndicat qu’un EPV / NPV ou un DOI;</li>
        <li>une personne siégeant au même conseil d’administration qu’un EPV / NPV ou un DOI;</li>
        <li>une personne participant à des oeuvres caritatives en relation étroite avec un EPV / NPV ou un DOI;</li>
        <li>une personne qui est co-titulaire d’une police d’assurance-vie dont l’un des titulaires est un EPV / NPV ou
            un DOI.
        </li>
    </ul>

    <p>La liste qui précède est fournie à titre d’exemple de relations étroites seulement, pour vous aider à déterminer
        le type
        de relation dont il s’agit. Comme l’ensemble des personnes qui sont liées à un EPV / NPV ou à un DOI ne tombent
        pas sous la définition de « Personne étroitement associée », le Bureau de la sécurité Desjardins devra
        déterminer
        quels types de relations sont visées pour prendre les mesures nécessaires.</p>

</ng-template>

<ng-template #helpPoliticalyExposedEn>
    <h2>Definition of a foreign PEP</h2>
    <p>A foreign politically exposed person (foreign PEP) is a person who holds or has held one of the following offices
        in or
        on behalf of a <strong>foreign state:</strong></p>

    <ul>
        <li>Head of state or head of government;</li>
        <li>Member of the executive council of government or member of a legislature;</li>
        <li>Deputy minister or equivalent rank</li>
        <li>Ambassador, or attaché or counsellor of an ambassador;</li>
        <li>Military officer with a rank of general or above;</li>
        <li>President of a state-owned company or a state-owned bank;</li>
        <li>Head of a government agency</li>
        <li>Judge of a supreme court, constitutional court or other court of last resort;</li>
        <li>Leader or president of a political party represented in a legislature.</li>
    </ul>

    <p><strong>Any family member</strong> of the above-mentioned person is also considered to be a foreign politically
        exposed person. In
        this context, a family member means any of the following individuals with respect to the person in question:</p>

    <ul>
        <li>Mother or father;</li>
        <li>Child;</li>
        <li>Spouse or common-law spouse;</li>
        <li>Former spouse or common-law spouse;</li>
        <li>Mother or father of spouse or common-law spouse;</li>
        <li>Brother or sister.</li>
    </ul>

    <h2>Definition of a domestic PEP</h2>
    <p>A domestic politically exposed person (domestic PEP) is a person who holds or has held within the last five years
        one of the following offices or positions in or on behalf of the <strong>Canadian federal government or the
            government of
            any province or municipality in Canada:</strong></p>

    <ul>
        <li>Governor General, lieutenant governor or head of government;</li>
        <li>Member of the Senate or House of Commons or member of a legislature;</li>
        <li>Deputy minister or equivalent rank;</li>
        <li>Ambassador, or attaché or counsellor of an ambassador;</li>
        <li>Military officer with a rank of general or above;</li>
        <li>President of a corporation that is wholly owned directly by Her Majesty in right of Canada or a province;
        </li>
        <li>Head of a government agency;</li>
        <li>Judge of an appellate court in a province, the Federal Court of Appeal or the Supreme Court of Canada;</li>
        <li>Leader or president of a political party represented in a legislature;</li>
        <li>Mayor.</li>
    </ul>

    <p><strong>Any family member</strong> of the above-mentioned person is also considered to be a domestic politically
        exposed person.
        In this context, a family member means any of the following individuals with respect to the person in question:
    </p>

    <ul>
        <li>Mother or father;</li>
        <li>Child;</li>
        <li>Spouse or common-law spouse;</li>
        <li>Former spouse or common-law spouse;</li>
        <li>Mother or father of spouse or common-law spouse;</li>
        <li>Brother or sister.</li>
    </ul>

    <h2>Definition of a HIO</h2>
    <p>The head of an international organization is a person who is either (or has been within the last five
        years): </p>

    <ul>
        <li>The head of an international organization established by the governments of states; or</li>
        <li>The head of an institution established by an international organization.</li>
    </ul>

    <p>When we refer to the head of an international organization or the head of an institution established by an
        <strong>international organization</strong>, we are referring to the primary person who leads that organization,
        such as the
        president or CEO.</p>

    <h2>Definition of a close associate of a PEP or a HIO</h2>

    <p>A close associate can be an individual who is closely connected to a foreign or domestic PEP or HIO for personal
        or
        business reasons. The term “close associate” does not cover every person who has been associated with a PEP or
        HIO.</p>

    <p>Examples of close associates of a foreign or domestic PEP or a HIO include persons who are: </p>

    <ul>
        <li>Business partners with, or who beneficially own or control a business with, a PEP or HIO;</li>
        <li>In a romantic relationship with a PEP or HIO, such as a boyfriend, girlfriend or mistress;</li>
        <li>Involved in financial transactions with a PEP or HIO;</li>
        <li>A prominent member of the same political party or union as a PEP or HIO;</li>
        <li>Serving as a member of the same board as a PEP or HIO;</li>
        <li>Closely carrying out charitable works with a PEP or HIO;</li>
        <li>Listed as a co-holder of a life insurance policy where one of the holders is a PEP or HIO.</li>
    </ul>

    <p>The examples provided are only a sample of considerations to help identify close associates. Because not all
        persons connected to a foreign or domestic PEP or HIO meet the definition of “close associate,” the Desjardins
        Group Security Office will have to determine if this is a close association you need to identify and treat as
        such.</p>
</ng-template>

