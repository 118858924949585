import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {VmdConstants} from '../../components/constants/vmd-constants';

@Component({
    selector: 'app-legal',
    templateUrl: './brokerage-accounts.component.html'
})
export class BrokerageAccountsComponent extends BaseNavComponent implements OnInit {

    brokerageOtherAccountsAccountTypeList: HtmlSelectKV[];
    brokeragePurposeList: HtmlSelectKV[];
    userBrokerageKnowledgeList: HtmlSelectKV[];
    userBokerageCreditBalanceList: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.form = this.fb.group({
            brokerageOtherAccountsIndicator: [this.formService.getForm().requesters[this.requesterIndex].brokerageOtherAccountsIndicator, [this.requiredValidator('brokerageOtherAccountsIndicator')]],
            brokerageOtherAccounts: this.fb.group({
                brokerageOtherAccountsFirmName: [this.formService.getForm().requesters[this.requesterIndex].brokerageOtherAccountsFirmName, [this.requiredValidator('brokerageOtherAccountsFirmName')]],
                brokerageOtherAccountsAccountType: [this.formService.getForm().requesters[this.requesterIndex].brokerageOtherAccountsAccountType, [this.requiredValidator('brokerageOtherAccountsAccountType')]]
            }),
            userInitieIndicator: [this.formService.getForm().requesters[this.requesterIndex].userInitieIndicator, [this.requiredValidator('userInitieIndicator')]],
            userInitie: this.fb.group({
                userInitieCompanyName: [this.formService.getForm().requesters[this.requesterIndex].userInitieCompanyName, [this.requiredValidator('userInitieCompanyName')]],
                userInitieCompanySymbol: [this.formService.getForm().requesters[this.requesterIndex].userInitieCompanySymbol, [this.requiredValidator('userInitieCompanySymbol')]],
                userInitieMarket: [this.formService.getForm().requesters[this.requesterIndex].userInitieMarket, [this.requiredValidator('userInitieMarket')]]
            }),
            userMainShareholderIndicator: [this.formService.getForm().requesters[this.requesterIndex].userMainShareholderIndicator, [this.requiredValidator('userMainShareholderIndicator')]],
            userMainShareholder: this.fb.group({
                userMainShareholderCompanyName: [this.formService.getForm().requesters[this.requesterIndex].userMainShareholderCompanyName, [this.requiredValidator('userMainShareholderCompanyName')]],
                userMainShareholderCompanySymbol: [this.formService.getForm().requesters[this.requesterIndex].userMainShareholderCompanySymbol, [this.requiredValidator('userMainShareholderCompanySymbol')]],
                userMainShareholderMarket: [this.formService.getForm().requesters[this.requesterIndex].userMainShareholderMarket, [this.requiredValidator('userMainShareholderMarket')]]
            }),
            userPoliticallyExposedForeignPersonIndicator: [this.formService.getForm().requesters[this.requesterIndex].userPoliticallyExposedForeignPersonIndicator, [this.requiredValidator('userPoliticallyExposedForeignPersonIndicator')]],
            userFinancialInterestIndicator: [this.formService.getForm().requesters[this.requesterIndex].userFinancialInterestIndicator, [
                this.requesterIndex === 0 ? this.requiredValidator('userFinancialInterestIndicator') : Validators.nullValidator]
            ],
            userTrustedPersonIndicator: [this.formService.getForm().requesters[this.requesterIndex].userTrustedPersonIndicator, [this.requiredValidator('userTrustedPersonIndicator')]],
            brokeragePurpose: [this.formService.getForm().requesters[this.requesterIndex].brokeragePurpose, [this.requiredValidator('brokeragePurpose')]],
            brokeragePurposeOther: [this.formService.getForm().requesters[this.requesterIndex].brokeragePurposeOther, [this.requiredValidator('brokeragePurposeOther')]],
            userBrokerageKnowledge: [this.formService.getForm().requesters[this.requesterIndex].userBrokerageKnowledge, [this.requiredValidator('userBrokerageKnowledge')]],
            userOtherGuarantingPersonIndicator: [{
                value: this.formService.getForm().requesters[0].userOtherGuarantingPersonIndicator,
                disabled: this.isRouteOtherAccountGPD()
            }, [this.requiredValidator('userOtherGuarantingPersonIndicator')]],
            userBrokerageCreditBalanceList: [{
                value: this.formService.getForm().requesters[this.requesterIndex].userBrokerageCreditBalanceList,
                disabled: this.isGpdOrSfd()
            }, [this.isRequiredWithoutUpdateValidator('userBrokerageCreditBalanceList')]],
            userBrokerageDirectDeposit: [
                this.formService.getForm().requesters[this.requesterIndex].userBrokerageDirectDeposit,
                [
                    this.isRequiredWithoutUpdateGpdValidator('userBrokerageDirectDeposit')
                ]
            ],
            userBrokerageWithdrawal: [this.formService.getForm().requesters[this.requesterIndex].userBrokerageWithdrawal]
        });

        this.listModelService.getListModel('brokerageAccountList', data => this.brokerageOtherAccountsAccountTypeList = data);
        this.listModelService.getListModel('brokeragePurposeList', data => this.brokeragePurposeList = data);
        this.listModelService.getListModel('brokerageCreditBalanceList', data => this.userBokerageCreditBalanceList = data);
        const brokerageKnowledgeServiceName = this.isPleinEx() ? 'brokerageKnowledgePleinExList' : 'brokerageKnowledgeList';
        this.listModelService.getListModel(brokerageKnowledgeServiceName, data => this.userBrokerageKnowledgeList = data);

        this.form.get('brokerageOtherAccountsIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('brokerageOtherAccounts'), value);
        });
        this.updateControlEnabled(this.form.get('brokerageOtherAccounts'), this.form.get('brokerageOtherAccountsIndicator').value);

        this.updateControlEnabled(this.form.get('userBrokerageKnowledge'), this.requesterIndex === 0);
        this.updateControlEnabled(this.form.get('userBrokerageDirectDeposit'), this.requesterIndex === 0);

        this.updateControlEnabled(this.form.get('userBrokerageWithdrawal'), this.requesterIndex === 0);

        this.form.get('userInitieIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('userInitie'), value);
        });
        this.updateControlEnabled(this.form.get('userInitie'), this.form.get('userInitieIndicator').value);

        this.form.get('userMainShareholderIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('userMainShareholder'), value);
        });
        this.updateControlEnabled(this.form.get('userMainShareholder'), this.form.get('userMainShareholderIndicator').value);

        this.form.get('brokeragePurpose').valueChanges.subscribe(() => {
            this.updateControlEnabled(this.form.get('brokeragePurposeOther'), this.brokeragePurposeIsOther());
        });
        this.updateControlEnabled(this.form.get('brokeragePurposeOther'), this.brokeragePurposeIsOther());
        this.updateControlEnabled(this.form.get('userTrustedPersonIndicator'), !this.isOtherExceptEnfRef_EenfEsQualites_EsQualitesMandate());
    }

    brokeragePurposeIsOther() {

        return this.form.get('brokeragePurpose').value === this.constants.BROKERAGE_PURPOSE_OTHER;
    }

    isFirstAccountHolder(): boolean {
        return this.requesterIndex === 0;
    }

    isEnfRefOrEenfEsQualites(): boolean {
        return this.isPleinEx() &&
            (this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_REF ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_ES_QUALITES);
    }

    isOtherExceptEnfRef_EenfEsQualites_EsQualitesMandate(): boolean {
        return this.isPleinEx() &&
            (this.formService.getForm().cltType === this.constants.CLT_TYPE_COOPERATIVE ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_CORPORATION ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_CLUB ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_CURATELLE ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_CO_UNIQUE ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_FIDUCIE ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_ORG_GOUV ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_OSBL ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_SENC ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_SOC_COMM ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_SUCC ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_TUTELLE ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_RRI ||
                this.formService.getForm().cltType === this.constants.CLT_TYPE_ASSOCIATION);
    }

    showMention(): boolean {
        const cltType = this.formService.getClientType();
        return this.isPleinEx() && (cltType === VmdConstants.CLT_TYPE_ASSOCIATION ||
            cltType === VmdConstants.CLT_TYPE_CLUB ||
            cltType === VmdConstants.CLT_TYPE_CORPORATION ||
            cltType === VmdConstants.CLT_TYPE_COOPERATIVE ||
            cltType === VmdConstants.CLT_TYPE_SENC ||
            cltType === VmdConstants.CLT_TYPE_OSBL ||
            cltType === VmdConstants.CLT_TYPE_SOC_COMM ||
            cltType === VmdConstants.CLT_TYPE_ORG_GOUV);
    }

    // OCE-7566
    getBrokerageCreditBalanceLabel(): string {
        if (VmdConstants.MODES.AUTOMATED === this.formService.getMode()) {
            return 'BROKERAGE_CREDIT_BALANCE';
        } else {
            return 'BROKERAGE_CREDIT_BALANCE_OPTIONAL';
        }
    }

    isRequiredWithoutUpdateGpdValidator(fieldName: string): ValidatorFn | null {
        if (!this.isUpdateAccount() && !this.isGpdOrSfd() && this.isFirstAccountHolder()) {
            return this.requiredValidator(fieldName);
        }

        return Validators.nullValidator;
    }

    isRequiredWithoutUpdateValidator(fieldName: string): ValidatorFn | null {
        if (!this.isUpdateAccount() && this.isFirstAccountHolder()) {
            return this.requiredValidator(fieldName);
        }

        return Validators.nullValidator;
    }

    showUserOtherGuarantingPersonIndicator(): boolean {

        return !this.isRouteOtherAccountGPD() && this.isFirstAccountHolder();
    }

    showBrokeragePurpose(): boolean {

        return !this.isPleinEx() || this.isFirstAccountHolder();
    }
}
