<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
        {{'OCCUPATION_TITLE' | translate}}

        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div *ngIf="showMention()">
        {{ 'EMPLOYER_QUESTIONS_NATURAL_PERSONS_HOLDING_25_PERCENT' | translate }}
        <br/><br/>
    </div>

    <!--userJobStatus--------------------------------->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'EMPLOYMENT_STATUS_LABEL' | translate"
        [id]="'userJobStatus'"
        [name]="'userJobStatus'"
        [path]="'userJobStatus'"
        [list]="jobStatusList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userJobStatus')"
        [readonlyProperty]="'userJobStatus'"
    ></app-odc-field>

    <div *ngIf="userIsPaidEmployeeOrSelfEmployed()" formGroupName="employer">
        <!--userEmployerIsBroker--------------------------------->
        <div id="panelDefault">
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form.get('employer')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_EMPLOYER_IS_BROKER_LABEL' | translate"
                [id]="'userEmployerIsBroker'"
                [name]="'userEmployerIsBroker'"
                [path]="'userEmployerIsBroker'"
                [list]="isBrokerList"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="getRequesterPath('userEmployerIsBroker')"
                [helpText]="getUserEmployerIsBrokerHelp()"
                [className]="''"
            ></app-odc-field>
        </div>

        <div *ngIf="isPleinEx() && userEmployerIsBrokerVmd()">
            <!--userIsInvestmentAdvisorIndicator-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('employer')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_IS_INVESTMENT_ADVISOR' | translate"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="'userIsInvestmentAdvisorIndicator'"
                [name]="'userIsInvestmentAdvisorIndicator'"
                [path]="'userIsInvestmentAdvisorIndicator'"
                [binding]="getRequesterPath('userIsInvestmentAdvisorIndicator')"
            ></app-odc-field>
        </div>

        <!--userEmployerName--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('employer')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_EMPLOYER_NAME' | translate"
            [id]="'userEmployerName'"
            [name]="'userEmployerName'"
            [path]="'userEmployerName'"
            [binding]="getRequesterPath('userEmployerName')"
            [attr]="{maxlength: 32}"
            [readonlyProperty]="'userEmployerName'"
        ></app-odc-field>

        <!--userJobTitle--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('employer')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_JOB_TITLE' | translate"
            [id]="'userJobTitle'"
            [name]="'userJobTitle'"
            [path]="'userJobTitle'"
            [binding]="getRequesterPath('userJobTitle')"
            [attr]="{maxlength: 32}"
            [readonlyProperty]="'userJobTitle'"
        ></app-odc-field>

        <!--userEmployerActivity--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form.get('employer')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_EMPLOYER_ACTIVITY' | translate"
            [id]="'userEmployerActivity'"
            [name]="'userEmployerActivity'"
            [path]="'userEmployerActivity'"
            [list]="employerActivityList"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [binding]="getRequesterPath('userEmployerActivity')"
            [readonlyProperty]="'userEmployerActivity'"
        ></app-odc-field>
    </div>

    <div id="panelDefaultEmployeeOrSelfEmployed">
        <!--userSameAddressWithBrokerEmployeeIndicator-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'userSameAddressWithBrokerEmployeeIndicator'"
            [name]="'userSameAddressWithBrokerEmployeeIndicator'"
            [path]="'userSameAddressWithBrokerEmployeeIndicator'"
            [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeIndicator')"
        ></app-odc-field>

        <div *ngIf="this.form.get('userSameAddressWithBrokerEmployeeIndicator').value"
             formGroupName="userSameAddressWithBrokerEmployee">

            <!--userSameAddressWithBrokerEmployeeVmdIndicator-->
            <app-odc-field *ngIf="isPleinEx()"
                           [type]="constants.FIELD_TYPES.RADIO"
                           [form]="form.get('userSameAddressWithBrokerEmployee')"
                           [submitted]="isFormSubmitted()"
                           [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_VMD' | translate"
                           [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                           [id]="'userSameAddressWithBrokerEmployeeVmdIndicator'"
                           [name]="'userSameAddressWithBrokerEmployeeVmdIndicator'"
                           [path]="'userSameAddressWithBrokerEmployeeVmdIndicator'"
                           [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeVmdIndicator')"
            ></app-odc-field>

            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form.get('userSameAddressWithBrokerEmployee')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_FIRM_NAME' | translate"
                [id]="'userSameAddressWithBrokerEmployeeFirmName'"
                [name]="'userSameAddressWithBrokerEmployeeFirmName'"
                [path]="'userSameAddressWithBrokerEmployeeFirmName'"
                [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeFirmName')"
                [attr]="{maxlength: 32}"
                [className]="''"
            ></app-odc-field>

            <div class="row">
                <div class="col-md-12">
                    <!--userSameAddressWithBrokerEmployeeFirstName--------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('userSameAddressWithBrokerEmployee')"
                        [submitted]="isFormSubmitted()"
                        [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_FIRSTNAME' | translate"
                        [id]="'userSameAddressWithBrokerEmployeeFirstName'"
                        [name]="'userSameAddressWithBrokerEmployeeFirstName'"
                        [path]="'userSameAddressWithBrokerEmployeeFirstName'"
                        [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeFirstName')"
                        [attr]="{maxlength: 32}"
                        [className]="''"
                    ></app-odc-field>
                </div>

                <div class="col-md-12">
                    <!--userSameAddressWithBrokerEmployeeLastName--------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('userSameAddressWithBrokerEmployee')"
                        [submitted]="isFormSubmitted()"
                        [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LASTNAME' | translate"
                        [id]="'userSameAddressWithBrokerEmployeeLastName'"
                        [name]="'userSameAddressWithBrokerEmployeeLastName'"
                        [path]="'userSameAddressWithBrokerEmployeeLastName'"
                        [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeLastName')"
                        [attr]="{maxlength: 32}"
                        [className]="''"
                    ></app-odc-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <!--userSameAddressWithBrokerEmployeeLink--------------------------------->
                    <app-odc-field *ngIf="!isPleinEx()"
                                   [type]="constants.FIELD_TYPES.TEXT"
                                   [form]="form.get('userSameAddressWithBrokerEmployee')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LINK' | translate"
                                   [id]="'userSameAddressWithBrokerEmployeeLink'"
                                   [name]="'userSameAddressWithBrokerEmployeeLink'"
                                   [path]="'userSameAddressWithBrokerEmployeeLink'"
                                   [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeLink')"
                                   [attr]="{maxlength: 32}"
                                   [className]="''"
                    ></app-odc-field>

                    <!--userSameAddressWithBrokerEmployeeLinkList--------------------------------->
                    <app-odc-field *ngIf="isPleinEx()"
                                   [type]="constants.FIELD_TYPES.SELECT"
                                   [form]="form.get('userSameAddressWithBrokerEmployee')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LINK' | translate"
                                   [id]="'userSameAddressWithBrokerEmployeeLinkList'"
                                   [name]="'userSameAddressWithBrokerEmployeeLinkList'"
                                   [path]="'userSameAddressWithBrokerEmployeeLinkList'"
                                   [list]="linkList"
                                   [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                                   [binding]="getRequesterPath('userSameAddressWithBrokerEmployeeLinkList')"
                                   [className]="''"
                    ></app-odc-field>
                </div>
            </div>
        </div>
    </div>

    <!--percentageOwner--------------------------------->
    <app-odc-field *ngIf="showPercentageOwnerFieldOnSpecificClientTypes()"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]=form
                   [submitted]="isFormSubmitted()"
                   [label]="'PERCENTAGE_OWNERSHIP_CLIENT' | translate"
                   [id]="'percentageOwner'"
                   [name]="'percentageOwner'"
                   [path]="'percentageOwner'"
                   [binding]="getRequesterPath('percentageOwner')"
                   [percentFormat]="true"
                   [attr]="{maxlength: 3}"
    ></app-odc-field>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpFr>
    <h2>Pourquoi dois-je fournir des renseignements sur mon emploi?</h2>
    <p>Les renseignements sur votre emploi nous permettent dans un premier temps de nous assurer de votre solvabilité. À
        l'aide de ces informations nous pouvons également vérifier si vous, ou une personne habitant sous votre toit,
        êtes employé dans le secteur des valeurs mobilières. Dans un tel cas, les opérations effectuées dans le compte
        devront être soumises à une réglementation particulière.</p>
</ng-template>
<ng-template #helpEn>
    <h2>Why must I provide information about my employment?</h2>
    <p>We need to collect information on a client's employment in order to determine their creditworthiness, if they are
        employed in the securities industry or if they are an insider. If this is the case, the transactions in the
        account shall be subject to additional regulations.</p>
</ng-template>
