<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat1 icon-circle-dollars"></span>
        {{ 'USER_FINANCE_INFO_TITLE' | translate }}
        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div *ngIf="showIncomes()" formGroupName="incomes">

        <!--userAnnualIncome--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('incomes')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_FINANCE_INFO_ANNUAL_REVENU' | translate"
            [id]="'userAnnualIncome'"
            [name]="'userAnnualIncome'"
            [path]="'userAnnualIncome'"
            [binding]="getRequesterPath('userAnnualIncome')"
            [templateRefFr]="helpUserAnnualIncome"
            [templateRefEn]="helpUserAnnualIncome"
            [currencyFormat]="true"
            [attr]="{maxlength: 9}"
            [className]="''"
        ></app-odc-field>

        <div *ngIf="!(isUpdateAccount() || isPilotMember()) && !isReadOnlyAndUpdateRequest">
            <!--userNetCapitalAsset--------------------------------->
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form.get('incomes')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_FINANCE_INFO_IMMO_ASSETS' | translate"
                [id]="'userNetCapitalAsset'"
                [name]="'userNetCapitalAsset'"
                [path]="'userNetCapitalAsset'"
                [binding]="getRequesterPath('userNetCapitalAsset')"
                [templateRefFr]="helpUserNetCapitalAsset"
                [templateRefEn]="helpUserNetCapitalAsset"
                [currencyFormat]="true"
                [attr]="{maxlength: 9}"
                [className]="''"
            ></app-odc-field>

            <!--userNetLiquidityAsset--------------------------------->
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form.get('incomes')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_FINANCE_INFO_LIQUID_ASSETS' | translate"
                [id]="'userNetLiquidityAsset'"
                [name]="'userNetLiquidityAsset'"
                [path]="'userNetLiquidityAsset'"
                [binding]="getRequesterPath('userNetLiquidityAsset')"
                [templateRefFr]="helpUserNetLiquidityAsset"
                [templateRefEn]="helpUserNetLiquidityAsset"
                [currencyFormat]="true"
                [attr]="{maxlength: 9}"
                [className]="''"
            ></app-odc-field>
        </div>

        <div *ngIf="isUpdateAccount() || isPilotMember() || isReadOnlyAndUpdateRequest">
            <!--userTotalNetWoth--------------------------------->
            <div *ngIf="showUserTotalNetWorthSection()">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('incomes')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_FINANCE_INFO_TOTAL_NET_WORTH' | translate"
                    [id]="'userTotalNetWorth'"
                    [name]="'userTotalNetWorth'"
                    [path]="'userTotalNetWorth'"
                    [attr]="{maxlength: 9}"
                    [attrDisabled]="true"
                    [currencyFormat]="true"
                ></app-odc-field>
            </div>

            <div [id]="'userTotalNetWorthError'" class="userTotalNetWorthError"
                 *ngIf="!showUserTotalNetWorthSection()">
                <label> <b>{{ 'USER_FINANCE_INFO_TOTAL_NET_WORTH' | translate }}</b> </label>
                <p>
                    <span class="fa fa-exclamation-triangle" style="color: #00884e"></span>
                    {{ 'USER_FINANCE_INFO_TOTAL_NET_WORTH_INVALID' | translate }}
                </p>
            </div>

            <div class="m-b-1">
                <ng-container
                    *ngIf="!showEditTotalNetWorth &&  !isMandatoryReDocumentation &&
                            showUserTotalNetWorthSection() && !isReadOnlyAndUpdateRequest">
                    <a href="#" class="lien-sans-soulignement" (click)="toggleEditTotalNetWorth(true)">
                        <span class="icon-circle-plus"></span>
                        {{ 'USER_FINANCE_INFO_EDIT_TOTAL_NET_WORTH' | translate }}
                    </a>
                </ng-container>
            </div>

            <div
                *ngIf="(showEditTotalNetWorth || isMandatoryReDocumentation || !showUserTotalNetWorthSection())
                         && !isReadOnlyAndUpdateRequest">
                <!--userNetCapitalAsset--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('incomes')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_FINANCE_INFO_IMMO_ASSETS_A' | translate"
                    [id]="'userNetCapitalAsset'"
                    [name]="'userNetCapitalAsset'"
                    [path]="'userNetCapitalAsset'"
                    [binding]="getRequesterPath('userNetCapitalAsset')"
                    [templateRefFr]="helpUserNetCapitalAsset"
                    [templateRefEn]="helpUserNetCapitalAsset"
                    [currencyFormat]="true"
                    [attr]="{maxlength: 9}"
                    [className]="''"
                ></app-odc-field>

                <!--userNetLiquidityAsset--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('incomes')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_FINANCE_INFO_LIQUID_ASSETS_B' | translate"
                    [id]="'userNetLiquidityAsset'"
                    [name]="'userNetLiquidityAsset'"
                    [path]="'userNetLiquidityAsset'"
                    [binding]="getRequesterPath('userNetLiquidityAsset')"
                    [templateRefFr]="helpUserNetLiquidityAsset"
                    [templateRefEn]="helpUserNetLiquidityAsset"
                    [currencyFormat]="true"
                    [attr]="{maxlength: 9}"
                    [className]="''"
                ></app-odc-field>

                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('incomes')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_FINANCE_INFO_NEW_TOTAL_NET_WORTH' | translate"
                    [id]="'userNewTotalNetWorth'"
                    [name]="'userNewTotalNetWorth'"
                    [path]="'userNewTotalNetWorth'"
                    [binding]="getRequesterPath('userNewTotalNetWorth')"
                    [attr]="{maxlength: 9}"
                    [attrDisabled]="true"
                    [currencyFormat]="true"
                ></app-odc-field>

                <div class="m-b-1">
                    <ng-container
                        *ngIf="showEditTotalNetWorth &&  !isMandatoryReDocumentation &&  showUserTotalNetWorthSection()">
                        <a href="#" class="lien-sans-soulignement" (click)="toggleEditTotalNetWorth(false)">
                            <span class="icon-close rouge"></span>
                            {{ 'USER_FINANCE_INFO_DELETE_TOTAL_NET_WORTH'|translate }}
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isPleinEx() && !(isUpdateAccount() || isPilotMember())">
        <!--userBankId--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'USER_FINANCE_INFO_INSTITUTION' | translate"
            [id]="'userBankId'"
            [name]="'userBankId'"
            [path]="'userBankId'"
            [list]="bankList"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [binding]="getRequesterPath('userBankId')"
        ></app-odc-field>

        <!--userBankBranchNumber & userBankAccountNumber------------------------------------------------>
        <div class="row">
            <div class="col-md-10">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_FINANCE_INFO_TRANSIT' | translate"
                    [id]="'userBankBranchNumber'"
                    [name]="'userBankBranchNumber'"
                    [path]="'userBankBranchNumber'"
                    [binding]="getRequesterPath('userBankBranchNumber')"
                    [numericFormat]="true"
                    [attr]="{maxlength: 5}"
                ></app-odc-field>
            </div>

            <div class="col-md-14">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_FINANCE_INFO_ACCOUNT' | translate"
                    [id]="'userBankAccountNumber'"
                    [name]="'userBankAccountNumber'"
                    [path]="'userBankAccountNumber'"
                    [binding]="getRequesterPath('userBankAccountNumber')"
                    [numericFormat]="true"
                    [attr]="{maxlength: 12}"
                ></app-odc-field>
            </div>

        </div>

        <!--userBankPhone--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'USER_FINANCE_INFO_PHONE' | translate"
            [id]="'userBankPhone'"
            [name]="'userBankPhone'"
            [path]="'userBankPhone'"
            [binding]="getRequesterPath('userBankPhone')"
            [phoneFormat]="true"
            [attr]="{maxlength: 12}"
        ></app-odc-field>
    </div>

    <!-- PleinEx Only - Borrowing for investment purpose -->
    <div *ngIf="isPleinEx()">
        <h2 class="clearfix">
            {{ 'USER_BORROWING_FOR_INVESTMENT_PURPOSE_TITLE' | translate }}
        </h2>

        <div id="panelDefaultInvestimentPurpose">
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'USER_BORROWING_FOR_INVESTMENT_PURPOSE_QUESTION' | translate"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="'borrowedForInvestmentPurposeIndicator'"
                [name]="'borrowedForInvestmentPurposeIndicator'"
                [path]="'borrowedForInvestmentPurposeIndicator'"
                [binding]="'borrowedForInvestmentPurposeIndicator'"
            ></app-odc-field>
            <div *ngIf="form.get('borrowedForInvestmentPurposeIndicator').value === true">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('borrowing')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_BORROWING_AMOUNT_OF_LOAN' | translate"
                    [id]="'borrowingAmountOfLoan'"
                    [name]="'borrowingAmountOfLoan'"
                    [path]="'borrowingAmountOfLoan'"
                    [binding]="'borrowingAmountOfLoan'"
                    [currencyFormat]="true"
                    [attr]="{maxlength: 9}"
                    [className]="''"
                ></app-odc-field>
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('borrowing')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_BORROWING_INTEREST_RATE' | translate"
                    [id]="'borrowingInterestRate'"
                    [name]="'borrowingInterestRate'"
                    [path]="'borrowingInterestRate'"
                    [binding]="'borrowingInterestRate'"
                    [interestRateFormat]="true"
                    [attr]="{maxlength: 6}"
                    [className]="''"
                ></app-odc-field>
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('borrowing')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_BORROWING_LOAN_STARTING_DATE' | translate"
                    [id]="'borrowingLoanStartingDate'"
                    [name]="'borrowingLoanStartingDate'"
                    [path]="'borrowingLoanStartingDate'"
                    [binding]="'borrowingLoanStartingDate'"
                    [helpText]="'ODC_STEP_FINANCIAL_INFORMATION_DATE_HELP' | translate"
                    [dateFormat]="true"
                    [className]="''"
                ></app-odc-field>
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('borrowing')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_BORROWING_LOAN_MATURITY_DATE' | translate"
                    [id]="'borrowingLoanMaturityDate'"
                    [name]="'borrowingLoanMaturityDate'"
                    [path]="'borrowingLoanMaturityDate'"
                    [binding]="'borrowingLoanMaturityDate'"
                    [helpText]="'ODC_STEP_FINANCIAL_INFORMATION_DATE_HELP' | translate"
                    [dateFormat]="true"
                    [className]="''"
                ></app-odc-field>
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('borrowing')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_BORROWING_AMOUNT_INVESTED' | translate"
                    [id]="'borrowingAmountInvested'"
                    [name]="'borrowingAmountInvested'"
                    [path]="'borrowingAmountInvested'"
                    [binding]="'borrowingAmountInvested'"
                    [currencyFormat]="true"
                    [attr]="{maxlength: 9}"
                    [className]="''"
                ></app-odc-field>
                <app-odc-field
                    [type]="constants.FIELD_TYPES.CHECKBOX"
                    [form]="form.get('borrowing')"
                    [submitted]="isFormSubmitted()"
                    [choices]="[
                            {label: 'USER_BORROWING_RECEIVED_AND_READ_BORROWING_RISKS_INDICATOR' | translate,
                                id: 'borrowingReceivedAndReadBorrowingRisksIndicator',
                                binding:'borrowingReceivedAndReadBorrowingRisksIndicator'}
                        ]"
                    [path]="'borrowingReceivedAndReadBorrowingRisksIndicator'"
                ></app-odc-field>
            </div>
        </div>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>


<ng-template #helpFr>
    <div *ngIf="showIncomes()">
        <h2>Pourquoi dois-je fournir mon revenu annuel et mes actifs?</h2>
        <p>Ces informations nous permettent d'établir la solvabilité du client.</p>
    </div>

    <h2>Pourquoi me demander une référence bancaire?</h2>
    <p>Le Règlement sur le recyclage des produits de la criminalité et le financement des activités terroristes exige
        que le courtier obtienne la référence bancaire de chaque titulaire d'un compte de courtage. La référence
        bancaire sera également utilisée pour le dépôt direct, grâce auquel il vous sera facile de faire des virements
        de liquidités de votre compte de courtage à votre institution financière.</p>

    <h2>Comment puis-je trouver l'information sur mon numéro de compte et numéro de transit?</h2>
    <p>Vous pouvez retrouver ces informations sur un de vos chèques personnels. Votre numéro de transit se trouve au bas
        du chèque immédiatement après votre numéro de chèque et est composé de 5 chiffres. Votre numéro de compte se
        trouve en dernier et est habituellement de 7 chiffres. <a
            href="https://www.desjardins.com/particuliers/comptes-services-relies/modes-acces-comptes/internet/virer-fonds-entre-comptes/specimen-cheque/"
            rel="external" target="_blank">Cliquez ici pour voir un exemple</a>.</p>
</ng-template>
<ng-template #helpEn>
    <div *ngIf="showIncomes()">
        <h2>Why must I provide my annual income and value of my assets?</h2>
        <p>This information allows us to establish the creditworthiness of the client.</p>
    </div>

    <h2>Why do you ask me for a bank reference?</h2>
    <p>Regulations regarding money laundering of proceeds of crime and terrorist financing require that the broker gets
        a credit reference for each holder of a brokerage account. The bank reference will also be used for direct
        deposits, which facilitate cash transfers from your brokerage account to your financial institution.</p>

    <h2>Where can I find information my account number and transit number?</h2>
    <p>You can find this information on your personal checks. Your transit number is at the bottom of the check
        immediately after your check number and consists of 5 digits. Your account number is at the end and usually
        consists of 7 digits. <a
            href="https://www.desjardins.com/ca/personal/accounts-services/ways-to-bank/online/transfer-money-between-accounts/specimen-cheque/index.jsp"
            rel="external" target="_blank">Click here for more details</a>.</p>
</ng-template>

<ng-template #helpUserAnnualIncome>
    <h2>{{ 'USER_FINANCE_INFO_ANNUAL_REVENU_TOOLTIP_TITLE' | translate }}</h2>
    <p>{{ 'USER_FINANCE_INFO_ANNUAL_REVENU_TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #helpUserNetCapitalAsset>
    <h2>{{ 'USER_FINANCE_INFO_IMMO_ASSETS_TOOLTIP_TITLE' | translate }}</h2>
    <p>{{ 'USER_FINANCE_INFO_IMMO_ASSETS_TOOLTIP' | translate }}</p>
</ng-template>

<ng-template #helpUserNetLiquidityAsset>
    <h2>{{ 'USER_FINANCE_INFO_LIQUID_ASSETS_TOOLTIP_TITLE' | translate }}</h2>
    <p>{{ 'USER_FINANCE_INFO_LIQUID_ASSETS_TOOLTIP' | translate }}</p>
</ng-template>
