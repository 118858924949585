import {SummaryFieldsTypeEnum} from '../../enums/summary-fields-type.enum';

export const SummaryFieldsConstants: any = {
    userLang: ['ODC_STEP_PERSONAL_INFORMATION_LANGUAGE', SummaryFieldsTypeEnum.LANG],
    userEmail: ['ODC_STEP_PERSONAL_INFORMATION_EMAIL', SummaryFieldsTypeEnum.FREE_FORM],
    userHomePhone: ['ODC_STEP_PERSONAL_INFORMATION_PHONE_HOME', SummaryFieldsTypeEnum.PHONE],
    userMobilePhone: ['ODC_STEP_PERSONAL_INFORMATION_PHONE_MOBILE', SummaryFieldsTypeEnum.PHONE],
    userOtherPhone: ['ODC_STEP_PERSONAL_INFORMATION_PHONE_BUSINESS', SummaryFieldsTypeEnum.PHONE],
    fiscalUsCitizenshipIndicator: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_RESIDENCE_QUESTION', SummaryFieldsTypeEnum.BOOLEAN],
    userCitizenship: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_QUESTION', SummaryFieldsTypeEnum.COUNTRIES_LIST],
    UserFiscalRelationCanada: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1', SummaryFieldsTypeEnum.BOOLEAN],
    UserFiscalRelationUsa: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2', SummaryFieldsTypeEnum.BOOLEAN],
    UserFiscalRelationOther: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3', SummaryFieldsTypeEnum.BOOLEAN],
    UsaNif: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_TIN', SummaryFieldsTypeEnum.FREE_FORM],
    UserCountryNif2: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3', SummaryFieldsTypeEnum.COUNTRY_LIST],
    CodePremierAutreNif: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_TIN', SummaryFieldsTypeEnum.FREE_FORM],
    CodeSecondAutreNif: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_TIN', SummaryFieldsTypeEnum.FREE_FORM],
    UserCountryNif3: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3', SummaryFieldsTypeEnum.COUNTRY_LIST],
    userJobStatus: ['EMPLOYMENT_STATUS_LABEL', SummaryFieldsTypeEnum.JOB_LIST],
    userJobTitle: ['REVIEW_EMPLOYMENT_JOB_TITLE', SummaryFieldsTypeEnum.FREE_FORM],
    userEmployerName: ['ODC_STEP_SPOUSE_EMPLOYER_NAME', SummaryFieldsTypeEnum.FREE_FORM],
    spouseJobStatus: ['ODC_STEP_SPOUSE_JOB_TITLE', SummaryFieldsTypeEnum.FREE_FORM],
    homeAddress: ['REVIEW_HOME_ADDRESS', SummaryFieldsTypeEnum.FREE_FORM],
    mailingAddress: ['REVIEW_MAILING_ADDRESS', SummaryFieldsTypeEnum.FREE_FORM],
    userMaritalStatus: ['ODC_STEP_SPOUSE_INFORMATION_MARITAL_STATUS', SummaryFieldsTypeEnum.MARITAL_STATUS_LIST],
    spouseFirstName: ['ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME', SummaryFieldsTypeEnum.FREE_FORM],
    spouseLastName: ['ODC_STEP_PERSONAL_INFORMATION_LAST_NAME', SummaryFieldsTypeEnum.FREE_FORM],
    userInitieIndicator: ['REVIEW_REPORTING_INSIDER', SummaryFieldsTypeEnum.BOOLEAN],
    userMainShareholderIndicator: ['REVIEW_SHAREHOLDER', SummaryFieldsTypeEnum.BOOLEAN],
    userFinancialInterestIndicator: ['REVIEW_THIRD_PARTY_USE', SummaryFieldsTypeEnum.BOOLEAN],
    userTrustedPersonIndicator: ['REVIEW_THIRD_PARTY_USE', SummaryFieldsTypeEnum.BOOLEAN],
    userBrokerageKnowledge: ['BROKERAGE_KNOWLEDGE', SummaryFieldsTypeEnum.BROKERAGE_KNOWLEDGE_LIST],
    userAnnualIncome: ['REVIEW_ANNUAL_REVENUE', SummaryFieldsTypeEnum.FINANCIAL_FORM],
    userTotalNetWorth: ['USER_FINANCE_INFO_TOTAL_NET_WORTH', SummaryFieldsTypeEnum.FINANCIAL_FORM],
    userNetCapitalAsset: ['USER_FINANCE_INFO_IMMO_ASSETS_A', SummaryFieldsTypeEnum.FINANCIAL_FORM],
    userNetLiquidityAsset: ['USER_FINANCE_INFO_LIQUID_ASSETS_B', SummaryFieldsTypeEnum.FINANCIAL_FORM],
    borrowedForInvestmentPurposeIndicator: ['USER_BORROWING_FOR_INVESTMENT_PURPOSE_QUESTION', SummaryFieldsTypeEnum.BOOLEAN],
    borrowingReceivedAndReadBorrowingRisksIndicator: ['USER_BORROWING_RECEIVED_AND_READ_BORROWING_RISKS_INDICATOR', SummaryFieldsTypeEnum.BOOLEAN],
    userInvestmentObjectivesIntendedUse: ['USER_INVESTMENT_OBJECTIVES_INTENDED_USE_TITLE', SummaryFieldsTypeEnum.INTENDED_USAGE_LIST],
    userInvestmentObjectivesNonRegInvHorizon: ['USER_INVESTMENT_OBJECTIVES_INVESTMENT_HORIZON_TITLE', SummaryFieldsTypeEnum.INVESTMENT_HORIZON_LIST],
    userInvestmentObjectivesRskTol: ['USER_INVESTMENT_OBJECTIVES_RISK_TOLERANCE_TITLE', SummaryFieldsTypeEnum.RISK_TOLERANCE_LIST],
    userInvestmentObjectivesLowRiskReg: ['USER_INVESTMENT_OBJECTIVES_TABLE_ROW1', SummaryFieldsTypeEnum.PERCENTAGE_FORM],
    userInvestmentObjectivesModerateRiskReg: ['USER_INVESTMENT_OBJECTIVES_TABLE_ROW2', SummaryFieldsTypeEnum.PERCENTAGE_FORM],
    userInvestmentObjectivesModHighRiskReg: ['USER_INVESTMENT_OBJECTIVES_TABLE_ROW3', SummaryFieldsTypeEnum.PERCENTAGE_FORM],
    userInvestmentObjectivesSpeculativeStrategiesReg: ['USER_INVESTMENT_OBJECTIVES_TABLE_ROW4', SummaryFieldsTypeEnum.PERCENTAGE_FORM],
    personalInformationSpecificConsentIndicator: ['SPECIFIC_CONSENT_COMPARISON', SummaryFieldsTypeEnum.BOOLEAN],
    userDisclaimerIndicator: ['REVIEW_CONSENTMENT', SummaryFieldsTypeEnum.BOOLEAN],
    userReceiptDocumentSecuritiesHolderIndicator: ['REVIEW_RECEIPT_METHOD', SummaryFieldsTypeEnum.DOCUMENT_SECURITY_HOLDER_LIST],
    userElectronicDocumentSecuritiesHolderIndicator: ['REVIEW_ELECTRONIC_METHOD', SummaryFieldsTypeEnum.BOOLEAN],
    UserNifMissingReason: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_TIN_QUESTION', SummaryFieldsTypeEnum.BOOLEAN],
    UserNifMissingReason2: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_REASON', SummaryFieldsTypeEnum.NIF_LIST],
    UserNifMissingReason3: ['ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_REASON', SummaryFieldsTypeEnum.NIF_LIST],
};
