import {Message} from './message';

export class WebException {

    listError: Message[];
    listWarning: Message[];
    status: number;

    constructor(error: any, status: number) {

        this.listError = error && error.listError || [];
        this.listWarning = error && error.listWarning || [];
        this.status = status;
    }
}
