<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <h2>{{'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_TITLE' | translate}}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--formLang----------------------------------------------------------------------->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_LANGUAGE' | translate"
        [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_FR' | translate, value: constants.LANG_FR}, {label: 'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_EN' | translate, value: constants.LANG_EN}]"
        [id]="'formLang'"
        [name]="'formLang'"
        [path]="'formLang'"
        [binding]="getRequesterPath('formLang')"
    ></app-odc-field>

    <!--Gender-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
        [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
        [id]="'gender'"
        [name]="'gender'"
        [path]="'gender'"
        [binding]="getRequesterPath('gender')"
    ></app-odc-field>

    <!--firstname & lastname--------------------------------->
    <div class="row">
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getUserFirstNameLabel() | translate"
                [id]="'userFirstName'"
                [name]="'userFirstName'"
                [path]="'userFirstName'"
                [binding]="getRequesterPath('userFirstName')"
                [attr]="{maxlength: 32}"
            ></app-odc-field>
        </div>
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getUserLastNameLabel() | translate"
                [id]="'userLastName'"
                [name]="'userLastName'"
                [path]="'userLastName'"
                [binding]="getRequesterPath('userLastName')"
                [attr]="{maxlength: 32}"
            ></app-odc-field>
        </div>
    </div>

    <!--SIN of Contact Person (case Sole Proprietorship account)--------------------------->
    <div *ngIf="displaySoleProprietorsSin()" class="row">
        <div class="col-md-12">
            <app-odc-field
                [autoComplete]="'off'"
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getSINLabel() | translate"
                [id]="'userSIN'"
                [name]="'userSIN'"
                [path]="'userSIN'"
                [binding]="getRequesterPath('userSIN')"
                [attr]="{maxlength: 11}"
                [sinFormat]="true"
                [templateRefFr]="helpSIN"
                [templateRefEn]="helpSIN"
            ></app-odc-field>
        </div>
    </div>

    <!--Company Name----------------------------------------------------------------------->
    <div *ngIf="displayCompanyName()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_COMPANY_NAME' | translate"
            [id]="'companyName'"
            [name]="'companyName'"
            [path]="'companyName'"
            [binding]="'accountHolderCompanyName'"
            [attr]="{maxlength: 128}"
        ></app-odc-field>
    </div>

    <!--HomePhone & OtherPhone------------------------------------------------>
    <div formGroupName="phoneGroup">
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('phoneGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PRIMARY_PHONE' | translate"
                    [id]="'userHomePhone'"
                    [name]="'userHomePhone'"
                    [path]="'userHomePhone'"
                    [binding]="getRequesterPath('userHomePhone')"
                    [attr]="{maxlength: 10}"
                    [phoneFormat]="true"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('phoneGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_OTHER_PHONE' | translate"
                    [id]="'userOtherPhone'"
                    [name]="'userOtherPhone'"
                    [path]="'userOtherPhone'"
                    [binding]="getRequesterPath('userOtherPhone')"
                    [attr]="{maxlength: 10}"
                    [phoneFormat]="true"
                ></app-odc-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('phoneGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MOBILE_PHONE' | translate"
                    [id]="'userMobilePhone'"
                    [name]="'userMobilePhone'"
                    [path]="'userMobilePhone'"
                    [binding]="getRequesterPath('userMobilePhone')"
                    [attr]="{maxlength: 10}"
                    [phoneFormat]="true"
                ></app-odc-field>
            </div>
        </div>
    </div>

    <!--Email----------------------------------------------------------------------->
    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_EMAIL' | translate"
        [id]="'userEmail'"
        [name]="'userEmail'"
        [path]="'userEmail'"
        [binding]="getRequesterPath('userEmail')"
        [attr]="{maxlength: 64}"
    ></app-odc-field>

    <!--federal Number & provincial Number--------------------------------->
    <div *ngIf="displayCompanyInformation()" class="row" formGroupName="businessNumberGroup">

        <div class="input-group m-b-1">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('businessNumberGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FEDERAL_BUSINESS_NUMBER' | translate"
                    [id]="'federalBusinessNumber'"
                    [name]="'federalBusinessNumber'"
                    [path]="'federalBusinessNumber'"
                    [templateRefEn]="helpFederalBusinessNumber"
                    [templateRefFr]="helpFederalBusinessNumber"
                    [attr]="{maxlength: 9}"
                    [numericFormat]="true"
                ></app-odc-field>
            </div>
            <div class="col-md-4">
                <app-odc-field
                    [hiddenLabel]="true"
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('businessNumberGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'.'"
                    [id]="'federalBusinessNumberExtension'"
                    [name]="'federalBusinessNumberExtension'"
                    [path]="'federalBusinessNumberExtension'"
                    [attr]="{maxlength: 6}"
                ></app-odc-field>
            </div>
        </div>

        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form.get('businessNumberGroup')"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PROVINCIAL_BUSINESS_NUMBER' | translate"
                [id]="'provincialBusinessNumber'"
                [name]="'provincialBusinessNumber'"
                [path]="'provincialBusinessNumber'"
                [binding]="'accountHolderProvincialBusinessNumber'"
                [numericFormat]="true"
                [attr]="{maxlength: 10}"
            ></app-odc-field>
        </div>
        <!--province-->
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form.get('businessNumberGroup')"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PROVINCE' | translate"
                [id]="'provinceOfBusinessNumber'"
                [name]="'provinceOfBusinessNumber'"
                [path]="'provinceOfBusinessNumber'"
                [list]="provinces"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="'accountHolderProvinceOfBusinessNumber'"
            ></app-odc-field>
        </div>
    </div>

    <div class="row" *ngIf="displayFiscalPeriodEndDate()">
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FISCAL_PERIOD_END_DATE' | translate"
                [helpText]="'GLOBAL_DATE_HELP' | translate"
                [id]="'fiscalPeriodEndDate'"
                [name]="'fiscalPeriodEndDate'"
                [path]="'fiscalPeriodEndDate'"
                [binding]="'fiscalPeriodEndDate'"
                [attr]="{maxlength: 10}"
                [datePicker]="true"
                [autoComplete]="'off'"
            ></app-odc-field>
        </div>
    </div>

    <!--other firstname & lastname--------------------------------->
    <div *ngIf="displayOtherName()" class="row">
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getOtherFirstNameLabel() | translate"
                [id]="'otherFirstName'"
                [name]="'otherFirstName'"
                [path]="'otherFirstName'"
                [binding]="'otherFirstName'"
                [attr]="{maxlength: 32}"
            ></app-odc-field>
        </div>
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getOtherLastNameLabel() | translate"
                [id]="'otherLastName'"
                [name]="'otherLastName'"
                [path]="'otherLastName'"
                [binding]="'otherLastName'"
                [attr]="{maxlength: 32}"
            ></app-odc-field>
        </div>
    </div>

    <!--DOB & SIN of Parent or Child---------------------------------------------------->
    <div *ngIf="displayParentDobAndSin() || displayChildDobAndSin()" class="row">
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getDOBLabel() | translate"
                [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                [id]="'userDOB'"
                [name]="'userDOB'"
                [path]="'userDOB'"
                [binding]="getRequesterPath('userDOB')"
                [attr]="{maxlength: 10}"
                [dateFormat]="true"
                [templateRefFr]="helpDOB"
                [templateRefEn]="helpDOB"
                [autoComplete]="'off'"
            ></app-odc-field>
        </div>
        <div class="col-md-12">
            <app-odc-field
                [autoComplete]="'off'"
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="getSINLabel() | translate"
                [id]="'userSIN'"
                [name]="'userSIN'"
                [path]="'userSIN'"
                [binding]="getRequesterPath('userSIN')"
                [attr]="{maxlength: 11}"
                [sinFormat]="true"
                [templateRefFr]="helpSIN"
                [templateRefEn]="helpSIN"
            ></app-odc-field>
        </div>
    </div>

    <div *ngIf="displayAddress()" formGroupName="addressGroup">
        <!--Home Address--------------------------------------------------------------------------->
        <h3>{{getHomeAddressLabel()|translate}}</h3>
        <div formGroupName="homeAddress">
            <app-address-form
                [legend]="getHomeAddressLabel()|translate"
                [form]="form"
                [index]="''"
                [submitted]="isFormSubmitted()"
                [bindPath]="'requesters.' + requesterIndex + '.'"
                [path]="'addressGroup.homeAddress'"
                [street]="'userAddressStreet'"
                [unit]="'userAddressUnit'"
                [city]="'userAddressCity'"
                [province]="'userAddressProv'"
                [postalcode]="'userAddressPostalCode'"
                [country]="'userAddressCountry'"
                [isCountryReadOnly]="true"
            ></app-address-form>
        </div>

        <div class="m-b-1">
            <ng-container *ngIf="!showMailingAddress">
                <a href="#" class="lien-sans-soulignement" (click)="toggleMailingAddress(true)">
                    <span class="icon-circle-plus"></span>
                    {{'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_ADD'|translate}}
                </a>
            </ng-container>
        </div>


        <!--Mailing Address---------------------------------------------------------------->
        <div *ngIf="showMailingAddress" formGroupName="mailingAddress">
            <h3>{{getMailingAddressLabel()|translate}}</h3>
            <app-address-form
                [legend]="getMailingAddressLabel()|translate"
                [form]="form"
                [index]="''"
                [submitted]="isFormSubmitted()"
                [bindPath]="'requesters.' + requesterIndex + '.'"
                [path]="'addressGroup.mailingAddress'"
                [street]="'userAddressStreetSec'"
                [unit]="'userAddressUnitSec'"
                [city]="'userAddressCitySec'"
                [province]="'userAddressProvSec'"
                [postalcode]="'userAddressPostalCodeSec'"
                [country]="'userAddressCountrySec'"
            ></app-address-form>
        </div>

        <div class="m-b-1">
            <ng-container *ngIf="showMailingAddress">
                <a href="#" class="lien-sans-soulignement" (click)="toggleMailingAddress(false)">
                    <span class="icon-close rouge"></span>
                    {{'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_REMOVE'|translate}}
                </a>
            </ng-container>
        </div>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpFederalBusinessNumber>
    <div [innerHTML]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FEDERAL_BUSINESS_NUMBER_HELP' | translate"></div>
</ng-template>

<ng-template #helpSIN>
    <h2>{{'HELP_SIN_TITLE' | translate}}</h2>
    <p>{{'HELP_SIN_BODY' | translate}}</p>
</ng-template>

<ng-template #helpDOB>
    <h2>{{'HELP_DOB_TITLE' | translate}}</h2>
    <p>{{'HELP_DOB_BODY' | translate}}</p>
</ng-template>
