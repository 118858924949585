export class AttachedDocumentValidation {

    id: number;
    contentHash: string;
    userId: string;
    docId: number;

    constructor(obj?: any) {
        this.id = obj && obj.id || null;
        this.contentHash = obj && obj.contentHash || null;
        this.userId = obj && obj.userId || null;
        this.docId = obj && obj.docId || null;
    }
}
