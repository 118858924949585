import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {VmdConstants} from '../constants/vmd-constants';
import {Functions} from '../utils/functions';

export class VmdValidators {

    static modeIsPrint(isPrint: boolean, isPleinEx: boolean): ValidatorFn | null {

        return !isPrint && isPleinEx ? Validators.pattern(VmdConstants.ACCOUNT_NEW_CLIENT) : Validators.nullValidator;
    }

    static updateContext(isPrint: boolean, isPleinEx: boolean): ValidatorFn | null {

        return !isPrint && isPleinEx ? Validators.pattern(VmdConstants.ACCOUNT_UPDATE_CLIENT) : Validators.nullValidator;
    }

    static addError(control: AbstractControl, error: string, value: any): void {
        if (control.errors) {
            control.errors[error] = value;
        } else {
            const errors = {};
            errors[error] = value;
            control.setErrors(errors);
        }
    }

    static removeError(control: AbstractControl, error: string): void {
        if (control && control.hasError(error)) {
            delete control.errors[error];
            if (Object.keys(control.errors).length === 0) {
                control.setErrors(null);
            }
        }
    }

    static required(isEnabled: boolean): ValidatorFn | null {

        return isEnabled ? Validators.required : Validators.nullValidator;
    }

    static requiredFalse(control: AbstractControl): ValidationErrors | null {
        return control.value === false ? null : {requiredFalse: true};
    }

    static alphanumeric(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.ALPHA_NUM_REGEXP, {alphanumeric: true});
    }

    static alphanumericWithDash(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.ALPHA_NUM_AND_DASH_REGEXP, {alphanumeric: true});
    }

    static alphanumericWithSpace(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidatorAcceptSpace(control, VmdConstants.ALPHA_NUM_REGEXP, {alphanumeric: true});
    }

    static addressStreetValidator(isRequired: boolean): ValidatorFn | null {

        if (isRequired) {
            return (control: FormControl): ValidationErrors | null => {
                let isValid = false;

                if (!Functions.isNullOrEmpty(control.value)) {
                    const addressStreet = ' ' + control.value + ' ';
                    isValid = VmdConstants.HAS_DIGIT_REGEXP.test(addressStreet) ||
                        VmdConstants.POST_BOX_REGEXP.test(addressStreet);
                }

                return isValid ? Validators.nullValidator : {required: true};
            };
        }

        return Validators.nullValidator;
    }

    static date(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            isValid = (VmdValidators.getDate(control.value) !== null);
        }
        return isValid ? null : {date: true};
    }

    static dateInFuture(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            const date = VmdValidators.getDate(control.value);
            isValid = (date === null) || moment().isBefore(date);
        }

        return isValid ? null : {dateinfuture: true};
    }

    static lengthValidator(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value && (control.value.length < min || control.value.length > max)) {
                return {invalidRange: true};
            }
            return null;
        };
    }

    static dateOfBirthAll(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.dateOfBirthAgeRange(control, VmdConstants.MIN_AGE_ALL, VmdConstants.MAX_AGE, {dateofbirthmajor: true});
    }

    static dateOfBirthMajor(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.dateOfBirthAgeRange(control, VmdConstants.MIN_AGE_ALL, VmdConstants.MAX_AGE, {dateofbirthmajor: true});
    }

    static dateOfBirthYoung(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.dateOfBirthAgeRange(control, VmdConstants.MIN_AGE, VmdConstants.MAX_AGE, {dateofbirthyoung: true});
    }

    static notADateOfBirthYoung(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.dateOfBirthAgeRange(control, VmdConstants.MIN_AGE_ALL, VmdConstants.MAX_AGE, {dateofbirthyoung: false});
    }

    static dateOfBirthChild(control: AbstractControl): ValidationErrors | null {
        const isFuture = VmdValidators.dateOfBirthChildInFuture(control);
        if (isFuture) {
            return isFuture;
        }

        const isOver150 = VmdValidators.dateOfBirthChildMoreThan150(control);
        if (isOver150) {
            return isOver150;
        }

        const isMajor = VmdValidators.dateOfBirthAgeRange(control, VmdConstants.MIN_AGE_ALL, VmdConstants.MIN_AGE, {dateofbirthchild: true});
        if (isMajor) {
            return isMajor;
        }
        return null;
    }

    static dateOfBirthChildInFuture(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            const date = VmdValidators.getDate(control.value);
            isValid = (date === null) || moment().isAfter(date);
        }

        return isValid ? null : {dateofbirthmajor: true};
    }

    static dateOfBirthChildMoreThan150(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            const date = VmdValidators.getDate(control.value);
            if (date === null) {
                return null;
            } else {
                const ageEndOfYear = moment.duration(moment().startOf('day').diff(date)).asYears();
                isValid = ageEndOfYear < VmdConstants.MAX_AGE;
            }
        }
        return isValid ? null : {dateofbirtchildmorethan150: true};
    }


    static dateOfBirthRrsp(control: AbstractControl): ValidationErrors | null {
        let isValid = true;

        if (!!control.value) {
            const date = VmdValidators.getDate(control.value);
            if (date === null) {
                return null;
            } else {
                const ageEndOfYear = moment().year() - date.year();
                isValid = ageEndOfYear <= VmdConstants.MAX_AGE_RRSP;
            }
        }

        return isValid ? null : {dateofbirthrrsp: true};
    }

    static digit(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.DIGIT_REGEXP, {digit: true});
    }

    static email(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.EMAIL_REGEXP, {email: true});
    }

    static phone(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.PHONE_REGEXP, {phone: true});
    }

    static sin(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            const sinToValidate = control.value.replace(/ /g, '').trim();

            // convert value to array;
            const aValue = sinToValidate.split('');
            let nSum = 0;
            let nNum;

            nNum = Number(aValue[0]);

            if (isValid) {
                for (let i = 0; i < 9; i++) {
                    nNum = Number(aValue[i]);
                    // double odd number elements;
                    if (i % 2 === 1) {
                        nNum *= 2;
                    }

                    // reduce values greater than 9 to sum of digits;
                    if (nNum > 9) {
                        nNum = Math.floor(nNum / 10) + (nNum % 10);
                    }

                    // sum values
                    nSum += nNum;
                }

                if (nSum === 0) {
                    isValid = false;
                } else {
                    isValid = ((nSum % 10) === 0); // modulo test results;
                }
            }

        }
        return isValid ? null : {sin: true};
    }

    static transit(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            isValid = VmdConstants.DIGIT_REGEXP.test(control.value) && control.value.length === 5;
        }
        return isValid ? null : {transit: true};
    }

    static username(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.USERNAME_REGEXP, {username: true});
    }

    static zipCode(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true});
    }

    static genericZipcodeRequired(control: AbstractControl, zipcodeLabel: string): ValidationErrors | null {
        const zipcode = control.get(zipcodeLabel);

        if (!zipcode.value) {
            zipcode.setErrors({required: true});
        } else {
            if (zipcode.hasError('required')) {
                zipcode.setErrors(null);
            }
        }
        return null;
    }

    static zipCodeValidator(control: AbstractControl, country: string): ValidationErrors | null {

        if (VmdConstants.COUNTRIES.CANADA === country) {
            return VmdValidators.regexpValidator(control, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true});
        } else if (VmdConstants.COUNTRIES.UNITED_STATES === country) {
            return VmdValidators.regexpValidator(control, VmdConstants.USA_ZIP_CODE_REGEXP, {zipcode: true});
        }
    }

    static homeAddressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const userAddressPostalCode: AbstractControl = control.get('userAddressPostalCode');
        const userAddressCountry: AbstractControl = control.get('userAddressCountry');

        if (VmdValidators.isValidControlandValue(userAddressPostalCode)) {

            VmdValidators.isValidControlandValue(userAddressCountry) ?
                control.get('userAddressPostalCode').setErrors(VmdValidators.zipCodeValidator(userAddressPostalCode, userAddressCountry.value)) :
                control.get('userAddressPostalCode').setErrors(VmdValidators.regexpValidator(userAddressPostalCode, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static homeAddressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'userAddressPostalCode');
    }

    static beneficiaryRespAddressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryRespAddressPostalCode: AbstractControl = control.get('beneficiaryRespAddressPostalCode');
        const beneficiaryRespAddressCountry: AbstractControl = control.get('beneficiaryRespAddressCountry');

        if (VmdValidators.isValidControlandValue(beneficiaryRespAddressPostalCode)) {

            VmdValidators.isValidControlandValue(beneficiaryRespAddressCountry) ?
                control.get('beneficiaryRespAddressPostalCode').setErrors(VmdValidators.zipCodeValidator(beneficiaryRespAddressPostalCode, beneficiaryRespAddressCountry.value)) :
                control.get('beneficiaryRespAddressPostalCode').setErrors(VmdValidators.regexpValidator(beneficiaryRespAddressPostalCode, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryRespAddressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryRespAddressPostalCode');
    }

    /**
     * Validate if the RESP reason required
     */
    static beneficiaryRespReasonRequired(respReason: AbstractControl, respBrotherAndSister: string) {

        if (!respBrotherAndSister && !respReason.value) {
            respReason.setErrors({reasonrequired: true});
        } else {
            if (!!respReason && !!respReason.getError('reasonrequired')) {
                respReason.setErrors(null);
            }
        }

        return null;
    }

    static mailingAddressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const userAddressPostalCodeSec: AbstractControl = control.get('userAddressPostalCodeSec');
        const userAddressCountrySec: AbstractControl = control.get('userAddressCountrySec');

        if (VmdValidators.isValidControlandValue(userAddressPostalCodeSec)) {
            VmdValidators.isValidControlandValue(userAddressCountrySec) ?
                control.get('userAddressPostalCodeSec').setErrors(VmdValidators.zipCodeValidator(userAddressPostalCodeSec, userAddressCountrySec.value)) :
                control.get('userAddressPostalCodeSec').setErrors(VmdValidators.regexpValidator(userAddressPostalCodeSec, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static mailingAddressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'userAddressPostalCodeSec');
    }

    static attorneyAddressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const attorneyAddressPostalCode: AbstractControl = control.get('attorneyAddressPostalCode');
        const attorneyAddressCountry: AbstractControl = control.get('attorneyAddressCountry');

        if (VmdValidators.isValidControlandValue(attorneyAddressPostalCode)) {

            VmdValidators.isValidControlandValue(attorneyAddressCountry) ?
                control.get('attorneyAddressPostalCode').setErrors(VmdValidators.zipCodeValidator(attorneyAddressPostalCode, attorneyAddressCountry.value)) :
                control.get('attorneyAddressPostalCode').setErrors(VmdValidators.regexpValidator(attorneyAddressPostalCode, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static attorneyAddressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'attorneyAddressPostalCode');
    }

    static beneficiaryReerAdressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryReerAdressPostalCode: AbstractControl = control.get('beneficiaryReerAdressPostalCode');
        const beneficiaryReerAdressCountry: AbstractControl = control.get('beneficiaryReerAdressCountry');

        if (VmdValidators.isValidControlandValue(beneficiaryReerAdressPostalCode)) {

            VmdValidators.isValidControlandValue(beneficiaryReerAdressCountry) ?
                control.get('beneficiaryReerAdressPostalCode').setErrors(VmdValidators.zipCodeValidator(beneficiaryReerAdressPostalCode, beneficiaryReerAdressCountry.value)) :
                control.get('beneficiaryReerAdressPostalCode').setErrors(VmdValidators.regexpValidator(beneficiaryReerAdressPostalCode, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryReerAddressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryReerAdressPostalCode');
    }

    static beneficiaryReerAdressSecZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryReerAdressPostalCodeSec: AbstractControl = control.get('beneficiaryReerAdressPostalCodeSec');
        const beneficiaryReerAdressCountrySec: AbstractControl = control.get('beneficiaryReerAdressCountrySec');

        if (VmdValidators.isValidControlandValue(beneficiaryReerAdressPostalCodeSec)) {

            VmdValidators.isValidControlandValue(beneficiaryReerAdressCountrySec) ?
                control.get('beneficiaryReerAdressPostalCodeSec').setErrors(VmdValidators.zipCodeValidator(beneficiaryReerAdressPostalCodeSec, beneficiaryReerAdressCountrySec.value)) :
                control.get('beneficiaryReerAdressPostalCodeSec').setErrors(VmdValidators.regexpValidator(beneficiaryReerAdressPostalCodeSec, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryReerAdressSecZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryReerAdressPostalCodeSec');
    }

    static beneficiaryReerAdressTerZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryReerAdressPostalCodeTer: AbstractControl = control.get('beneficiaryReerAdressPostalCodeTer');
        const beneficiaryReerAdressCountryTer: AbstractControl = control.get('beneficiaryReerAdressCountryTer');

        if (VmdValidators.isValidControlandValue(beneficiaryReerAdressPostalCodeTer)) {

            VmdValidators.isValidControlandValue(beneficiaryReerAdressCountryTer) ?
                control.get('beneficiaryReerAdressPostalCodeTer').setErrors(VmdValidators.zipCodeValidator(beneficiaryReerAdressPostalCodeTer, beneficiaryReerAdressCountryTer.value)) :
                control.get('beneficiaryReerAdressPostalCodeTer').setErrors(VmdValidators.regexpValidator(beneficiaryReerAdressPostalCodeTer, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryReerAdressTerZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryReerAdressPostalCodeTer');
    }

    static beneficiaryCeliAdressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryCeliAdressPostalCode: AbstractControl = control.get('beneficiaryCeliAdressPostalCode');
        const beneficiaryCeliAdressCountry: AbstractControl = control.get('beneficiaryCeliAdressCountry');

        if (VmdValidators.isValidControlandValue(beneficiaryCeliAdressPostalCode)) {

            VmdValidators.isValidControlandValue(beneficiaryCeliAdressCountry) ?
                control.get('beneficiaryCeliAdressPostalCode').setErrors(VmdValidators.zipCodeValidator(beneficiaryCeliAdressPostalCode, beneficiaryCeliAdressCountry.value)) :
                control.get('beneficiaryCeliAdressPostalCode').setErrors(VmdValidators.regexpValidator(beneficiaryCeliAdressPostalCode, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryCeliAdressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryCeliAdressPostalCode');
    }

    static beneficiaryCeliAdressSecZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryCeliAdressPostalCodeSec: AbstractControl = control.get('beneficiaryCeliAdressPostalCodeSec');
        const beneficiaryCeliAdressCountrySec: AbstractControl = control.get('beneficiaryCeliAdressCountrySec');

        if (VmdValidators.isValidControlandValue(beneficiaryCeliAdressPostalCodeSec)) {

            VmdValidators.isValidControlandValue(beneficiaryCeliAdressCountrySec) ?
                control.get('beneficiaryCeliAdressPostalCodeSec').setErrors(VmdValidators.zipCodeValidator(beneficiaryCeliAdressPostalCodeSec, beneficiaryCeliAdressCountrySec.value)) :
                control.get('beneficiaryCeliAdressPostalCodeSec').setErrors(VmdValidators.regexpValidator(beneficiaryCeliAdressPostalCodeSec, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryCeliAdressSecZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryCeliAdressPostalCodeSec');
    }

    static beneficiaryCeliAdressTerZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const beneficiaryCeliAdressPostalCodeTer: AbstractControl = control.get('beneficiaryCeliAdressPostalCodeTer');
        const beneficiaryCeliAdressCountryTer: AbstractControl = control.get('beneficiaryCeliAdressCountryTer');

        if (VmdValidators.isValidControlandValue(beneficiaryCeliAdressPostalCodeTer)) {

            VmdValidators.isValidControlandValue(beneficiaryCeliAdressCountryTer) ?
                control.get('beneficiaryCeliAdressPostalCodeTer').setErrors(VmdValidators.zipCodeValidator(beneficiaryCeliAdressPostalCodeTer, beneficiaryCeliAdressCountryTer.value)) :
                control.get('beneficiaryCeliAdressPostalCodeTer').setErrors(VmdValidators.regexpValidator(beneficiaryCeliAdressPostalCodeTer, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static beneficiaryCeliAdressTerZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'beneficiaryCeliAdressPostalCodeTer');
    }

    static baseTransferAdressZipCodeValidator(control: FormGroup): ValidationErrors | null {

        const addressPostalCode: AbstractControl = control.get('addressPostalCode');
        const addressCountry: AbstractControl = control.get('addressCountry');

        if (VmdValidators.isValidControlandValue(addressPostalCode)) {

            VmdValidators.isValidControlandValue(addressCountry) ?
                control.get('addressPostalCode').setErrors(VmdValidators.zipCodeValidator(addressPostalCode, addressCountry.value)) :
                control.get('addressPostalCode').setErrors(VmdValidators.regexpValidator(addressPostalCode, VmdConstants.CANADA_ZIP_CODE_REGEXP, {zipcode: true}));
        }

        return null;
    }

    static baseTransferAddressZipCodeRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericZipcodeRequired(control, 'addressPostalCode');
    }

    static clientNumber(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.regexpValidator(control, VmdConstants.CLIENT_NUMBER_REGEXP, {username: true});
    }

    static positiveDigit(control: AbstractControl): ValidationErrors | null {
        let isValid = true;
        if (!isNaN(parseInt(control.value, 10))) {
            isValid = control.value > 0;
        }
        return isValid ? null : {positivedigit: true};
    }

    static validMinMaxCumul(controls: AbstractControl[], min: number, max: number, isPrintMode: boolean = false) {
        let sum = 0;

        for (const control of controls) {
            if (!!control && !!control.value && (control.value < min)) {
                control.setErrors({morethanminrequired: true});
            }
        }

        for (const control of controls) {
            if (!!control) {
                sum = sum + control.value * 1;
            }
        }

        if (sum > max) {
            for (const control of controls) {
                if (!!control) {
                    control.setErrors({lessthanmaxrequired: true});
                }
            }
        } else {
            for (const control of controls) {
                if (!!control && !!control.getError('lessthanmaxrequired')) {
                    control.setErrors(null);
                }
            }
        }

        return null;
    }

    static validPercentageCumul(controls: AbstractControl[], percentage: number) {
        let sum = 0;

        for (const control of controls) {

            if (!!control && control.value) {

                if (!isNaN(parseInt(control.value, 10))) {
                    sum = sum + control.value * 1;
                }

            } else {
                return null;
            }
        }

        if (sum !== percentage) {
            for (const control of controls) {
                if (!!control) {
                    control.setErrors({percentagecumulrequired: true});
                }
            }
        } else {
            for (const control of controls) {
                if (!!control && !!control.getError('percentagecumulrequired')) {
                    control.setErrors(null);
                }
            }
        }

        return null;
    }

    static dateRange(start: string, end: string): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            let isValid = true;

            if (!!control.value) {
                const date = VmdValidators.getDate(control.value);
                if (date !== null) {

                    if (date.isBefore(moment(start), 'day') || date.isAfter(moment(end), 'day')) {
                        isValid = false;
                    }
                }
            }

            return isValid ? null : {dateRange: true};
        };
    }

    /**
     * Validate if the HomePhone and OtherPhone is matched
     */
    static phoneMatchValidator(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericPhoneMatchValidator(control, 'userHomePhone', 'userOtherPhone');
    }

    static accountHolderPhonesMatchValidator(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.accountHolderGenericPhonesMatchValidator(control, 'userHomePhone', 'userOtherPhone', 'userMobilePhone');
    }

    static accountHolderPhonesRequiredValidator(control: AbstractControl): ValidationErrors | null {
        const homePhone = control.get('userHomePhone');
        const otherPhone = control.get('userOtherPhone');
        const mobilePhone = control.get('userMobilePhone');

        if (mobilePhone) {
            if (!homePhone.value && !otherPhone.value && !mobilePhone.value) {
                if (!homePhone.hasError('any_required')
                    || !otherPhone.hasError('any_required')
                    || !mobilePhone.hasError('any_required')) {
                    homePhone.setErrors({any_required: true});
                    otherPhone.setErrors({any_required: true});
                    mobilePhone.setErrors({any_required: true});
                }
            } else {
                if (homePhone.hasError('any_required')
                    && !(homePhone.hasError('phoneMatch') || homePhone.hasError('phone'))) {
                    homePhone.setErrors(null);
                }
                if (otherPhone.hasError('any_required')
                    && !(otherPhone.hasError('phoneMatch') || otherPhone.hasError('phone'))) {
                    otherPhone.setErrors(null);
                }
                if (mobilePhone.hasError('any_required')
                    && !(mobilePhone.hasError('phoneMatch') || mobilePhone.hasError('phone'))) {
                    mobilePhone.setErrors(null);
                }
            }
        } else {
            if (!homePhone.value && !otherPhone.value) {
                if (!homePhone.hasError('any_required')
                    || !otherPhone.hasError('any_required')
                ) {
                    homePhone.setErrors({any_required: true});
                    otherPhone.setErrors({any_required: true});
                }
            } else {
                if (homePhone.hasError('any_required')
                    && !(homePhone.hasError('phoneMatch') || homePhone.hasError('phone'))) {
                    homePhone.setErrors(null);
                }
                if (otherPhone.hasError('any_required')
                    && !(otherPhone.hasError('phoneMatch') || otherPhone.hasError('phone'))) {
                    otherPhone.setErrors(null);
                }
            }
        }
        return null;
    }

    static phoneRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericPhoneRequired(control, 'userHomePhone', 'userOtherPhone');
    }

    /**
     * Validate if the HomePhone and OtherPhone is matched
     */
    static attorneyPhoneMatchValidator(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericPhoneMatchValidator(control, 'attorneyHomePhone', 'attorneyOtherPhone');
    }

    /**
     * Validate if the
     */
    static attorneyPhoneRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericPhoneRequired(control, 'attorneyHomePhone', 'attorneyOtherPhone');
    }

    /**
     * Validate either userNif or userNifMissingReason must be provided
     */
    static usaNifRequired(control: FormGroup): ValidationErrors | null {
        return VmdValidators.genericNifRequired(control, 'usaNif', 'userNifMissingReason');
    }

    /**
     * Validate either codePremierAutreNif or userNifMissingReason2 must be provided
     */
    static otherNif2Required(control: FormGroup): ValidationErrors | null {
        return VmdValidators.genericNifRequired(control, 'codePremierAutreNif', 'userNifMissingReason2');

    }

    /**
     * Validate either codeSecondAutreNif or userNifMissingReason3 must be provided
     */
    static otherNif3Required(control: FormGroup): ValidationErrors | null {

        return VmdValidators.genericNifRequired(control, 'codeSecondAutreNif', 'userNifMissingReason3');
    }

    /**
     * Validate if the HomePhone and OtherPhone is matched
     */
    static genericPhoneMatchValidator(control: AbstractControl, labelHome: string, labelOther: string): ValidationErrors | null {
        const homePhone = control.get(labelHome);
        const otherPhone = control.get(labelOther);

        if (homePhone.value && homePhone.value === otherPhone.value) {
            if (!homePhone.hasError('phone')) {
                homePhone.setErrors({phoneMatch: true});
            }
        } else {
            if (homePhone.hasError('phoneMatch')) {
                homePhone.setErrors(null);
            }
        }
        return null;
    }

    static accountHolderGenericPhonesMatchValidator(control: AbstractControl, labelHome: string, labelOther: string, labelMobile: string): ValidationErrors | null {
        const homePhone = control.get(labelHome);
        const otherPhone = control.get(labelOther);
        const mobilePhone = control.get(labelMobile);

        if ((homePhone.value && homePhone.value === otherPhone.value)
            || (homePhone.value && homePhone.value === mobilePhone.value)
            || (otherPhone.value && otherPhone.value === mobilePhone.value)) {
            if (!homePhone.hasError('phone')) {
                homePhone.setErrors({phoneMatch: true});
            }
        } else {
            if (homePhone.hasError('phoneMatch')
                && !(homePhone.hasError('any_required') || homePhone.hasError('phone'))) {
                homePhone.setErrors(null);
            }
        }
        return null;
    }

    /**
     * Validate if the loan starting date is inferior to maturity date and OtherPhone is matched
     */
    static loanDateValidator(control: AbstractControl): ValidationErrors | null {
        const borrowingLoanStartingDate = control.get('borrowingLoanStartingDate');
        const borrowingLoanMaturityDate = control.get('borrowingLoanMaturityDate');

        if (borrowingLoanStartingDate.value && (borrowingLoanMaturityDate.value !== null) && new Date(borrowingLoanStartingDate.value) >= new Date(borrowingLoanMaturityDate.value)) {
            if (!borrowingLoanStartingDate.hasError('dateInf')) {
                borrowingLoanStartingDate.setErrors({dateInf: true});
            }
        } else {
            if (borrowingLoanStartingDate.hasError('dateInf')) {
                borrowingLoanStartingDate.setErrors(null);
            }
        }
        return null;
    }

    static genericPhoneRequired(control: AbstractControl, labelHome: string, labelOther: string): ValidationErrors | null {
        const homePhone = control.get(labelHome);
        const otherPhone = control.get(labelOther);

        if (!homePhone.value && !otherPhone.value) {
            homePhone.setErrors({required: true});
        } else {
            if (homePhone.hasError('required')) {
                homePhone.setErrors(null);
            }
        }
        return null;
    }

    /**
     * Validate if the
     */
    static accountHolderGenericPhonesRequired(control: AbstractControl, labelHome: string, labelOther: string, labelMobile: string): ValidationErrors | null {
        const homePhone = control.get(labelHome);
        const otherPhone = control.get(labelOther);
        const mobilePhone = control.get(labelMobile);

        if (!homePhone.value && !otherPhone.value && !mobilePhone.value) {
            homePhone.setErrors({required: true});
        } else {
            if (homePhone.hasError('required')) {
                homePhone.setErrors(null);
            }
        }
        return null;
    }

    /**
     * Validate if the one of the business number field is setted
     */
    static provincialNumberAndProvinceRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericFieldsDependanceRequired(control, 'provincialBusinessNumber', 'provinceOfBusinessNumber', 'required');
    }

    /**
     * Validate dependance between two field
     */
    static genericFieldsDependanceRequired(control: AbstractControl, field: string, otherField: string, errorCode: string): ValidationErrors | null {
        const fieldToCheck = control.get(field);
        const otherFieldToCheck = control.get(otherField);

        if (fieldToCheck.value || otherFieldToCheck.value) {

            const error: { [key: string]: boolean } = {};
            error[errorCode] = true;

            if (fieldToCheck.value && !otherFieldToCheck.value) {
                otherFieldToCheck.setErrors(error);
            }

            if (!fieldToCheck.value && otherFieldToCheck.value) {
                fieldToCheck.setErrors(error);
            }
        } else {
            if (fieldToCheck.hasError(errorCode)) {

                this.removeError(fieldToCheck, errorCode);
            }

            if (otherFieldToCheck.hasError(errorCode)) {

                this.removeError(otherFieldToCheck, errorCode);
            }
        }

        return null;
    }

    /**
     * Validate if the one of the business number field is setted
     */
    static businessNumberRequired(control: AbstractControl): ValidationErrors | null {
        return VmdValidators.genericFieldRequired(control, 'federalBusinessNumber', 'provincialBusinessNumber', 'required_choice');
    }

    /**
     * Validate if the one of the field is setted
     */
    static genericFieldRequired(control: AbstractControl, field: string, otherField: string, errorCode: string): ValidationErrors | null {
        const fieldToCheck = control.get(field);
        const otherFieldToCheck = control.get(otherField);

        if (!fieldToCheck.value && !otherFieldToCheck.value) {

            const error: { [key: string]: boolean } = {};
            error[errorCode] = true;

            fieldToCheck.setErrors(error);
            otherFieldToCheck.setErrors(error);
        } else {

            if (fieldToCheck.hasError(errorCode)) {

                this.removeError(fieldToCheck, errorCode);
            }

            if (otherFieldToCheck.hasError(errorCode)) {

                this.removeError(otherFieldToCheck, errorCode);
            }
        }
        return null;
    }

    /**
     * Compare personal address and mailing address
     */
    static addressMatchValidator(control: FormGroup): ValidationErrors | null {

        const homeAddressStreet: AbstractControl = control.get('homeAddress.userAddressStreet');
        const mailingAddressStreet: AbstractControl = control.get('mailingAddress.userAddressStreetSec');

        const homeAddressCity: AbstractControl = control.get('homeAddress.userAddressCity');
        const mailingAddressCity: AbstractControl = control.get('mailingAddress.userAddressCitySec');

        const homeAddressPostalCode: AbstractControl = control.get('homeAddress.userAddressPostalCode');
        const mailingAddressPostalCode: AbstractControl = control.get('mailingAddress.userAddressPostalCodeSec');

        const homeAddressUnit: AbstractControl = control.get('homeAddress.userAddressUnit');
        const mailingAddressUnit: AbstractControl = control.get('mailingAddress.userAddressUnitSec');

        const homeAddressProv: AbstractControl = control.get('homeAddress.userAddressProv');
        const mailingAddressProv: AbstractControl = control.get('mailingAddress.userAddressProvSec');

        if (
            (VmdValidators.isValidControlandValue(homeAddressStreet) ||
                VmdValidators.isValidControlandValue(homeAddressCity) ||
                VmdValidators.isValidControlandValue(homeAddressUnit) ||
                VmdValidators.isValidControlandValue(homeAddressPostalCode) ||
                VmdValidators.isValidControlandValue(homeAddressProv)
            ) &&
            (VmdValidators.isValidControlandValue(mailingAddressStreet) ||
                VmdValidators.isValidControlandValue(mailingAddressCity) ||
                VmdValidators.isValidControlandValue(mailingAddressPostalCode) ||
                VmdValidators.isValidControlandValue(mailingAddressUnit)) ||
            VmdValidators.isValidControlandValue(mailingAddressProv)
        ) {
            if (
                VmdValidators.compareAbstractControlValues(homeAddressStreet, mailingAddressStreet) &&
                VmdValidators.compareAbstractControlValues(homeAddressCity, mailingAddressCity) &&
                VmdValidators.compareAbstractControlValues(homeAddressPostalCode, mailingAddressPostalCode) &&
                VmdValidators.compareAbstractControlWithEmptyValues(homeAddressUnit, mailingAddressUnit) &&
                VmdValidators.compareAbstractControlValues(homeAddressProv, mailingAddressProv)
            ) {
                control.get('homeAddress.userAddressStreet').setErrors({addressMatch: true});
                control.get('mailingAddress.userAddressStreetSec').setErrors({addressMatch: true});
            } else {
                if (control.get('homeAddress.userAddressStreet').hasError('addressMatch')) {
                    control.get('homeAddress.userAddressStreet').setErrors(null);
                }
                if (control.get('mailingAddress.userAddressStreetSec').hasError('addressMatch')) {
                    control.get('mailingAddress.userAddressStreetSec').setErrors(null);
                }
            }

        }

        return null;

    }

    static compareAbstractControlValues(control1: AbstractControl, control2: AbstractControl): boolean {

        if (VmdValidators.isValidControlandValue(control1) && VmdValidators.isValidControlandValue(control2)) {
            return (control1.value === control2.value);
        }
        return false;
    }

    static compareAbstractControlWithEmptyValues(control1: AbstractControl, control2: AbstractControl): boolean {
        if (control1.value && control1.value !== '' && control2.value && control2.value !== '') {
            this.compareAbstractControlValues(control1, control2);
        }
        return true;
    }

    static isValidControlandValue(control: AbstractControl): boolean {
        return control && (control.value !== null);
    }

    /**
     * Validate either nif or nifMissingReason must be entered
     */
    static genericNifRequired(control: AbstractControl, labelNif: string, labelNifMissingReason: string): ValidationErrors | null {
        const nif = control.get(labelNif);
        const nifMissingReason = control.get(labelNifMissingReason);

        if (!nif.value && !nifMissingReason.value && nifMissingReason.enabled) {
            nif.setErrors({required: true});
        } else if (nif.hasError('required')) {
            nif.setErrors(null);
        }
        return null;
    }

    static citizenshipValidator(control: FormGroup): ValidationErrors | null {
        const userCitizenship = control.get('userCitizenship');
        const userCitizenshipUS = control.get('userCitizenshipUS');
        const addUserOtherCitizenship = control.get('addUserOtherCitizenship');

        if (!userCitizenship.value && !userCitizenshipUS.value && !addUserOtherCitizenship.value) {
            userCitizenship.setErrors({required: true});
        } else if (userCitizenship.hasError('required')) {
            userCitizenship.setErrors(null);
        } else if (userCitizenshipUS.hasError('required')) {
            userCitizenshipUS.setErrors(null);
        }
        return null;
    }

    static checkBorrowingReceivedAndReadBorrowingRisksIndicator(control: FormGroup): ValidationErrors | null {
        const borrowingReceivedAndReadBorrowingRisksIndicator = control.get('borrowingReceivedAndReadBorrowingRisksIndicator');

        if (!borrowingReceivedAndReadBorrowingRisksIndicator.value) {
            borrowingReceivedAndReadBorrowingRisksIndicator.setErrors({required: true});
        } else if (borrowingReceivedAndReadBorrowingRisksIndicator.hasError('required')) {
            borrowingReceivedAndReadBorrowingRisksIndicator.setErrors(null);
        }
        return null;
    }

    static fiscalValidator(control: FormGroup): ValidationErrors | null {
        const userFiscalRelationCanada = control.get('userFiscalRelationCanada');
        const userFiscalRelationUsa = control.get('userFiscalRelationUsa');
        const userFiscalRelationOther = control.get('userFiscalRelationOther');

        if (!userFiscalRelationCanada.value && !userFiscalRelationUsa.value && !userFiscalRelationOther.value) {
            userFiscalRelationCanada.setErrors({required: true});
        } else if (userFiscalRelationCanada.hasError('required')) {
            userFiscalRelationCanada.setErrors(null);
        }
        return null;
    }

    static optionActTradingPlannedGroupValidator(control: FormGroup): ValidationErrors | null {
        if (!control.get('optionActTradingPlannedOperationLevel1').value &&
            !control.get('optionActTradingPlannedOperationLevel2').value &&
            !control.get('optionActTradingPlannedOperationLevel3').value &&
            !control.get('optionActTradingPlannedOperationLevel4').value) {

            control.get('optionActTradingPlannedOperationLevel1').setErrors({required: true});

        } else if (control.get('optionActTradingPlannedOperationLevel1').hasError('required')) {
            control.get('optionActTradingPlannedOperationLevel1').setErrors(null);
        }
        return null;
    }

    static optionActTradingExperienceGroupValidator(control: FormGroup): ValidationErrors | null {
        if (!control.get('optionActTradingExperienceOperationLevel1').value &&
            !control.get('optionActTradingExperienceOperationLevel2').value &&
            !control.get('optionActTradingExperienceOperationLevel3').value &&
            !control.get('optionActTradingExperienceOperationLevel4').value) {

            control.get('optionActTradingExperienceOperationLevel1').setErrors({required: true});
        } else if (control.get('optionActTradingExperienceOperationLevel1').hasError('required')) {
            control.get('optionActTradingExperienceOperationLevel1').setErrors(null);
        }
        return null;
    }

    static transferRequestChoiceGroupValidator(isCeliAppActive: boolean): ValidatorFn | null {
        return (control: FormGroup): ValidationErrors | null => {
            let atLeastOneSelected = false;
            const transfertRequestTypesList = isCeliAppActive ? VmdConstants.TRANSFER_REQUEST_TYPES : VmdConstants.TRANSFER_REQUEST_TYPES_TOGGLE_CELIAPP_OFF;
            for (const item of transfertRequestTypesList) {
                if (control.get(item) && control.get(item).value) {
                    atLeastOneSelected = true;
                }
            }
            if (!atLeastOneSelected) {
                if (control.get('transfer')) {
                    control.get('transfer').setErrors({required: true});
                }

            } else if (control.get('transfer').hasError('required')) {
                if (control.get('transfer')) {
                    control.get('transfer').setErrors(null);
                }
            }
            return null;
        };
    }

    static jointTransferRequestChoiceGroupValidator(transferTypes: string[]): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            let atLeastOneSelected = false;
            for (const item of transferTypes) {
                if (control.get(item) && control.get(item).value) {
                    atLeastOneSelected = true;
                }
            }
            if (!atLeastOneSelected) {
                if (control.get(transferTypes[0])) {
                    control.get(transferTypes[0]).setErrors({required: true});
                }

            } else if (control.get(transferTypes[0]).hasError('required')) {
                if (control.get(transferTypes[0])) {
                    control.get(transferTypes[0]).setErrors(null);
                }
            }
            return null;
        };
    }

    static otherTransferRequestChoiceGroupValidator(control: FormGroup): ValidationErrors | null {
        let atLeastOneSelected = false;
        for (const item of VmdConstants.OTHER_TRANSFER_REQUEST_TYPES) {
            if (control.get(item) && control.get(item).value) {
                atLeastOneSelected = true;
            }
        }
        if (!atLeastOneSelected) {
            if (control.get('otherTransfer')) {
                control.get('otherTransfer').setErrors({required: true});
            }

        } else if (control.get('otherTransfer').hasError('required')) {
            if (control.get('otherTransfer')) {
                control.get('otherTransfer').setErrors(null);
            }
        }
        return null;
    }

    static securityValidator(control: FormGroup): ValidationErrors | null {
        const description = control.get('description');
        const symbol = control.get('symbol');
        const quantity = control.get('quantity');
        const type = control.get('type');
        if (description.value || symbol.value || quantity.value) {
            if (!description.value) {
                description.setErrors({required: true});
            }
            if (!quantity.value) {
                quantity.setErrors({required: true});
            }
            if (!type.value) {
                type.setErrors({required: true});
            }
        } else {
            if (description.hasError('required')) {
                description.setErrors(null);
            }
            if (quantity.hasError('required')) {
                quantity.setErrors(null);
            }
            if (type.hasError('required')) {
                type.setErrors(null);
            }
        }

        return null;
    }

    static fileFormatValidator(supportedFormats: string[]): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            let valid = false;
            if (supportedFormats.length) {
                for (const item of supportedFormats) {
                    if (control.value.split('.').pop().toLowerCase() === item) {
                        valid = true;
                    }
                }
            }
            return valid ? null : {invalidFormat: true};
        };
    }

    static passwordMatchValidator(): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            const /** @type {?} */ newPasswordControl = control.get('newPassword');
            const /** @type {?} */ confirmNewPasswordControl = control.get('confirmNewPassword');
            const /** @type {?} */ match = newPasswordControl.value === confirmNewPasswordControl.value;

            let emptyValues = false;

            if (newPasswordControl.value === null || newPasswordControl.value.length === 0) {
                emptyValues = true; // don't validate empty values to allow optional controls
            }
            if (confirmNewPasswordControl.value === null || confirmNewPasswordControl.value.length === 0) {
                emptyValues = true; // don't validate empty values to allow optional controls
            }

            if (!emptyValues && !match) {
                if (!confirmNewPasswordControl.hasError('passwordMatch')) {
                    confirmNewPasswordControl.setErrors({passwordMatch: true});
                }
            } else {
                if (confirmNewPasswordControl.hasError('passwordMatch')) {
                    confirmNewPasswordControl.setErrors(null);
                }
            }

            return null;
        };
    }

    static countryUsaCitizenshipValidator(control: FormGroup): ValidationErrors | null {
        const userCitizenship = control.get('citizenshipGroup.userCitizenship');
        const userCitizenshipUS = control.get('citizenshipGroup.userCitizenshipUS');
        const userOtherCitizenship = control.get('citizenshipGroup.userOtherCitizenship');
        const userFiscalRelationCanada = control.get('fiscalGroup.userFiscalRelationCanada');
        const userFiscalRelationUsa = control.get('fiscalGroup.userFiscalRelationUsa');

        if (userFiscalRelationCanada.value && userFiscalRelationUsa.value && userCitizenshipUS.value !== true) {
            VmdValidators.addError(userCitizenship, 'usaRequired', true);
        } else if (userCitizenshipUS.hasError('usaRequired') || userCitizenship.hasError('usaRequired')) {
            VmdValidators.removeError(userCitizenshipUS, 'usaRequired');
            VmdValidators.removeError(userCitizenship, 'usaRequired');
            VmdValidators.removeError(userOtherCitizenship, 'usaRequired');
        }

        return null;
    }

    static onlyOneAccountTypeInUSD(isPleinEx: boolean): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            if (isPleinEx) {
                const entries = [
                    [
                        // RRSP
                        {add: 'addRRSP', currency: 'rrspAccount.rrspCurrency'},
                        {add: 'addRRSPSec', currency: 'rrspAccountSec.rrspCurrencySec'},
                        {add: 'addRRSPTer', currency: 'rrspAccountTer.rrspCurrencyTer'}
                    ], [
                        // LIRA
                        {add: 'addLIRA', currency: 'liraAccount.liraCurrency'},
                        {add: 'addLIRASec', currency: 'liraAccountSec.liraCurrencySec'}
                    ], [
                        // RRIF
                        {add: 'addRRIF', currency: 'rrifAccount.rrifCurrency'},
                        {add: 'addRRIFSec', currency: 'rrifAccountSec.rrifCurrencySec'}
                    ]
                ];

                for (const entry of entries) {
                    const usdControlList = [];

                    for (const item of entry) {
                        const addControl = control.get(item.add);
                        const currencyControl = control.get(item.currency);

                        // Reset validation error
                        VmdValidators.removeError(currencyControl, 'onlyOneAccountTypeInUSD');

                        if (addControl.value && VmdConstants.CURRENCY_USD === currencyControl.value) {
                            usdControlList.push(currencyControl);
                        }
                    }

                    if (usdControlList.length > 1) {
                        // If more than one USD currency add validation error
                        for (const usdControl of usdControlList) {
                            VmdValidators.addError(usdControl, 'onlyOneAccountTypeInUSD', true);
                        }
                    }
                }
            }

            return null;
        };
    }

    static notSameJuridiction(isPleinEx: boolean): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            if (isPleinEx) {
                const entries = [
                    [
                        // LIRA
                        {add: 'addLIRA', jurisdiction: 'liraAccount.liraJurisdiction'},
                        {add: 'addLIRASec', jurisdiction: 'liraAccountSec.liraJurisdictionSec'}
                    ]
                ];

                for (const entry of entries) {
                    const juridictionMap = {};

                    for (const item of entry) {
                        const addControl = control.get(item.add);
                        const jurisdictionControl = control.get(item.jurisdiction);

                        // Reset validation error
                        VmdValidators.removeError(jurisdictionControl, 'notSameJuridiction');

                        if (addControl.value && jurisdictionControl.value) {
                            if (-1 === Object.keys(juridictionMap).indexOf(jurisdictionControl.value)) {
                                juridictionMap[jurisdictionControl.value] = jurisdictionControl;
                            } else {
                                VmdValidators.addError(juridictionMap[jurisdictionControl.value], 'notSameJuridiction', true);
                                VmdValidators.addError(jurisdictionControl, 'notSameJuridiction', true);
                            }
                        }
                    }
                }
            }

            return null;
        };
    }

    static maxTwoSameDesignationOfBeneficiary(isPleinEx: boolean): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            if (isPleinEx) {
                const addTFARRSPControl = control.get('addFTARRSP');
                const addTFARRSPSecControl = control.get('addFTARRSPSec');
                const addTFARRSPTerControl = control.get('addFTARRSPTer');

                const ftarrspRevocableBeneficiaryControl = control.get('ftarrspAccount.ftarrspRevocableBeneficiaryIndicator');
                const ftarrspRevocableBeneficiarySecControl = control.get('ftarrspAccountSec.ftarrspRevocableBeneficiaryIndicatorSec');
                const ftarrspRevocableBeneficiaryTerControl = control.get('ftarrspAccountTer.ftarrspRevocableBeneficiaryIndicatorTer');

                VmdValidators.removeError(ftarrspRevocableBeneficiaryControl, 'maxTwoSameDesignationOfBeneficiary');
                VmdValidators.removeError(ftarrspRevocableBeneficiarySecControl, 'maxTwoSameDesignationOfBeneficiary');
                VmdValidators.removeError(ftarrspRevocableBeneficiaryTerControl, 'maxTwoSameDesignationOfBeneficiary');

                const allTFARRSP = (addTFARRSPControl.value && addTFARRSPSecControl.value && addTFARRSPTerControl.value);
                const allSameNotNullValue = ftarrspRevocableBeneficiaryControl.value !== null
                    && ftarrspRevocableBeneficiaryControl.value === ftarrspRevocableBeneficiarySecControl.value
                    && ftarrspRevocableBeneficiarySecControl.value === ftarrspRevocableBeneficiaryTerControl.value;

                if (allTFARRSP && allSameNotNullValue) {
                    VmdValidators.addError(ftarrspRevocableBeneficiaryControl, 'maxTwoSameDesignationOfBeneficiary', true);
                    VmdValidators.addError(ftarrspRevocableBeneficiarySecControl, 'maxTwoSameDesignationOfBeneficiary', true);
                    VmdValidators.addError(ftarrspRevocableBeneficiaryTerControl, 'maxTwoSameDesignationOfBeneficiary', true);
                }
            }

            return null;
        };
    }

    static invalidMgntType(isPleinEx: boolean, isPrintMode: boolean): ValidatorFn | null {
        return (control: FormControl): ValidationErrors | null => {

            const actTypeIsCash = control.get('actType').value === VmdConstants.ACCOUNT_TYPE_CASH;

            if (isPleinEx && !isPrintMode && actTypeIsCash) {

                const cashAccountMgntTypeCtrl = control.get('cashAccountMgntType');
                const typeMgntIsCom = cashAccountMgntTypeCtrl.value === VmdConstants.MGNT_TYPE_COM;

                const addTfsa = control.get('addTFSA');
                const tfsaMgntType = control.get('tfsaAccount.tfsaAccountMgntType');
                const tfsaMgntTypeIsCom = tfsaMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addFhsa = control.get('addFHSA');
                const fhsaMgntType = control.get('fhsaAccount.fhsaAccountMgntType');
                const fhsaMgntTypeIsCom = fhsaMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addRrsp = control.get('addRRSP');
                const rrspMgntType = control.get('rrspAccount.rrspAccountMgntType');
                const rrspMgntTypeIsCom = rrspMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addRrspSec = control.get('addRRSPSec');
                const rrspMgntTypeSec = control.get('rrspAccountSec.rrspAccountMgntTypeSec');
                const rrspMgntTypeIsComSec = rrspMgntTypeSec.value === VmdConstants.MGNT_TYPE_COM;

                const addRrspTer = control.get('addRRSPTer');
                const rrspMgntTypeTer = control.get('rrspAccountTer.rrspAccountMgntTypeTer');
                const rrspMgntTypeIsComTer = rrspMgntTypeTer.value === VmdConstants.MGNT_TYPE_COM;

                const addLIRA = control.get('addLIRA');
                const liraMgntType = control.get('liraAccount.liraAccountMgntType');
                const liraMgntTypeIsCom = liraMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addLIRASec = control.get('addLIRASec');
                const liraMgntTypeSec = control.get('liraAccountSec.liraAccountMgntTypeSec');
                const liraMgntTypeIsComSec = liraMgntTypeSec.value === VmdConstants.MGNT_TYPE_COM;

                const addRRIF = control.get('addRRIF');
                const rrifMgntType = control.get('rrifAccount.rrifAccountMgntType');
                const rrifMgntTypeIsCom = rrifMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addRRIFSec = control.get('addRRIFSec');
                const rrifMgntTypeSec = control.get('rrifAccountSec.rrifAccountMgntTypeSec');
                const rrifMgntTypeIsComSec = rrifMgntTypeSec.value === VmdConstants.MGNT_TYPE_COM;

                const addLIF = control.get('addLIF');
                const lifMgntType = control.get('lifAccount.lifAccountMgntType');
                const lifMgntTypeIsCom = lifMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addResp = control.get('addRESP');
                const respMgntType = control.get('respAccount.respAccountMgntType');
                const respMgntTypeIsCom = respMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addFTARRSP = control.get('addFTARRSP');
                const ftarrspMgntType = control.get('ftarrspAccount.ftarrspAccountMgntType');
                const ftarrspMgntTypeIsCom = ftarrspMgntType.value === VmdConstants.MGNT_TYPE_COM;

                const addFTARRSPSec = control.get('addFTARRSPSec');
                const ftarrspMgntTypeSec = control.get('ftarrspAccountSec.ftarrspAccountMgntTypeSec');
                const ftarrspMgntTypeIsComSec = ftarrspMgntTypeSec.value === VmdConstants.MGNT_TYPE_COM;

                const addFTARRSPTer = control.get('addFTARRSPTer');
                const ftarrspMgntTypeTer = control.get('ftarrspAccountTer.ftarrspAccountMgntTypeTer');
                const ftarrspMgntTypeIsComTer = ftarrspMgntTypeTer.value === VmdConstants.MGNT_TYPE_COM;

                VmdValidators.removeError(cashAccountMgntTypeCtrl, 'invalidMgntType');
                VmdValidators.removeError(tfsaMgntType, 'invalidMgntType');
                VmdValidators.removeError(fhsaMgntType, 'invalidMgntType');
                VmdValidators.removeError(rrspMgntType, 'invalidMgntType');
                VmdValidators.removeError(rrspMgntTypeSec, 'invalidMgntType');
                VmdValidators.removeError(rrspMgntTypeTer, 'invalidMgntType');
                VmdValidators.removeError(liraMgntType, 'invalidMgntType');
                VmdValidators.removeError(liraMgntTypeSec, 'invalidMgntType');
                VmdValidators.removeError(rrifMgntType, 'invalidMgntType');
                VmdValidators.removeError(rrifMgntTypeSec, 'invalidMgntType');
                VmdValidators.removeError(lifMgntType, 'invalidMgntType');
                VmdValidators.removeError(respMgntType, 'invalidMgntType');
                VmdValidators.removeError(ftarrspMgntType, 'invalidMgntType');
                VmdValidators.removeError(ftarrspMgntTypeSec, 'invalidMgntType');
                VmdValidators.removeError(ftarrspMgntTypeTer, 'invalidMgntType');

                let isError = false;

                if (
                    (addTfsa.value && tfsaMgntTypeIsCom !== typeMgntIsCom) ||
                    (addFhsa.value && fhsaMgntTypeIsCom !== typeMgntIsCom) ||
                    (addRrsp.value && rrspMgntTypeIsCom !== typeMgntIsCom) ||
                    (addRrspSec.value && rrspMgntTypeIsComSec !== typeMgntIsCom) ||
                    (addRrspTer.value && rrspMgntTypeIsComTer !== typeMgntIsCom) ||
                    (addLIRA.value && liraMgntTypeIsCom !== typeMgntIsCom) ||
                    (addLIRASec.value && liraMgntTypeIsComSec !== typeMgntIsCom) ||
                    (addRRIF.value && rrifMgntTypeIsCom !== typeMgntIsCom) ||
                    (addRRIFSec.value && rrifMgntTypeIsComSec !== typeMgntIsCom) ||
                    (addLIF.value && lifMgntTypeIsCom !== typeMgntIsCom) ||
                    (addResp.value && respMgntTypeIsCom !== typeMgntIsCom) ||
                    (addFTARRSP.value && ftarrspMgntTypeIsCom !== typeMgntIsCom) ||
                    (addFTARRSPSec.value && ftarrspMgntTypeIsComSec !== typeMgntIsCom) ||
                    (addFTARRSPTer.value && ftarrspMgntTypeIsComTer !== typeMgntIsCom)) {

                    isError = true;
                }

                // Error Messages
                if (isError && actTypeIsCash) {
                    VmdValidators.addError(cashAccountMgntTypeCtrl, 'invalidMgntType', true);
                }
                if (isError && addTfsa.value) {
                    VmdValidators.addError(tfsaMgntType, 'invalidMgntType', true);
                }
                if (isError && addFhsa.value) {
                    VmdValidators.addError(fhsaMgntType, 'invalidMgntType', true);
                }
                if (isError && addRrsp.value) {
                    VmdValidators.addError(rrspMgntType, 'invalidMgntType', true);
                }
                if (isError && addRrspSec.value) {
                    VmdValidators.addError(rrspMgntTypeSec, 'invalidMgntType', true);
                }
                if (isError && addRrspTer.value) {
                    VmdValidators.addError(rrspMgntTypeTer, 'invalidMgntType', true);
                }
                if (isError && addLIRA.value) {
                    VmdValidators.addError(liraMgntType, 'invalidMgntType', true);
                }
                if (isError && addLIRASec.value) {
                    VmdValidators.addError(liraMgntTypeSec, 'invalidMgntType', true);
                }
                if (isError && addRRIF.value) {
                    VmdValidators.addError(rrifMgntType, 'invalidMgntType', true);
                }
                if (isError && addRRIFSec.value) {
                    VmdValidators.addError(rrifMgntTypeSec, 'invalidMgntType', true);
                }
                if (isError && addLIF.value) {
                    VmdValidators.addError(lifMgntType, 'invalidMgntType', true);
                }
                if (isError && addResp.value) {
                    VmdValidators.addError(respMgntType, 'invalidMgntType', true);
                }
                if (isError && addFTARRSP.value) {
                    VmdValidators.addError(ftarrspMgntType, 'invalidMgntType', true);
                }
                if (isError && addFTARRSPSec.value) {
                    VmdValidators.addError(ftarrspMgntTypeSec, 'invalidMgntType', true);
                }
                if (isError && addFTARRSPTer.value) {
                    VmdValidators.addError(ftarrspMgntTypeTer, 'invalidMgntType', true);
                }
            }

            return null;
        };
    }

    static contributingSpouseCannotBeCurrentSpouse(isPleinEx: boolean): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            if (isPleinEx) {
                const userMaritalStatus = control.get('userMaritalStatus').value;
                const noSpouse = (userMaritalStatus === 'SINGLE' || userMaritalStatus === 'DIVORCED' || userMaritalStatus === 'SEPARATED' || userMaritalStatus === 'WIDOWED');

                const paths = [
                    'rrspContributingSpouseIsCurrentSpouse',
                    'rrspContributingSpouseIsCurrentSpouseSec',
                    'rrspContributingSpouseIsCurrentSpouseTer',
                    'rrifContributingSpouseIsCurrentSpouse',
                    'rrifContributingSpouseIsCurrentSpouseSec',
                    'ftarrspContributingSpouseIsCurrentSpouse',
                    'ftarrspContributingSpouseIsCurrentSpouseSec',
                    'ftarrspContributingSpouseIsCurrentSpouseTer'
                ];

                for (const path of paths) {
                    const contributingSpouseControl = control.get(path);
                    VmdValidators.removeError(contributingSpouseControl, 'contributingSpouseCannotBeCurrentSpouse');
                    if (contributingSpouseControl && contributingSpouseControl.value && noSpouse) {
                        VmdValidators.addError(contributingSpouseControl, 'contributingSpouseCannotBeCurrentSpouse', true);
                    }
                }
            }

            return null;
        };
    }

    static multipleContributingSpousesValidation(isPleinEx: boolean, isPrintMode: boolean): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            if (isPleinEx && !isPrintMode) {

                const paths = [
                    'rrspContributingSpouseIsCurrentSpouse',
                    'rrspContributingSpouseIsCurrentSpouseSec',
                    'rrspContributingSpouseIsCurrentSpouseTer',
                    'rrifContributingSpouseIsCurrentSpouse',
                    'rrifContributingSpouseIsCurrentSpouseSec',
                    'ftarrspContributingSpouseIsCurrentSpouse',
                    'ftarrspContributingSpouseIsCurrentSpouseSec',
                    'ftarrspContributingSpouseIsCurrentSpouseTer'
                ];

                let multipleConditionBroken = false;
                let previousCondition = false;
                let firstTime = true;

                const userMaritalStatus = control.get('userMaritalStatus').value;
                const withSpouse = (userMaritalStatus === 'LIVING_COMMON_LAW' || userMaritalStatus === 'MARRIED');

                if (withSpouse) {

                    for (const path of paths) {
                        const contributingSpouseControl = control.get(path);
                        if ((null !== contributingSpouseControl) && contributingSpouseControl.enabled) {
                            if (firstTime && (null !== contributingSpouseControl.value)) {
                                firstTime = false;
                                previousCondition = contributingSpouseControl.value;
                            } else {
                                if ((null !== contributingSpouseControl.value) && (previousCondition !== contributingSpouseControl.value)) {
                                    multipleConditionBroken = true;
                                }

                            }

                        }

                    }

                }

                for (const path of paths) {
                    const contributingSpouseControl = control.get(path);
                    VmdValidators.removeError(contributingSpouseControl, 'multiple');
                    if (multipleConditionBroken && ((null !== contributingSpouseControl) && contributingSpouseControl.enabled)) {
                        VmdValidators.addError(contributingSpouseControl, 'multiple', true);
                    }

                }

            }

            return null;
        };
    }

    static investmentObjectives(isPrintMode: boolean): ValidatorFn | null {

        return (control: FormControl): ValidationErrors | null => {

            const error = 'investmentObjectives';

            const controlNonReg1 = control.get('userInvestmentObjectivesLowRiskNonReg');
            const controlNonReg2 = control.get('userInvestmentObjectivesModerateRiskNonReg');
            const controlNonReg3 = control.get('userInvestmentObjectivesModHighRiskNonReg');
            const controlNonReg4 = control.get('userInvestmentObjectivesSpeculativeStrategiesNonReg');

            const controlReg1 = control.get('registered.userInvestmentObjectivesLowRiskReg');
            const controlReg2 = control.get('registered.userInvestmentObjectivesModerateRiskReg');
            const controlReg3 = control.get('registered.userInvestmentObjectivesModHighRiskReg');
            const controlReg4 = control.get('registered.userInvestmentObjectivesSpeculativeStrategiesReg');

            VmdValidators.removeError(controlNonReg1, error);
            VmdValidators.removeError(controlNonReg2, error);
            VmdValidators.removeError(controlNonReg3, error);
            VmdValidators.removeError(controlNonReg4, error);
            VmdValidators.removeError(controlReg1, error);
            VmdValidators.removeError(controlReg2, error);
            VmdValidators.removeError(controlReg3, error);
            VmdValidators.removeError(controlReg4, error);

            const sumNonReg = controlNonReg1.value + controlNonReg2.value + controlNonReg3.value + controlNonReg4.value;
            const sumReg = controlReg1.value + controlReg2.value + controlReg3.value + controlReg4.value;

            if (!isPrintMode) {

                if (sumNonReg !== 100) {
                    VmdValidators.addError(controlNonReg1, error, true);
                    VmdValidators.addError(controlNonReg2, error, true);
                    VmdValidators.addError(controlNonReg3, error, true);
                    VmdValidators.addError(controlNonReg4, error, true);
                }

                if (control.get('registered').enabled && sumReg !== 100) {
                    VmdValidators.addError(controlReg1, error, true);
                    VmdValidators.addError(controlReg2, error, true);
                    VmdValidators.addError(controlReg3, error, true);
                    VmdValidators.addError(controlReg4, error, true);
                }
            }

            return null;
        };
    }

    static attachedDocumentUnique(): ValidatorFn | null {

        return (formArray: FormArray): ValidationErrors | null => {

            const error = 'attachedDocumentUnique';

            const types = [];

            for (const control of formArray.controls) {

                const controlName = control.get('attachedDocumentName');

                VmdValidators.removeError(controlName, error);

                if (null !== controlName.value) {
                    if (types.indexOf(controlName.value) === -1) {
                        types.push(controlName.value);
                    } else {
                        VmdValidators.addError(controlName, error, true);
                    }
                }
            }

            return null;
        };

    }

    static validMultipleHoldersProvince(requesters: any, isPrintMode: boolean, indexRequester: number, isJointAccount: boolean): ValidatorFn {
        return (control: FormGroup): ValidationErrors | null => {
            if (!isJointAccount) {
                return null;
            }
            const province = control.get('addressGroup.homeAddress.userAddressProv');
            const isSameAddressAsFirstIndicator = control.get('isSameAddressAsFirstIndicator');

            VmdValidators.removeError(province, 'provincesnotequals');
            if (VmdValidators.isSameProvinceSelected(province, requesters, indexRequester)
                && VmdValidators.isNotSameAddressSelected(isSameAddressAsFirstIndicator)
                && !isPrintMode) {

                VmdValidators.addError(province, 'provincesnotequals', true);
                return {provincesnotequals: true};
            } else {
                return null;
            }
        };
    }

    static isSameProvinceSelected(province: AbstractControl, requesters: any, indexRequester: number): boolean {
        return (!!province && province.value !== requesters[0].userAddressProv && province.value !== null && indexRequester > 0);
    }

    static isNotSameAddressSelected(isSameAddressAsFirstIndicator: AbstractControl): boolean {
        return (!!isSameAddressAsFirstIndicator && !isSameAddressAsFirstIndicator.value);
    }

    private static dateOfBirthAgeRange(control: AbstractControl, minAge: number, maxAge: number, validationError: ValidationErrors): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            const date = VmdValidators.getDate(control.value);
            if (date === null) {
                return null;
            } else {

                const olderDOB = moment().subtract(maxAge, 'y');
                const youngerDOB = moment().subtract(minAge, 'y');

                isValid = olderDOB.isSameOrBefore(date, 'day') && youngerDOB.isSameOrAfter(date, 'day');
            }
        }

        return isValid ? null : validationError;
    }

    private static getDate(value: any) {
        let date = moment(value, VmdConstants.DATE_FORMAT_INPUT, true);
        if (!date.isValid()) {
            date = moment(value, VmdConstants.DATE_FORMAT_INPUT_ALT, true);
            if (!date.isValid()) {
                return null;
            }
        }
        return date;
    }

    private static regexpValidator(control: AbstractControl, regexp: RegExp, validationError: ValidationErrors): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            isValid = regexp.test(control.value);
        }
        return isValid ? null : validationError;
    }

    private static regexpValidatorAcceptSpace(control: AbstractControl, regexp: RegExp, validationError: ValidationErrors): ValidationErrors | null {
        let isValid = true;
        if (!!control.value) {
            isValid = regexp.test(control.value.replace(/ /g, ''));
        }
        return isValid ? null : validationError;
    }

}
