<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <div *ngIf="isNotConjoint() || isConjointPleinEx()" class="m-b-5">

        <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

        <h2>{{ 'ACCOUNT_CHOICE_TITLE' | translate }}</h2>

        <fieldset class="form-group">
            <legend class="sr-only"><b>{{ 'ACCOUNT_CHOICE_TITLE' | translate }}</b></legend>
            <div [ngClass]="{'panel panel-default panel-noborder' : !isGpdOrSfd()}" id="panelDefault">
                <div [ngClass]="{'panel-body' : !isGpdOrSfd()}">
                    <!--new account/add account-->
                    <ng-container *ngIf="!isRedocumented()">
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form"
                                [submitted]="isFormSubmitted()"
                                [label]="'REQUEST_TYPE' | translate"
                                [choices]="[{label: 'NEW_ACCOUNT' | translate, value: constants.ACCOUNT_NEW_CLIENT}, {label: 'ADD_ACCOUNT' | translate, value: constants.ACCOUNT_ADD_ACCOUNT, templateRef: cltNo}]"
                                [id]="'newExistAct'"
                                [name]="'newExistAct'"
                                [path]="'newExistAct'"
                                [binding]="'newExistAct'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['pattern']"
                        ></app-odc-field>
                    </ng-container>
                    <ng-template #cltNo>
                        <div *ngIf="form.get('newExistAct').value === constants.ACCOUNT_ADD_ACCOUNT && !isPleinEx()"
                             class="m-l-4">

                            <!--client number-->
                            <app-odc-field
                                    [type]="constants.FIELD_TYPES.TEXT"
                                    [form]="form.get('addAccount')"
                                    [submitted]="isFormSubmitted()"
                                    [label]="'CLIENT_NUMBER' | translate"
                                    [id]="'cltNo'"
                                    [name]="'cltNo'"
                                    [path]="'cltNo'"
                                    [binding]="'cltNo'"
                                    [attr]="{maxlength: 5}"
                            ></app-odc-field>
                        </div>
                    </ng-template>

                    <!--cash account/margin account-->
                    <div class="m-t-3">
                        <div id="account-types">
                            <app-odc-field
                                    *ngIf="((isNotOther() && !isGpdOrSfd())
                                            || (!isNotOther() && isPleinEx() && showMarginAccountForOtherClientType()))
                                            && !(isGpdOrSfd() && isPleinEx() && !isNotOther())"
                                    [type]="constants.FIELD_TYPES.RADIO"
                                    [form]="form"
                                    [submitted]="isFormSubmitted()"
                                    [label]="'ACCOUNT_TYPE' | translate"
                                    [choices]="[{label: 'CASH_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_CASH, helpRefFr: helpCashAccount, helpRefEn: helpCashAccount},
                                            {label: 'MARGIN_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_MARGIN, hidden: !showMarginAccount(), helpRefFr: helpMarginAccount, helpRefEn: helpMarginAccount}]"
                                    [id]="'actType'"
                                    [name]="'actType'"
                                    [path]="'actType'"
                                    [binding]="'actType'"
                                    [stacked]="true"
                            ></app-odc-field>

                            <app-odc-field
                                    *ngIf="(isNotOther() && (isGpdOrSfd()) && !showMarginAccount())
                                            || (!isNotOther() && isPleinEx() && !showMarginAccountForOtherClientType())
                                            || (isGpdOrSfd() && isPleinEx() && !isNotOther())"
                                    [type]="constants.FIELD_TYPES.RADIO"
                                    [form]="form"
                                    [submitted]="isFormSubmitted()"
                                    [label]="'ACCOUNT_TYPE' | translate"
                                    [choices]="[{label: 'CASH_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_CASH, helpRefFr: helpCashAccount, helpRefEn: helpCashAccount}]"
                                    [id]="'actType'"
                                    [name]="'actType'"
                                    [path]="'actType'"
                                    [binding]="'actType'"
                                    [stacked]="true"
                            ></app-odc-field>
                        </div>
                        <div *ngIf="showMarginAccount() && form.get('actType').value === constants.ACCOUNT_TYPE_MARGIN"
                             class="m-l-4">

                            <!--optionAct/shortSellAct-->
                            <app-odc-field
                                    [type]="constants.FIELD_TYPES.CHECKBOX"
                                    [form]="form.get('marginAccount')"
                                    [submitted]="isFormSubmitted()"
                                    [label]="'ACCOUNT_TYPE' | translate"
                                    [choices]="[
                                    {label: 'WITH_OPTIONS' | translate, id: 'optionAct', helpRefFr: helpMarginAccountWithOptions, helpRefEn: helpMarginAccountWithOptions},
                                    {label: 'WITH_SHORT_SELLING' | translate, id: 'shortSellAct', hidden: showMarginAccountWithShortSelling(), helpRefFr: helpMarginAccountWithShortSelling, helpRefEn: helpMarginAccountWithShortSelling}]"
                                    [stacked]="true"
                            ></app-odc-field>
                        </div>
                        <div
                                *ngIf="showManagementTypeChoices()"
                                class="m-1-4">
                            <app-odc-field
                                    [type]="constants.FIELD_TYPES.RADIO"
                                    [form]="form"
                                    [submitted]="isFormSubmitted()"
                                    [label]="'MGNT_TYPE' | translate"
                                    [choices]="[
                                {
                                    label: 'MGNT_TYPE_COM' | translate,
                                    value: constants.MGNT_TYPE_COM,
                                    hidden: isGpdOrSfd()
                                },
                                {
                                    label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                    value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                }]"
                                    [id]="'cashAccountMgntType'"
                                    [name]="'cashAccountMgntType'"
                                    [path]="'cashAccountMgntType'"
                                    [binding]="'cashAccountMgntType'"
                                    [stacked]="true"
                                    [errorOnSubmitOnly]="['invalidMgntType']"
                            ></app-odc-field>
                        </div>
                    </div>

                    <div class="m-t-3">
                        <!--currency-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form"
                                [submitted]="isFormSubmitted()"
                                [label]="'ACCOUNT_CURRENCY' | translate"
                                [choices]="[{label: 'CA_ONLY' | translate, value: constants.CURRENCY_CAD}, {label: 'US_AND_CA' | translate, value: constants.CURRENCY_USD}]"
                                [id]="'currency'"
                                [name]="'currency'"
                                [path]="'currency'"
                                [binding]="'currency'"
                                [stacked]="true"
                        ></app-odc-field>
                    </div>

                    <div class="m-t-3" *ngIf="isSfd()">

                        <h3>
                            {{ 'ACCOUNT_CHOICE_FIDUCIARY_TRANSACTION_ACCOUNTS_TITLE' | translate }}
                            <app-help [templateRefEn]="helpFiduciaryTranactionAccountsEn"
                                      [templateRefFr]="helpFiduciaryTranactionAccountsFr"></app-help>
                        </h3>

                        <div class="row align-items-baseline" formGroupName="fiduciaryTransactionAccountsCad">
                            <div class="col-md-10">
                                <app-odc-field
                                        [type]="constants.FIELD_TYPES.TEXT"
                                        [form]="form.get('fiduciaryTransactionAccountsCad')"
                                        [submitted]="isFormSubmitted()"
                                        [label]="isCad() ? ('SFD_ACCOUNT_NUMBER' | translate) : ('SFD_ACCOUNT_NUMBER_CAD' | translate)"
                                        [id]="'accountNumberCad'"
                                        [name]="'accountNumberCad'"
                                        [path]="'accountNumberCad'"
                                        [binding]="'accountNumberCad'"
                                        [numericFormat]="true"
                                        [attr]="{maxlength: 7}"
                                ></app-odc-field>
                            </div>
                            <div class="col-md-6">
                                <app-odc-field
                                        [type]="constants.FIELD_TYPES.TEXT"
                                        [form]="form.get('fiduciaryTransactionAccountsCad')"
                                        [submitted]="isFormSubmitted()"
                                        [label]="'SFD_PRODUCT_NUMBER' | translate"
                                        [id]="'productNumberCad'"
                                        [name]="'productNumberCad'"
                                        [path]="'productNumberCad'"
                                        [binding]="'productNumberCad'"
                                        [numericFormat]="true"
                                        [attr]="{maxlength: 2}"
                                ></app-odc-field>
                            </div>
                        </div>

                        <div class="row align-items-baseline" formGroupName="fiduciaryTransactionAccountsUsd"
                             *ngIf="isUsd()">
                            <div class="col-md-10">
                                <app-odc-field
                                        [type]="constants.FIELD_TYPES.TEXT"
                                        [form]="form.get('fiduciaryTransactionAccountsUsd')"
                                        [submitted]="isFormSubmitted()"
                                        [label]="'SFD_ACCOUNT_NUMBER_USD' | translate"
                                        [id]="'accountNumberUsd'"
                                        [name]="'accountNumberUsd'"
                                        [path]="'accountNumberUsd'"
                                        [binding]="'accountNumberUsd'"
                                        [numericFormat]="true"
                                        [attr]="{maxlength: 7}"
                                ></app-odc-field>
                            </div>
                            <div class="col-md-6">
                                <app-odc-field
                                        [type]="constants.FIELD_TYPES.TEXT"
                                        [form]="form.get('fiduciaryTransactionAccountsUsd')"
                                        [submitted]="isFormSubmitted()"
                                        [label]="'SFD_PRODUCT_NUMBER' | translate"
                                        [id]="'productNumberUsd'"
                                        [name]="'productNumberUsd'"
                                        [path]="'productNumberUsd'"
                                        [binding]="'productNumberUsd'"
                                        [numericFormat]="true"
                                        [attr]="{maxlength: 2}"
                                ></app-odc-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>

    <div *ngIf="isConjointPeopleNumberValid() && isNotOther() && !isConjointGpd()">
        <h2>{{ 'REGISTERED_ACCOUNT_CHOICE_TITLE' | translate }}</h2>
    </div>

    <fieldset *ngIf="isNotOther()" class="form-group">
        <legend class="sr-only"><b>{{ 'REGISTERED_ACCOUNT_CHOICE_TITLE' | translate }}</b></legend>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--TFSA-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addTFSA"
                           id="addTFSA"
                           formControlName="addTFSA"
                           [appFormBinding]="'addTFSA'"
                           aria-describedby="tfsa-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'TFSA_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpTFSA" [templateRefEn]="helpTFSA"></app-help>
            </div>
            <div id="tfsa-account-desc" class="m-l-3">{{ 'TFSA_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addTFSA').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- tfsaAccountMgntType-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('tfsaAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'tfsaAccountMgntType'"
                                   [name]="'tfsaAccountMgntType'"
                                   [path]="'tfsaAccountMgntType'"
                                   [binding]="'tfsaAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <!--tfsa currency-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('tfsaAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [id]="'tfsaCurrency'"
                            [name]="'tfsaCurrency'"
                            [path]="'tfsaCurrency'"
                            [binding]="'tfsaCurrency'"
                            [stacked]="true"
                    ></app-odc-field>

                </div>
            </div>
        </div>

        <div *ngIf="this.isNotConjointCtlType && this.config.TOGGLE_CELI_APP" class="m-t-3">
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addFHSA"
                           id="addFHSA"
                           formControlName="addFHSA"
                           [appFormBinding]="'addFHSA'"
                           aria-describedby="fhsa-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'FHSA_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpFHSA" [templateRefEn]="helpFHSA"></app-help>
            </div>
            <div id="fhsa-account-desc" class="m-l-3">{{ 'FHSA_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="showAddFhsaButton()" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- fhsaAccountMgntType-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('fhsaAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'fhsaAccountMgntType'"
                                   [name]="'fhsaAccountMgntType'"
                                   [path]="'fhsaAccountMgntType'"
                                   [binding]="'fhsaAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <!--hsfa currency-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('fhsaAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [choices]="
                            this.config.TOGGLE_CELI_APP_USD ?
                            [
                                {
                                    label: 'CA_ONLY' | translate,
                                    value: constants.CURRENCY_CAD
                                },
                                {
                                    label: 'US_AND_CA' | translate,
                                    value: constants.CURRENCY_USD
                                }
                            ]
                            :
                             [
                                {
                                    label: 'CA_ONLY' | translate,
                                    value: constants.CURRENCY_CAD
                                }
                            ]"
                            [id]="'fhsaCurrency'"
                            [name]="'fhsaCurrency'"
                            [path]="'fhsaCurrency'"
                            [binding]="'fhsaCurrency'"
                            [stacked]="true"
                    ></app-odc-field>

                    <app-odc-field
                            [type]="constants.FIELD_TYPES.CHECKBOX"
                            [form]="form.get('fhsaAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'FHSA_ELIGIBILITY' | translate"
                            [choices]="[
                            {
                                label: 'FHSA_ELIGIBILITY_CONFIRMATION' | translate,
                                id: 'fhsaEligibility',
                                binding: 'fhsaEligibility',
                                helpRefFr: helpFHSAEligibility,
                                helpRefEn: helpFHSAEligibility
                            }
                        ]"
                            [stacked]="true"
                            [path]="'fhsaEligibility'"
                    ></app-odc-field>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--RRSP-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addRRSP"
                           id="addRRSP"
                           formControlName="addRRSP"
                           [appFormBinding]="'addRRSP'"
                           aria-describedby="rrsp-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'RRSP_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpRRSP" [templateRefEn]="helpRRSP"></app-help>
            </div>
            <div id="rrsp-account-desc" class="m-l-3">{{ 'RRSP_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addRRSP').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- rrsp management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('rrspAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'rrspAccountMgntType'"
                                   [name]="'rrspAccountMgntType'"
                                   [path]="'rrspAccountMgntType'"
                                   [binding]="'rrspAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <!--rrsp currency-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('rrspAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [id]="'rrspCurrency'"
                            [name]="'rrspCurrency'"
                            [path]="'rrspCurrency'"
                            [binding]="'rrspCurrency'"
                            [stacked]="true"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                    ></app-odc-field>

                    <!--rrsp Annuitant-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('rrspAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'RRSP_ANNUITANT' | translate"
                            [choices]="[{label: 'RRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [id]="'rrspAnnuitant'"
                            [name]="'rrspAnnuitant'"
                            [path]="'rrspAnnuitant'"
                            [binding]="'rrspAnnuitant'"
                            [stacked]="true"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddRrspSecButton()" class="m-l-3 m-b-1">
                <a href="javascript:void(0)" class="lien-sans-soulignement"
                   (click)="form.get('addRRSPSec').setValue(true)">
                    <span class="icon-circle-plus"></span>
                    {{'RRSP_ADD'|translate}}
                </a>
            </div>

            <div *ngIf="showAddRrspSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- rrsp management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                                       [form]="form.get('rrspAccountSec')"
                                       [submitted]="isFormSubmitted()"
                                       [label]="'MGNT_TYPE' | translate"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [id]="'rrspAccountMgntTypeSec'"
                                       [name]="'rrspAccountMgntTypeSec'"
                                       [path]="'rrspAccountMgntTypeSec'"
                                       [binding]="'rrspAccountMgntTypeSec'"
                                       [stacked]="true"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                        ></app-odc-field>

                        <!--rrsp currency-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('rrspAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ACCOUNT_CURRENCY' | translate"
                                [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                                [id]="'rrspCurrencySec'"
                                [name]="'rrspCurrencySec'"
                                [path]="'rrspCurrencySec'"
                                [binding]="'rrspCurrencySec'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        ></app-odc-field>

                        <!--rrsp Annuitant-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('rrspAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'RRSP_ANNUITANT' | translate"
                                [choices]="[{label: 'RRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                [id]="'rrspAnnuitantSec'"
                                [name]="'rrspAnnuitantSec'"
                                [path]="'rrspAnnuitantSec'"
                                [binding]="'rrspAnnuitantSec'"
                                [stacked]="true"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a href="javascript:void(0)" class="lien-sans-soulignement"
                       (click)="form.get('addRRSPSec').setValue(false)">
                        <span class="icon-close rouge"></span>
                        {{'RRSP_DELETE'|translate}}
                    </a>
                </div>
            </div>

            <div *ngIf="showAddRrspTerButton()" class="m-l-3 m-b-1">
                <a href="javascript:void(0)" class="lien-sans-soulignement"
                   (click)="form.get('addRRSPTer').setValue(true)">
                    <span class="icon-circle-plus"></span>
                    {{'RRSP_ADD'|translate}}
                </a>
            </div>

            <div *ngIf="showAddRrspTerSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- rrsp management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                                       [form]="form.get('rrspAccountTer')"
                                       [submitted]="isFormSubmitted()"
                                       [label]="'MGNT_TYPE' | translate"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [id]="'rrspAccountMgntTypeTer'"
                                       [name]="'rrspAccountMgntTypeTer'"
                                       [path]="'rrspAccountMgntTypeTer'"
                                       [binding]="'rrspAccountMgntTypeTer'"
                                       [stacked]="true"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                        ></app-odc-field>

                        <!--rrsp currency-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('rrspAccountTer')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ACCOUNT_CURRENCY' | translate"
                                [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                                [id]="'rrspCurrencyTer'"
                                [name]="'rrspCurrencyTer'"
                                [path]="'rrspCurrencyTer'"
                                [binding]="'rrspCurrencyTer'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        ></app-odc-field>

                        <!--rrsp Annuitant-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('rrspAccountTer')"
                                [submitted]="isFormSubmitted()"
                                [label]="'RRSP_ANNUITANT' | translate"
                                [choices]="[{label: 'RRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                [id]="'rrspAnnuitantTer'"
                                [name]="'rrspAnnuitantTer'"
                                [path]="'rrspAnnuitantTer'"
                                [binding]="'rrspAnnuitantTer'"
                                [stacked]="true"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a href="javascript:void(0)" class="lien-sans-soulignement"
                       (click)="form.get('addRRSPTer').setValue(false)">
                        <span class="icon-close rouge"></span>
                        {{'RRSP_DELETE'|translate}}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--LIRA-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addLIRA"
                           id="addLIRA"
                           formControlName="addLIRA"
                           [appFormBinding]="'addLIRA'"
                           aria-describedby="lira-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'LIRA_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpLIRA" [templateRefEn]="helpLIRA"></app-help>
            </div>
            <div id="lira-account-desc" class="m-l-3">{{ 'LIRA_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addLIRA').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- lira management type-->
                    <app-odc-field
                            *ngIf="showManagementTypeChoices()"
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('liraAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'MGNT_TYPE' | translate"
                            [choices]="[
                        {
                            label: 'MGNT_TYPE_COM' | translate,
                            value: constants.MGNT_TYPE_COM,
                            hidden: isGpdOrSfd()
                        },
                        {
                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                        }]"
                            [id]="'liraAccountMgntType'"
                            [name]="'liraAccountMgntType'"
                            [path]="'liraAccountMgntType'"
                            [binding]="'liraAccountMgntType'"
                            [stacked]="true"
                            [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <!--lira currency-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('liraAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [id]="'liraCurrency'"
                            [name]="'liraCurrency'"
                            [path]="'liraCurrency'"
                            [binding]="'liraCurrency'"
                            [stacked]="true"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                    ></app-odc-field>

                    <!--lira jurisdiction-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.SELECT"
                            [form]="form.get('liraAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'LIRA_JURISDICTION' | translate"
                            [id]="'liraJurisdiction'"
                            [name]="'liraJurisdiction'"
                            [path]="'liraJurisdiction'"
                            [list]="jurisdictions"
                            [defaultOptionLabel]="'LIRA_JURISDICTION_DEFAULT_OPTION' | translate"
                            [binding]="'liraJurisdiction'"
                            [errorOnSubmitOnly]="['notSameJuridiction']"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddLiraSecButton()" class="m-l-3 m-b-1">
                <a href="javascript:void(0)" class="lien-sans-soulignement"
                   (click)="form.get('addLIRASec').setValue(true)">
                    <span class="icon-circle-plus"></span>
                    {{'LIRA_ADD'|translate}}
                </a>
            </div>

            <div *ngIf="showAddLiraSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- lira management type-->
                        <app-odc-field
                                *ngIf="showManagementTypeChoices()"
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('liraAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'MGNT_TYPE' | translate"
                                [choices]="[
                            {
                                label: 'MGNT_TYPE_COM' | translate,
                                value: constants.MGNT_TYPE_COM,
                                hidden: isGpdOrSfd()
                            },
                            {
                                label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                            }]"
                                [id]="'liraAccountMgntTypeSec'"
                                [name]="'liraAccountMgntTypeSec'"
                                [path]="'liraAccountMgntTypeSec'"
                                [binding]="'liraAccountMgntTypeSec'"
                                [stacked]="true"
                        ></app-odc-field>

                        <!--lira currency-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('liraAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ACCOUNT_CURRENCY' | translate"
                                [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                                [id]="'liraCurrencySec'"
                                [name]="'liraCurrencySec'"
                                [path]="'liraCurrencySec'"
                                [binding]="'liraCurrencySec'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        ></app-odc-field>

                        <!--lira jurisdiction-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.SELECT"
                                [form]="form.get('liraAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'LIRA_JURISDICTION' | translate"
                                [id]="'liraJurisdictionSec'"
                                [name]="'liraJurisdictionSec'"
                                [path]="'liraJurisdictionSec'"
                                [list]="jurisdictions"
                                [defaultOptionLabel]="'LIRA_JURISDICTION_DEFAULT_OPTION' | translate"
                                [binding]="'liraJurisdictionSec'"
                                [errorOnSubmitOnly]="['notSameJuridiction']"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a href="javascript:void(0)" class="lien-sans-soulignement"
                       (click)="form.get('addLIRASec').setValue(false)">
                        <span class="icon-close rouge"></span>
                        {{'LIRA_DELETE'|translate}}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--RRIF-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addRRIF"
                           id="addRRIF"
                           formControlName="addRRIF"
                           [appFormBinding]="'addRRIF'"
                           aria-describedby="rrif-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'RRIF_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpRRIF" [templateRefEn]="helpRRIF"></app-help>
            </div>
            <div id="rrif-account-desc" class="m-l-3">{{ 'RRIF_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addRRIF').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- rrif management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('rrifAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'rrifAccountMgntType'"
                                   [name]="'rrifAccountMgntType'"
                                   [path]="'rrifAccountMgntType'"
                                   [binding]="'rrifAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('rrifAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [id]="'rrifCurrency'"
                            [name]="'rrifCurrency'"
                            [path]="'rrifCurrency'"
                            [binding]="'rrifCurrency'"
                            [stacked]="true"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                    ></app-odc-field>

                    <!--rrif Annuitant-->
                    <app-odc-field *ngIf="isPleinEx()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('rrifAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'RRIF_ANNUITANT' | translate"
                                   [choices]="[{label: 'RRIF_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRIF_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                   [id]="'rrifAnnuitant'"
                                   [name]="'rrifAnnuitant'"
                                   [path]="'rrifAnnuitant'"
                                   [binding]="'rrifAnnuitant'"
                                   [stacked]="true"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddRrifSecButton()" class="m-l-3 m-b-1">
                <a href="javascript:void(0)" class="lien-sans-soulignement"
                   (click)="form.get('addRRIFSec').setValue(true)">
                    <span class="icon-circle-plus"></span>
                    {{'RRIF_ADD'|translate}}
                </a>
            </div>

            <div *ngIf="showAddRrifSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- rrif management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                                       [form]="form.get('rrifAccountSec')"
                                       [submitted]="isFormSubmitted()"
                                       [label]="'MGNT_TYPE' | translate"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [id]="'rrifAccountMgntTypeSec'"
                                       [name]="'rrifAccountMgntTypeSec'"
                                       [path]="'rrifAccountMgntTypeSec'"
                                       [binding]="'rrifAccountMgntTypeSec'"
                                       [stacked]="true"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                        ></app-odc-field>

                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('rrifAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ACCOUNT_CURRENCY' | translate"
                                [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                                [id]="'rrifCurrencySec'"
                                [name]="'rrifCurrencySec'"
                                [path]="'rrifCurrencySec'"
                                [binding]="'rrifCurrencySec'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        ></app-odc-field>

                        <!--rrif Annuitant-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('rrifAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'RRIF_ANNUITANT' | translate"
                                [choices]="[{label: 'RRIF_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRIF_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                [id]="'rrifAnnuitantSec'"
                                [name]="'rrifAnnuitantSec'"
                                [path]="'rrifAnnuitantSec'"
                                [binding]="'rrifAnnuitantSec'"
                                [stacked]="true"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a href="javascript:void(0)" class="lien-sans-soulignement"
                       (click)="form.get('addRRIFSec').setValue(false)">
                        <span class="icon-close rouge"></span>
                        {{'RRIF_DELETE'|translate}}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--LIF-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addLIF"
                           id="addLIF"
                           formControlName="addLIF"
                           [appFormBinding]="'addLIF'"
                           aria-describedby="lif-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'LIF_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpLIF" [templateRefEn]="helpLIF"></app-help>
            </div>
            <div id="lif-account-desc" class="m-l-3">{{ 'LIF_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addLIF').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- lif management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('lifAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'lifAccountMgntType'"
                                   [name]="'lifAccountMgntType'"
                                   [path]="'lifAccountMgntType'"
                                   [binding]="'lifAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('lifAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [id]="'lifCurrency'"
                            [name]="'lifCurrency'"
                            [path]="'lifCurrency'"
                            [binding]="'lifCurrency'"
                            [stacked]="true"
                    ></app-odc-field>


                    <!--lif jurisdiction-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.SELECT"
                            [form]="form.get('lifAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'LIF_JURISDICTION' | translate"
                            [id]="'lifJurisdiction'"
                            [name]="'lifJurisdiction'"
                            [path]="'lifJurisdiction'"
                            [list]="jurisdictions"
                            [defaultOptionLabel]="'LIF_JURISDICTION_DEFAULT_OPTION' | translate"
                            [binding]="'lifJurisdiction'"
                    ></app-odc-field>
                </div>
            </div>
        </div>

        <div *ngIf="isConjointPeopleNumberValid() && !isGpdOrSfd()" class="m-t-3">
            <!--RESP-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addRESP"
                           id="addRESP"
                           formControlName="addRESP"
                           [appFormBinding]="'addRESP'"
                           aria-describedby="resp-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'RESP_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpRESP" [templateRefEn]="helpRESP"></app-help>
            </div>
            <div id="resp-account-desc" class="m-l-3">{{ 'RESP_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addRESP').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- resp management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('respAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'respAccountMgntType'"
                                   [name]="'respAccountMgntType'"
                                   [path]="'respAccountMgntType'"
                                   [binding]="'respAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <!--resp type-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('respAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'RESP_TYPE' | translate"
                            [choices]="[{label: 'RESP_TYPE_FAMILY' | translate, value: constants.RESP_TYPE_FAMILY, templateRef: respNumberOfBeneficiaries}, {label: 'RESP_TYPE_INDIVIDUAL' | translate, value: constants.RESP_TYPE_INDIVIDUAL}]"
                            [id]="'respType'"
                            [name]="'respType'"
                            [path]="'respType'"
                            [binding]="'respType'"
                            [stacked]="true"
                    ></app-odc-field>

                    <ng-template #respNumberOfBeneficiaries>
                        <div *ngIf="showRespNumberOfBeneficiariesField()" class="m-l-4">
                            <!--respNumberOfBeneficiaries-->
                            <app-odc-field
                                    [type]="constants.FIELD_TYPES.TEXT"
                                    [form]="form.get('respAccount.respTypeFamily')"
                                    [submitted]="isFormSubmitted()"
                                    [label]="'RESP_NUMBER_OF_BENEFICIARIES' | translate"
                                    [id]="'respNumberOfBeneficiaries'"
                                    [name]="'respNumberOfBeneficiaries'"
                                    [path]="'respNumberOfBeneficiaries'"
                                    [binding]="'respNumberOfBeneficiaries'"
                                    [integerFormat]="true"
                                    [attr]="{maxlength: 2}"
                            ></app-odc-field>
                        </div>
                    </ng-template>

                    <!--resp subscriber-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('respAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'RESP_IS_SUBSCRIBER_RESPONSIBLE' | translate"
                            [choices]="[{label: 'GLOBAL_YES' | translate, value: true}, {label: 'GLOBAL_NO' | translate, value: false}]"
                            [id]="'respIsSubscriberResponsible'"
                            [name]="'respIsSubscriberResponsible'"
                            [path]="'respIsSubscriberResponsible'"
                            [binding]="'respIsSubscriberResponsible'"
                            [stacked]="true"
                    ></app-odc-field>
                </div>
            </div>
        </div>

        <div *ngIf="isPleinEx() && formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU" class="m-t-3">
            <!--FTARRSP-->
            <div>
                <label class="c-input c-checkbox">
                    <input type="checkbox"
                           name="addFTARRSP"
                           id="addFTARRSP"
                           formControlName="addFTARRSP"
                           [appFormBinding]="'addFTARRSP'"
                           aria-describedby="ftarrsp-account-desc">
                    <span class="c-indicator"></span>
                    {{ 'FTARRSP_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefFr]="helpFTARRSP" [templateRefEn]="helpFTARRSP"></app-help>
            </div>
            <div id="ftarrsp-account-desc" class="m-l-3">{{ 'FTARRSP_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addFTARRSP').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- FTARRSP management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                                   [form]="form.get('ftarrspAccount')"
                                   [submitted]="isFormSubmitted()"
                                   [label]="'MGNT_TYPE' | translate"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [id]="'ftarrspAccountMgntType'"
                                   [name]="'ftarrspAccountMgntType'"
                                   [path]="'ftarrspAccountMgntType'"
                                   [binding]="'ftarrspAccountMgntType'"
                                   [stacked]="true"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                    ></app-odc-field>

                    <!--FTARRSP Annuitant-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('ftarrspAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'FTARRSP_ANNUITANT' | translate"
                            [choices]="[{label: 'FTARRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'FTARRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [id]="'ftarrspAnnuitant'"
                            [name]="'ftarrspAnnuitant'"
                            [path]="'ftarrspAnnuitant'"
                            [binding]="'ftarrspAnnuitant'"
                            [stacked]="true"
                    ></app-odc-field>

                    <!--FTARRSP ftarrspRevocableBeneficiaryIndicator-->
                    <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form.get('ftarrspAccount')"
                            [submitted]="isFormSubmitted()"
                            [label]="'FTARRSP_REVOCABLE_BENEFICIARY' | translate"
                            [choices]="[{label: 'FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE' | translate, value: true}, {label: 'FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE' | translate, value: false}]"
                            [id]="'ftarrspRevocableBeneficiaryIndicator'"
                            [name]="'ftarrspRevocableBeneficiaryIndicator'"
                            [path]="'ftarrspRevocableBeneficiaryIndicator'"
                            [binding]="'ftarrspRevocableBeneficiaryIndicator'"
                            [stacked]="true"
                            [errorOnSubmitOnly]="['maxTwoSameDesignationOfBeneficiary']"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddFtarrspSecButton()" class="m-l-3 m-b-1">
                <a href="javascript:void(0)" class="lien-sans-soulignement"
                   (click)="form.get('addFTARRSPSec').setValue(true)">
                    <span class="icon-circle-plus"></span>
                    {{'FTARRSP_ADD'|translate}}
                </a>
            </div>

            <div *ngIf="showAddFtarrspSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- FTARRSP management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                                       [form]="form.get('ftarrspAccountSec')"
                                       [submitted]="isFormSubmitted()"
                                       [label]="'MGNT_TYPE' | translate"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [id]="'ftarrspAccountMgntTypeSec'"
                                       [name]="'ftarrspAccountMgntTypeSec'"
                                       [path]="'ftarrspAccountMgntTypeSec'"
                                       [binding]="'ftarrspAccountMgntTypeSec'"
                                       [stacked]="true"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                        ></app-odc-field>

                        <!--FTARRSP Annuitant-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('ftarrspAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'FTARRSP_ANNUITANT' | translate"
                                [choices]="[{label: 'FTARRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'FTARRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                [id]="'ftarrspAnnuitantSec'"
                                [name]="'ftarrspAnnuitantSec'"
                                [path]="'ftarrspAnnuitantSec'"
                                [binding]="'ftarrspAnnuitantSec'"
                                [stacked]="true"
                        ></app-odc-field>

                        <!--FTARRSP ftarrspRevocableBeneficiaryIndicator-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('ftarrspAccountSec')"
                                [submitted]="isFormSubmitted()"
                                [label]="'FTARRSP_REVOCABLE_BENEFICIARY' | translate"
                                [choices]="[{label: 'FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE' | translate, value: true}, {label: 'FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE' | translate, value: false}]"
                                [id]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                                [name]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                                [path]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                                [binding]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['maxTwoSameDesignationOfBeneficiary']"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a href="javascript:void(0)" class="lien-sans-soulignement"
                       (click)="form.get('addFTARRSPSec').setValue(false)">
                        <span class="icon-close rouge"></span>
                        {{'FTARRSP_DELETE'|translate}}
                    </a>
                </div>
            </div>

            <div *ngIf="showAddFtarrspTerButton()" class="m-l-3 m-b-1">
                <a href="javascript:void(0)" class="lien-sans-soulignement"
                   (click)="form.get('addFTARRSPTer').setValue(true)">
                    <span class="icon-circle-plus"></span>
                    {{'FTARRSP_ADD'|translate}}
                </a>
            </div>

            <div *ngIf="showAddFtarrspTerSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- FTARRSP management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                                       [form]="form.get('ftarrspAccountTer')"
                                       [submitted]="isFormSubmitted()"
                                       [label]="'MGNT_TYPE' | translate"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [id]="'ftarrspAccountMgntTypeTer'"
                                       [name]="'ftarrspAccountMgntTypeTer'"
                                       [path]="'ftarrspAccountMgntTypeTer'"
                                       [binding]="'ftarrspAccountMgntTypeTer'"
                                       [stacked]="true"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                        ></app-odc-field>

                        <!--FTARRSP Annuitant-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('ftarrspAccountTer')"
                                [submitted]="isFormSubmitted()"
                                [label]="'FTARRSP_ANNUITANT' | translate"
                                [choices]="[{label: 'FTARRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'FTARRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                [id]="'ftarrspAnnuitantTer'"
                                [name]="'ftarrspAnnuitantTer'"
                                [path]="'ftarrspAnnuitantTer'"
                                [binding]="'ftarrspAnnuitantTer'"
                                [stacked]="true"
                        ></app-odc-field>

                        <!--FTARRSP ftarrspRevocableBeneficiaryIndicator-->
                        <app-odc-field
                                [type]="constants.FIELD_TYPES.RADIO"
                                [form]="form.get('ftarrspAccountTer')"
                                [submitted]="isFormSubmitted()"
                                [label]="'FTARRSP_REVOCABLE_BENEFICIARY' | translate"
                                [choices]="[{label: 'FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE' | translate, value: true}, {label: 'FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE' | translate, value: false}]"
                                [id]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                                [name]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                                [path]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                                [binding]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                                [stacked]="true"
                                [errorOnSubmitOnly]="['maxTwoSameDesignationOfBeneficiary']"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a href="javascript:void(0)" class="lien-sans-soulignement"
                       (click)="form.get('addFTARRSPTer').setValue(false)">
                        <span class="icon-close rouge"></span>
                        {{'FTARRSP_DELETE'|translate}}
                    </a>
                </div>
            </div>
        </div>
    </fieldset>

    <div *ngIf="showDiscretionary() && !isSsd() && !isGpdOrSfd()">
        <h2>{{ 'DISCRETIONARY_TITLE' | translate }}</h2>
        <app-odc-field
                [type]="constants.FIELD_TYPES.CHECKBOX"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'DISCRETIONARY_TITLE' | translate"
                [labelSrOnly]="true"
                [choices]="[{
                id: 'addDiscretionary',
                binding: '',
                label: 'DISCRETIONARY_LABEL' | translate
            }]"
                [path]="'addDiscretionary'"
                [errorOnSubmitOnly]="['requiredFalse']"
        ></app-odc-field>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [hideBackBtn]="hideBackBtn()"></app-questionnaire-btn>
</form>


<ng-template #helpCashAccount>
    <h2>{{'CASH_ACCOUNT'|translate}}</h2>
    <p>{{(!isPleinEx() ? 'CASH_ACCOUNT_HELP_DCL' : 'CASH_ACCOUNT_HELP')|translate}}</p>
</ng-template>
<ng-template #helpMarginAccount>
    <h2>{{'MARGIN_ACCOUNT'|translate}}</h2>
    <p>{{'MARGIN_ACCOUNT_HELP'|translate}}</p>
</ng-template>
<ng-template #helpMarginAccountWithOptions>
    <h2>{{'MARGIN_ACCOUNT_WITH_OPTIONS'|translate}}</h2>
    <p>{{'WITH_OPTIONS_HELP'|translate}}</p>
</ng-template>
<ng-template #helpMarginAccountWithShortSelling>
    <h2>{{'MARGIN_ACCOUNT_WITH_SHORT_SELLING'|translate}}</h2>
    <p>{{'WITH_SHORT_SELLING_HELP'|translate}}</p>
</ng-template>
<ng-template #helpRRSP>
    <h2>{{'RRSP_ACCOUNT'|translate}}</h2>
    <p>{{'RRSP_ACCOUNT_HELP'|translate}}</p>
</ng-template>
<ng-template #helpTFSA>
    <h2>{{'TFSA_ACCOUNT'|translate}}</h2>
    <p>{{'TFSA_ACCOUNT_HELP'|translate}}</p>
</ng-template>
<ng-template #helpFHSA>
    <h2>{{'FHSA_ACCOUNT'|translate}}</h2>
    <p [innerHTML]="'FHSA_ACCOUNT_HELP'|translate"></p>
</ng-template>
<ng-template #helpFHSAEligibility>
    <p [innerHTML]="'FHSA_HELP_ELIGIBILITY'|translate"></p>
</ng-template>
<ng-template #helpRESP>
    <h2>{{'RESP_ACCOUNT'|translate}}</h2>
    <p>{{'RESP_ACCOUNT_HELP'|translate}}</p>
</ng-template>

<ng-template #helpLIRA>
    <h2>{{'LIRA_ACCOUNT'|translate}}</h2>
    <p>{{'LIRA_ACCOUNT_HELP'|translate}}</p>
</ng-template>
<ng-template #helpRRIF>
    <h2>{{'RRIF_ACCOUNT'|translate}}</h2>
    <p>{{'RRIF_ACCOUNT_HELP'|translate}}</p>
</ng-template>
<ng-template #helpLIF>
    <h2>{{'LIF_ACCOUNT'|translate}}</h2>
    <p>{{'LIF_ACCOUNT_HELP'|translate}}</p>
</ng-template>
<ng-template #helpFTARRSP>
    <h2>{{'FTARRSP_ACCOUNT'|translate}}</h2>
    <p>{{'FTARRSP_ACCOUNT_HELP'|translate}}</p>
</ng-template>

<ng-template #helpFiduciaryTranactionAccountsFr>
    <h2>Comptes opérations fiduciaires</h2>
    <p>Le numéro de compte et le numéro de produit correspondent au numéro de compte fourni par les Services
        fiduciaires.</p>
    <p>Le numéro de compte est composé de 7 chiffres.</p>
    <p>Le numéro de produit est composé de 2 chiffres, soit 60, 61 ou 69.</p>
</ng-template>
<ng-template #helpFiduciaryTranactionAccountsEn>
    <h2>Fiduciary Transaction Accounts</h2>
    <p>The account number and product number are the same as the account number provided by Trust services.</p>
    <p>The account number has 7 digits.</p>
    <p>The product number has 2 digits, either 60, 61 or 69.</p>
</ng-template>
