import {Inject, Injectable} from '@angular/core';
import {VmdConstants} from '../constants/vmd-constants';
import {FormService} from './form.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {ODCForm} from '../models';
import {AppRoutes} from '../../app.routes';

@Injectable()
export class RecoveryRedirectService {

    constructor(private formService: FormService,
        @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    public getPersonalAccountDisnatWebPath(form: ODCForm) {
        const requestStatus = form.requestStatus;
        const transmissionMethod = form.transmissionMethod;
        const esignCompleted = form.esignCompleted;

        if (requestStatus === VmdConstants.REQUEST_STATUS_ENTRY || requestStatus === VmdConstants.REQUEST_STATUS_SAVED) {

            return 'ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE_PERSONAL_ACCOUNT_PATH';
        } else if ((requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED
                || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED || requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED
                || requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE)
            && this.config.FROM_EXISTING_REQUEST && this.config.AGENT_DISNAT_USERNAME) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH';

        } else if (this.isStatusInjection()) {

            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH';

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT &&
            (transmissionMethod === VmdConstants.TRANSMISSION_ESIGN ||
                transmissionMethod === VmdConstants.TRANSMISSION_ESIGN_MAIL)) {

            if (esignCompleted) {

                this.formService.setTransmitted(true);
                return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH';
            } else {

                this.formService.setTransmitted(false);
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH';
            }

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT && transmissionMethod === null) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED
            || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED) {

            this.formService.setTransmitted(true);
            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH';
        } else if (transmissionMethod === VmdConstants.TRANSMISSION_MAIL) {

            if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT) {
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH';
            }
        }

        return null;
    }

    public getPersonalAccountDisnatCaissePath(form: ODCForm) {
        const requestStatus = form.requestStatus;
        const transmissionMethod = form.transmissionMethod;

        if (requestStatus === VmdConstants.REQUEST_STATUS_ENTRY || requestStatus === VmdConstants.REQUEST_STATUS_SAVED) {

            return 'ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE_PERSONAL_ACCOUNT_PATH';
        } else if ((requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED
                || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED || requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED
                || requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE)
            && this.config.FROM_EXISTING_REQUEST && this.config.AGENT_DISNAT_USERNAME) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE) {

            // OCE-7167
            if (form.requesters[0].userAddressProv === VmdConstants.MANITOBA) {
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH';
            } else {
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH';
            }

        } else if (VmdConstants.CAISSE_TO_CONFIRMATION_STATUS_TYPES.indexOf(requestStatus) !== -1) {

            if (requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED
                || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED) {
                this.formService.setTransmitted(true);
            }

            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_PERSONAL_ACCOUNT_PATH';

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT &&
            (transmissionMethod === VmdConstants.TRANSMISSION_ESIGN ||
                transmissionMethod === VmdConstants.TRANSMISSION_ESIGN_MAIL)) {

            if (form.esignCompleted) {

                this.formService.setTransmitted(true);
                return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_PERSONAL_ACCOUNT_PATH';
            } else {

                this.formService.setTransmitted(false);
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH';
            }

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT && transmissionMethod === null) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH';
        } else if (transmissionMethod === VmdConstants.TRANSMISSION_MAIL) {

            if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT) {
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH';
            }
        }
        return null;
    }

    public getJointAccountDisnatCaissePath(form: ODCForm) {
        const requestStatus = form.requestStatus;
        const transmissionMethod = form.transmissionMethod;
        const esignCompleted = form.esignCompleted;

        if (requestStatus === VmdConstants.REQUEST_STATUS_ENTRY || requestStatus === VmdConstants.REQUEST_STATUS_SAVED) {

            return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_PATH';
        } else if ((requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED
                || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED || requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED
                || requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE)
            && this.config.FROM_EXISTING_REQUEST && this.config.AGENT_DISNAT_USERNAME) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH';

        } else if (this.isStatusInjection()) {

            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_JOINT_ACCOUNT_PATH';

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT &&
            (transmissionMethod === VmdConstants.TRANSMISSION_ESIGN ||
                transmissionMethod === VmdConstants.TRANSMISSION_ESIGN_MAIL)) {

            if (esignCompleted) {

                this.formService.setTransmitted(true);
                return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_JOINT_ACCOUNT_PATH';
            } else {

                this.formService.setTransmitted(false);
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_JOINT_ACCOUNT_PATH';
            }

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT && transmissionMethod === null) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED
            || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED) {

            this.formService.setTransmitted(true);
            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_JOINT_ACCOUNT_PATH';
        } else if (transmissionMethod === VmdConstants.TRANSMISSION_MAIL) {

            if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT) {
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH';
            }
        }
        return null;
    }

    public getOtherAccountDisnatCaissePath(form: ODCForm) {
        const requestStatus = form.requestStatus;
        const transmissionMethod = form.transmissionMethod;
        const esignCompleted = form.esignCompleted;

        if (requestStatus === VmdConstants.REQUEST_STATUS_ENTRY || requestStatus === VmdConstants.REQUEST_STATUS_SAVED) {
            return this.getSavedOtherAccountPath(form.cltType);

        } else if ((requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED
                || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED || requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED
                || requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE)
            && this.config.FROM_EXISTING_REQUEST && this.config.AGENT_DISNAT_USERNAME) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH';

        } else if (this.isStatusInjection()) {

            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH';

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT &&
            (transmissionMethod === VmdConstants.TRANSMISSION_ESIGN ||
                transmissionMethod === VmdConstants.TRANSMISSION_ESIGN_MAIL)) {

            if (esignCompleted) {

                this.formService.setTransmitted(true);
                return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH';
            } else {

                this.formService.setTransmitted(false);
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_OTHER_ACCOUNT_PATH';
            }

        } else if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT && transmissionMethod === null) {

            return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH';
        } else if (requestStatus === VmdConstants.REQUEST_STATUS_RECEIVED || requestStatus === VmdConstants.REQUEST_STATUS_MANAGED
            || requestStatus === VmdConstants.REQUEST_STATUS_ESIGNED) {

            this.formService.setTransmitted(true);
            return 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH';
        } else if (transmissionMethod === VmdConstants.TRANSMISSION_MAIL) {

            if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT) {
                return 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH';
            }
        }
        return null;
    }

    getOtherAccountPleinExPath(form: ODCForm): string {
        if (this.isAutomatedModeAndCompleted(form) && !this.isReadOnly()) {
            this.formService.setCompleted(true);
            return this.getPleinExSubDivisionPath('ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_GPD_PATH',
                'ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_SSD_PATH',
                'ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_PLEIN_EX_PATH');
        }

        const firstShownedTabStepPath = this.getPleinExSubDivisionPath(VmdConstants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY,
            VmdConstants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY,
            VmdConstants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY);
        return AppRoutes.getFirstShownedTabStepPath(firstShownedTabStepPath);
    }

    getPersonalAccountPleinExPath(form: ODCForm): string {
        if (this.isAutomatedModeAndCompleted(form) && !this.isReadOnly()) {
            this.formService.setCompleted(true);
            return this.getPleinExSubDivisionPath('ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_GPD_PATH',
                'ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_SSD_PATH',
                'ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_PLEIN_EX_PATH');
        }

        const firstShownedTabStepPath = this.getPleinExSubDivisionPath(VmdConstants.PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG_KEY,
            VmdConstants.PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG_KEY,
            VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY);
        return AppRoutes.getFirstShownedTabStepPath(firstShownedTabStepPath);
    }

    getPersonalUpdateAccountPleinExPath(form: ODCForm): string {
        if ((this.isStatusCompleted(form) || form.requestUpdatePath !== null) && !this.isReadOnly()) {
            this.formService.setCompleted(true);
            return (VmdConstants.TRANSMISSION_PRINT === form.transmissionMethod ||
                form.requestUpdatePath === VmdConstants.PATH_IMPRESSION) ?
                'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PATH' :
                this.getPleinExSubDivisionPath('ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH',
                    'ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_SSD_PATH',
                    'ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_PATH');
        }

        const firstShownedTabStepPath = this.getPleinExSubDivisionPath(VmdConstants.PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY,
            VmdConstants.PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY,
            VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY);
        return AppRoutes.getFirstShownedTabStepPath(firstShownedTabStepPath);
    }

    getPersonalUpdateAccountDisnatWebPath(form: ODCForm): string {
        if ((this.isStatusCompleted(form) || form.requestUpdatePath !== null) && !this.isReadOnly()) {
            this.formService.setCompleted(true);
            return (VmdConstants.TRANSMISSION_PRINT === form.transmissionMethod ||
                form.requestUpdatePath === VmdConstants.PATH_IMPRESSION) ?
                'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PATH' : 'ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PATH';
        }
        return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PATH';
    }

    getJointAccountPleinExPath(form: ODCForm): string {
        if (this.isAutomatedModeAndCompleted(form) && !this.isReadOnly()) {
            this.formService.setCompleted(true);
            return this.getPleinExSubDivisionPath('ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_GPD_PATH',
                'ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_SSD_PATH',
                'ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_PLEIN_EX_PATH');
        }

        const firstShownedTabStepPath = this.getPleinExSubDivisionPath(VmdConstants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY,
            VmdConstants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY,
            VmdConstants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY);
        return AppRoutes.getFirstShownedTabStepPath(firstShownedTabStepPath);
    }

    isReadOnly() {
        return this.config.READ_ONLY;
    }

    getPleinExSubDivisionPath(subDivisionGPDPath: string, subDivisionSSDPath: string, defaultPath: string): string {
        if (this.config.IS_GPD || this.config.IS_SFD) {
            return subDivisionGPDPath;
        } else if (this.config.IS_SSD) {
            return subDivisionSSDPath;
        } else {
            return defaultPath;
        }
    }

    private isStatusInjection(): boolean {
        return this.formService.isStatusInjection();
    }

    private getSavedOtherAccountPath(cltType: string): string {

        switch (cltType) {
            case VmdConstants.CLT_TYPE_ASSOCIATION: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_CLUB: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_COOPERATIVE: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_CORPORATION: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_CURATELLE: {
                return 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_ENF_REF: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_ENF_ES_QUALITES: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_CO_UNIQUE: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_MANDATAIRE: {
                return 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_FIDUCIE: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_OSBL: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_SENC: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_SOC_COMM: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_SUCC: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH';
            }
            case VmdConstants.CLT_TYPE_TUTELLE: {
                return 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH';
            }
        }

        return null;
    }

    private isAutomatedModeAndCompleted(form: ODCForm): boolean {
        return VmdConstants.MODES.AUTOMATED === form.entryMode
            && VmdConstants.REQUEST_STATUS_PRINTED !== form.requestStatus
            && VmdConstants.REQUEST_STATUS_SAVED !== form.requestStatus
            && VmdConstants.REQUEST_STATUS_CORRECT !== form.requestStatus;
    }

    public isStatusCompleted(form: ODCForm): boolean {
        return form.requestStatus !== null &&
            VmdConstants.REQUEST_STATUS_PRINTED !== form.requestStatus &&
            VmdConstants.REQUEST_STATUS_SAVED !== form.requestStatus &&
            VmdConstants.REQUEST_STATUS_CORRECT !== form.requestStatus;
    }

    public isNotStatusCompleted(form: ODCForm): boolean {
        return (form.requestStatus === null ||
                VmdConstants.REQUEST_STATUS_PRINTED === form.requestStatus ||
                VmdConstants.REQUEST_STATUS_SAVED === form.requestStatus ||
                VmdConstants.REQUEST_STATUS_CORRECT === form.requestStatus) &&
            form.requestUpdatePath === null;
    }
}
