import {AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../components/services/navigation.service';

declare let $: any;

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html'
})
export class HelpComponent implements AfterViewInit, OnInit {

    @Input() templateRefFr: TemplateRef<any>;
    @Input() templateRefEn: TemplateRef<any>;

    @Input() label = null;

    @Input() srOnly = false;

    iconClass = 'ico-aide';
    @Input() iconClassHide = 'ico-aide';
    @Input() iconClassShow = 'ico-aide';

    @ViewChild('helpLink', {read: ElementRef}) helpLink: ElementRef;
    @ViewChild('helpContent', {read: ElementRef}) helpContent: ElementRef;

    constructor(private el: ElementRef, private navigationService: NavigationService, protected translate: TranslateService) {
    }

    ngOnInit() {

        // OCE-7366
        if (this.navigationService.currentTab === 9) {
            this.label = 'AGREEMENT_CONSENT_HELP_LABEL';
        }

        this.iconClass = this.iconClassHide;

        this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.init();
        });
    }

    ngAfterViewInit() {
        this.init();
    }

    private init(): void {

        if (this.isDefined()) {
            setTimeout(() => {
                $(this.helpLink.nativeElement).popover('destroy');
                setTimeout(() => {
                    $(this.helpLink.nativeElement).popover({
                        html: true,
                        placement: 'bottom',
                        content: () => {
                            return $(this.helpContent.nativeElement).html();
                        }
                    });

                    // OCE-7342
                    $(this.helpLink.nativeElement).on('show.bs.popover', (e) => {
                        this.iconClass = this.iconClassShow;

                        $('.help-link').not(e.target).popover('hide');
                        $('.help-link .ic-moins-carre-out-couleur').not(e.target).removeClass('ic-moins-carre-out-couleur').addClass('ic-plus-carre-out-couleur');
                    });

                    $(this.helpLink.nativeElement).on('shown.bs.popover', (e) => {
                        this.iconClass = this.iconClassShow;
                        // fix OCE-5013
                        $('.popover-fin').html($('.popover-fin').html().replace(/popover/g, 'pop-over'));
                    });

                    // Both a required even if it should not, one called on link click, the other on close button.
                    $(this.helpLink.nativeElement).on('hide.bs.popover', (e) => {
                        this.iconClass = this.iconClassHide;
                    });
                    $(this.helpLink.nativeElement).on('hidden.bs.popover', (e) => {
                        this.iconClass = this.iconClassHide;
                    });
                }, 100);
            }, 100);
        }
    }

    isDefined(): boolean {
        return this.templateRefFr != null || this.templateRefEn != null;
    }

    display(event): void {
        event.stopPropagation();

        if (this.isDefined()) {
            $(this.helpLink.nativeElement).popover('show');
        }
    }

}
