import {Inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import ordinal from 'ordinal';
import {VmdConstants} from '../constants/vmd-constants';

@Pipe({
    name: 'prefixerror'
})
export class PrefixErrorPipe implements PipeTransform {
    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig, private translateService: TranslateService) {
    }

    transform(value: string, controlName: string, index: string, context: string = null) {
        if (this.config.TOGGLE_LBA && (context === 'app-transmission-attachments-lba' || context === 'app-transmission-attachments-lba-caisse')) {
            let attachedDocumentPos = Number.parseInt(index) + 1;
            if (controlName === 'attachedDocument' || controlName === 'attachedDocumentBack') {
                const prefixAttachedDocument = (context === 'app-transmission-attachments-lba')?this.getPrefixAttachedDocument(attachedDocumentPos):
                    this.getPrefixAttachedDocumentCaisse(attachedDocumentPos);
                return prefixAttachedDocument + ' - ' + value;
            }
        }
        return value;
    }

    private getPrefixAttachedDocument(attachmentPos: number): string {
        return attachmentPos == 3 ? this.translateService.instant('TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL') :
            this.translateService.instant('ERROR_FIELD_attachedDocument_prefix', {index: this.getOrdinal(attachmentPos)});
    }

    private getPrefixAttachedDocumentCaisse(attachmentPos: number): string {
        return attachmentPos == 2 ? this.translateService.instant('TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL') :
            this.translateService.instant('ERROR_FIELD_userIdentityCodeCaisse');
    }

    private getOrdinal(index: number): any {
        return this.translateService.currentLang == VmdConstants.LANG_EN ? ordinal(index) : index;
    }
}
