import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AppRoutes, ODC_ROUTES_CONFIG} from '../../app.routes';
import {VmdConstants} from '../constants/vmd-constants';
import {ODCForm} from '../models';
import {I18N_NAV_MESSAGES_EN} from '../i18n/nav-messages_en';
import {I18N_NAV_MESSAGES_FR} from '../i18n/nav-messages_fr';
import {Branch} from '../models/Branch';
import {NavigationConstants} from '../constants/navigation-constants';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

declare let $: any;

@Injectable()
export class NavigationService {

    public currentTab: number = null;
    public currentSubTab: number = null;
    public stepRoutesConfigKey: string = null;
    public isBackDisabled = false;
    public stepRoutesConfigKeysEmitted: Observable<any>;
    public nextEmitted: Observable<any>;
    public backEmitted: Observable<any>;
    public isBackDisabledEmitted: Observable<any>;
    public stepMetricFormEmitted: Observable<any>;
    public afterViewInitEmitted: Observable<any>;
    private emitStepRoutesConfigKeysSource = new Subject<string>();
    private emitNextSource = new Subject<any>();
    private emitBackSource = new Subject<any>();
    private emitIsBackDisabledSource = new Subject<any>();
    private emitStepMetricFormSource = new Subject<any>();
    private emitAfterViewInitSource = new Subject<any>();

    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig) {
        this.stepRoutesConfigKeysEmitted = this.emitStepRoutesConfigKeysSource.asObservable();
        this.nextEmitted = this.emitNextSource.asObservable();
        this.backEmitted = this.emitBackSource.asObservable();
        this.isBackDisabledEmitted = this.emitIsBackDisabledSource.asObservable();
        this.stepMetricFormEmitted = this.emitStepMetricFormSource.asObservable();
        this.afterViewInitEmitted = this.emitAfterViewInitSource.asObservable();
    }

    agentDisnatUsername(): string {
        return this.config.AGENT_DISNAT_USERNAME;
    }

    getBranchAct(): string {
        return this.config.BRANCH_ACT;
    }

    getSource(): string {
        return this.config.SOURCE;
    }

    isPleinEx(): boolean {
        return this.config.IS_PLEIN_EX;
    }

    isUpdateAccount(): boolean {
        return this.config.IS_UPDATE_ACCOUNT;
    }

    isCELIAPP(): boolean {
        return this.config.TOGGLE_CELI_APP;
    }

    isPDFLang(): boolean {
        return this.config.TOGGLE_PDF_LANG;
    }

    isLBA(): boolean {
        return this.config.TOGGLE_LBA;
    }

    fromExistingRequest(): string {
        return this.config.FROM_EXISTING_REQUEST;
    }

    public isRecoveredRequest(): boolean {
        return !!this.fromExistingRequest();
    }

    public isFromSmartD(): boolean {
        return this.agentDisnatUsername() !== null && this.agentDisnatUsername() !== '';
    }

    public isWebContext(): boolean {
        return this.config.SOURCE === 'WEB' || !this.isFromSmartD();
    }

    public static getAppDefaultRoute(isPleinex: boolean, isLangEn: boolean, isRecovery = false) {

        if (isRecovery) {
            return null;
        }

        if (isPleinex) {
            if (isLangEn) {
                return I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH;
            } else {
                return I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH;
            }
        } else {
            if (isLangEn) {
                return I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH;
            } else {
                return I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH;
            }
        }
    }

    static getDefaultUpdatingRoute(isPleinEx: boolean, isLangEn: boolean, isGpd: boolean = false, isSsd: boolean = false) {
        if (!isPleinEx) {
            if (isLangEn) {
                return I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PATH;
            } else {
                return I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PATH;
            }
        } else {
            if (isGpd) {
                if (isLangEn) {
                    return I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_GPD_PATH;
                } else {
                    return I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_GPD_PATH;
                }
            } else if (isSsd) {
                if (isLangEn) {
                    return I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH;
                } else {
                    return I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH;
                }
            } else {
                if (isLangEn) {
                    return I18N_NAV_MESSAGES_EN.ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH;
                } else {
                    return I18N_NAV_MESSAGES_FR.ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH;
                }
            }
        }
    }

    private static isNullOrEmpty(value: any): boolean {
        return !value || !value.trim();
    }

    goToMainContent(): void {

        if ($('#questionnaire-content').length === 1) {

            if ($('#questionnaire-content h2').first().length === 1) {
                if (typeof $('#questionnaire-content h2').first().attr('tabindex') === 'undefined') {
                    $('#questionnaire-content h2').first().attr('tabindex', '0');
                }
                $('#questionnaire-content h2[tabindex]').first().focus();
            } else {
                $('#questionnaire-content').focus();
            }

        } else {
            $('#content').focus();
        }
    }

    emitStepRoutesConfigKeys(key: string) {
        this.stepRoutesConfigKey = key;
        this.emitStepRoutesConfigKeysSource.next(key);
    }

    emitNext() {
        this.emitNextSource.next();
    }

    emitBack() {
        this.emitBackSource.next();
    }

    emitIsBackDisabled(value: boolean) {
        this.isBackDisabled = value;
        this.emitIsBackDisabledSource.next(value);
    }

    emitStepMetricForm() {
        this.emitStepMetricFormSource.next();
    }

    emitAfterViewInit() {
        this.emitAfterViewInitSource.next();
    }

    checkAssociationRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_ASSOCIATION) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_SSD_PATH', true);
        }
    }

    checkBeneficiaryRRSPActive(userAddressProv: string, addRRSP: boolean) {
        if (userAddressProv !== VmdConstants.QUEBEC && addRRSP) {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP_PERSONAL_ACCOUNT_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP_PERSONAL_ACCOUNT_PATH', true);
        }
    }

    checkBeneficiaryTFSAActive(userAddressProv: string, addTFSA: boolean) {
        if (userAddressProv !== VmdConstants.QUEBEC && addTFSA) {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA_PERSONAL_ACCOUNT_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA_PERSONAL_ACCOUNT_PATH', true);
        }
    }

    checkBeneficiaryFHSAActive(userAddressProv: string, userMaritalStatus: string, addFHSA: boolean) {
        if (userAddressProv !== VmdConstants.QUEBEC && addFHSA) {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_SSD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_GPD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_GPD_PATH', true);
        }
    }

    checkBeneficiaryRESPActive(userAddressProv: string, addRESP: boolean) {
        if (addRESP) {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_SSD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_SSD_PATH', true);
        }
    }

    checkClubRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_CLUB) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_SSD_PATH', true);
        }
    }

    checkCooperativeRelatedQuestionsNav(form: ODCForm) {
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PLEIN_EX_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_GPD_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_SFD_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_SSD_PATH', true);

        if (form.cltType === VmdConstants.CLT_TYPE_COOPERATIVE) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SSD_PATH', true);
        }
    }

    checkCorporationRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_CORPORATION) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SSD_PATH', true);
        }
    }

    checkCoUniqueRelatedQuestionsNav(form: ODCForm) {
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PLEIN_EX_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_GPD_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_SFD_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_SSD_PATH', true);

        if (form.cltType === VmdConstants.CLT_TYPE_CO_UNIQUE) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PATH', true);
        }
    }

    // OCE-6352
    checkDisnatAgent() {
        // todo : remove this.config.BRANCH_ACT once we start using encrypted url
        if (this.agentDisnatUsername() ||
            (!NavigationService.isNullOrEmpty(this.getBranchAct()) && (this.getBranchAct().toUpperCase() === VmdConstants.BRANCH_ACT_NEW)) ||
            (Branch.getInstance() !== null && !NavigationService.isNullOrEmpty(Branch.getInstance().act) && (Branch.getInstance().act.toUpperCase() === VmdConstants.BRANCH_ACT_NEW)) ||
            (!NavigationService.isNullOrEmpty(this.getSource())) && this.getSource().toUpperCase() === VmdConstants.SOURCE_CAISSE) {

            this.setSubTabDisable('ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH', true);
        }
    }

    // OCE-6093
    checkConfirmationPage(isCaisseContext: boolean) {
        if (!this.isPleinEx()) {
            // Personal
            this.setSubTabDisable('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH', isCaisseContext);
            this.setSubTabDisable('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_PERSONAL_ACCOUNT_PATH', !isCaisseContext);

            // Joint
            this.setSubTabDisable('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_JOINT_ACCOUNT_PATH', isCaisseContext);
            this.setSubTabDisable('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_JOINT_ACCOUNT_PATH', !isCaisseContext);

            // Other
            this.setSubTabDisable('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_PATH', isCaisseContext);
            this.setSubTabDisable('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH', !isCaisseContext);
        }
    }

    checkEnfantEsQualitesRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_ENF_ES_QUALITES) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_SSD_PATH', true);
        }
    }

    checkEnfantRefRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_ENF_REF) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_SSD_PATH', false);

        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_SSD_PATH', true);

        }
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH', false);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_GPD_PATH', false);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_SFD_PATH', false);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_SSD_PATH', false);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_PLEIN_EX_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_GPD_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_SFD_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_SSD_PATH', true);
    }

    checkEntityNav(form: ODCForm) {
        if (
            form.cltType === VmdConstants.CLT_TYPE_SOC_COMM
            || form.cltType === VmdConstants.CLT_TYPE_SENC
            || (form.cltType === VmdConstants.CLT_TYPE_FIDUCIE && form.otherFiducieType === VmdConstants.OTHER_FIDUCIE_TYPE_GRANTOR_SIMPLE)
        ) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_SSD_PATH', true);

        }
    }

    checkFiducieRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_FIDUCIE) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_SSD_PATH', false);

        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_SSD_PATH', true);

        }
    }

    checkIppRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_RRI) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_SSD_PATH', false);

        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_SSD_PATH', true);

        }
    }

    checkJointRelatedQuestionsNav(form: ODCForm) {
        if (form.jointActPeople === 2) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SSD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SSD_PATH', true);

        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SSD_PATH', false);
            if (form.jointActPeople === 3) {
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SFD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_GPD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SFD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SSD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SFD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_GPD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SFD_PATH', false);
                this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SSD_PATH', false);
            }
        }
    }

    checkOptionsActived(optionsActived: boolean) {
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT_PERSONAL_ACCOUNT_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_GPD_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_SFD_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_SSD_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_GPD_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_SFD_PATH', !optionsActived);
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_SSD_PATH', !optionsActived);
    }

    checkOsblRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_OSBL) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_SSD_PATH', true);

        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_SSD_PATH', true);

        }
    }

    checkOrgGouvRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_ORG_GOUV) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_SSD_PATH', false);

        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_SSD_PATH', true);
        }
    }

    checkPleinExOtherAccountPersonalInformationNav(form: ODCForm) {
        const isAttorneyPath = VmdConstants.CLT_TYPE_MANDATAIRE === form.cltType;
        const isDeceasedPath = VmdConstants.CLT_TYPE_SUCC === form.cltType;
        const isChildRefPath = VmdConstants.CLT_TYPE_ENF_REF === form.cltType;
        const isChildEsQualitesPath = VmdConstants.CLT_TYPE_ENF_ES_QUALITES === form.cltType;
        const isChildPath = VmdConstants.CLT_TYPE_ENF_ES_QUALITES === form.cltType || VmdConstants.CLT_TYPE_ENF_REF === form.cltType;
        const isGuardianshipPath = VmdConstants.CLT_TYPE_TUTELLE === form.cltType;
        const isCuratorshipPath = VmdConstants.CLT_TYPE_CURATELLE === form.cltType;
        const isIppPath = VmdConstants.CLT_TYPE_RRI === form.cltType;

        const isDefaultPath = !isAttorneyPath && !isDeceasedPath && !isChildPath && !isChildRefPath && !isChildEsQualitesPath && !isGuardianshipPath && !isCuratorshipPath && !isIppPath;

        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PLEIN_EX_PATH', !isDefaultPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GPD_PATH', !isDefaultPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_SFD_PATH', !isDefaultPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_SSD_PATH', !isDefaultPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_PLEIN_EX_PATH', !isAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_GPD_PATH', !isAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_SFD_PATH', !isAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_SSD_PATH', !isAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_PLEIN_EX_PATH', !isDeceasedPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_GPD_PATH', !isDeceasedPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_SFD_PATH', !isDeceasedPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_SSD_PATH', !isDeceasedPath);

        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_GPD_PATH', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_SFD_PATH', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_SSD_PATH', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_1', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_GPD_PATH_1', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SFD_PATH_1', !isChildPath);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SSD_PATH_1', !isChildPath);

        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_PLEIN_EX_PATH', !isChildRefPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_GPD_PATH', !isChildRefPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_SFD_PATH', !isChildRefPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_SSD_PATH', !isChildRefPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_PLEIN_EX_PATH', !isChildEsQualitesPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_GPD_PATH', !isChildEsQualitesPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_SFD_PATH', !isChildEsQualitesPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_SSD_PATH', !isChildEsQualitesPath);

        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_PLEIN_EX_PATH', !isGuardianshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_GPD_PATH', !isGuardianshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_SFD_PATH', !isGuardianshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_SSD_PATH', !isGuardianshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_PLEIN_EX_PATH', !isCuratorshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_GPD_PATH', !isCuratorshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_SFD_PATH', !isCuratorshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_SSD_PATH', !isCuratorshipPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_PLEIN_EX_PATH', !isIppPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_GPD_PATH', !isIppPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_SFD_PATH', !isIppPath);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_SSD_PATH', !isIppPath);
    }

    checkPleinExOtherAddSpouseNav(form: ODCForm) {
        const isChildPath = VmdConstants.CLT_TYPE_ENF_ES_QUALITES === form.cltType || VmdConstants.CLT_TYPE_ENF_REF === form.cltType;
        const isOtherAddSpouse = isChildPath && form.otherAddSpouse;

        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_GPD_PATH', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_SFD_PATH', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_SSD_PATH', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH', !isOtherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_GPD_PATH', !isOtherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_SFD_PATH', !isOtherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_SSD_PATH', !isOtherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_2', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_GPD_PATH_2', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SFD_PATH_2', !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SSD_PATH_2', !isChildPath || !form.otherAddSpouse);

        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PLEIN_EX_PATH, isChildPath && form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SSD_PATH, isChildPath && form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_GPD_PATH, isChildPath && form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SFD_PATH, isChildPath && form.otherAddSpouse);

        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH, !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SSD_PATH, !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_GPD_PATH, !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SFD_PATH, !isChildPath || !form.otherAddSpouse);

        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH, !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SSD_PATH, !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_GPD_PATH, !isChildPath || !form.otherAddSpouse);
        this.setSubTabDisable(NavigationConstants.REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SFD_PATH, !isChildPath || !form.otherAddSpouse);
    }

    public disableNavStepSubmitAccountUpdatePath(disable: boolean) {
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_SSD_PATH', disable);
    }

    public disableNavTransmissionPersonalAccountUpdatePath(disable: boolean) {
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_GPD_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_SSD_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PATH', disable);
    }

    public disableNavReviewUpdateAccountPath(disable: boolean) {
        this.setSubTabDisable('ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PLEIN_EX_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_GPD_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_SSD_PATH', disable);
    }

    public disableNavAccountsChoiceTypeOfManagementUpdateAccountPath(disable: boolean) {
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH', disable);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH', disable);
    }

    checkReferredAccountNav(form: ODCForm) {
        const isRefAccountPath = this.checkRefAccountPath(form);

        if (!isRefAccountPath && form.pleinEx) {
            form.requesters[0].userBankId = null;
            form.requesters[0].userBankBranchNumber = null;
        }

        const isAttorneyPath = VmdConstants.CLT_TYPE_MANDATAIRE === form.cltType;
        const isDeceasedPath = VmdConstants.CLT_TYPE_SUCC === form.cltType;
        const isGuardianshipPath = VmdConstants.CLT_TYPE_TUTELLE === form.cltType;
        const isCuratorshipPath = VmdConstants.CLT_TYPE_CURATELLE === form.cltType;
        const isIppPath = VmdConstants.CLT_TYPE_RRI === form.cltType;
        const isUpdateAccount = this.isUpdateAccount();

        const isInjectableOtherAccount = !isAttorneyPath && !isDeceasedPath && !isGuardianshipPath && !isCuratorshipPath && !isIppPath;

        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_JOINT_ACCOUNT_SSD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_JOINT_ACCOUNT_GPD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_JOINT_ACCOUNT_SFD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SFD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH, !isRefAccountPath);
        this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SFD_PATH, !isRefAccountPath);

        if (isInjectableOtherAccount) {
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH, !isRefAccountPath);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_SSD_PATH, !isRefAccountPath);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_GPD_PATH, !isRefAccountPath);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_SFD_PATH, !isRefAccountPath);
        } else {
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH, true);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_SSD_PATH, true);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_GPD_PATH, true);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_OTHER_ACCOUNT_SFD_PATH, true);
        }

        if (isUpdateAccount && isRefAccountPath) {
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH, true);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH, true);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH, true);
            this.setSubTabDisable(NavigationConstants.REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SFD_PATH, true);
        }
    }

    checkAttorneyOtherAccountPleinExNav(form: ODCForm) {
        const isChildPath = VmdConstants.CLT_TYPE_ENF_ES_QUALITES === form.cltType || VmdConstants.CLT_TYPE_ENF_REF === form.cltType;
        const isCoUniquePath = VmdConstants.CLT_TYPE_CO_UNIQUE === form.cltType;

        const showAttorneyPath = isChildPath || isCoUniquePath;

        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_PLEIN_EX_PATH', !showAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_GPD_PATH', !showAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_SFD_PATH', !showAttorneyPath);
        this.setSubTabDisable('ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_SSD_PATH', !showAttorneyPath);
    }

    checkPleinExModeAutoNav(isModePrint: boolean) { // ref: OCE-5145 //
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_SSD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_GPD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_SFD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_SSD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_GPD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_SFD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_GPD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_SFD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_SSD_PATH', !isModePrint);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_GPD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_SFD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_GPD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_SFD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_SSD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_PLEIN_EX_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_GPD_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_SFD_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_SSD_PATH', isModePrint);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_SSD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_PLEIN_EX_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_GPD_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_SFD_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_SSD_PATH', isModePrint);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_GPD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_SFD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_SSD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_PLEIN_EX_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_GPD_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_SFD_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_SSD_PATH', isModePrint);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_ADD_ACCOUNT_PLEIN_EX_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_ADD_ACCOUNT_SSD_PATH', !isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_ADD_ACCOUNT_PLEIN_EX_PATH', isModePrint);
        this.setSubTabDisable('ODC_NAV_STEP_SUBMIT_ADD_ACCOUNT_SSD_PATH', isModePrint);
    }

    checkSencRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_SENC) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_SSD_PATH', true);
        }
    }

    checkSocCommRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_SOC_COMM) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_SSD_PATH', true);
        }
    }

    checkSuccRelatedQuestionsNav(form: ODCForm) {
        if (form.cltType === VmdConstants.CLT_TYPE_SUCC) {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_GPD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_SFD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_SSD_PATH', false);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_GPD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_SFD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_SSD_PATH', true);

        }
    }

    checkTransferRequestNav(form: ODCForm) {
        const pathSuffix = this.getPathSuffix();
        const transferTypes: string[] = this.isCELIAPP() ? VmdConstants.TRANSFER_REQUEST_TYPES : VmdConstants.TRANSFER_REQUEST_TYPES_TOGGLE_CELIAPP_OFF;

        for (const transferType of transferTypes) {
            const value: boolean = form[transferType];
            if (transferType === 'transfer') {
                this.setSubTabDisable('ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED' + pathSuffix, !value);
            } else {
                const key = transferType.replace('Transfer', '').toUpperCase();
                this.setSubTabDisable('ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_' + key + pathSuffix, !value);
            }
        }
    }

    checkTransmissionPath(isEsignDisabled: boolean, transmissionMethod: string, withAdditionalForms: boolean, isBranch: boolean): void {
        if (isEsignDisabled || this.isPleinEx()) {
            this.forceTransmissionMail(false, isBranch);
        } else if (transmissionMethod === null) {
            this.forceTransmissionChoiceOnly(isBranch);
        } else if (transmissionMethod === VmdConstants.TRANSMISSION_MAIL) {
            this.forceTransmissionMail(true, isBranch);
        } else {
            this.forceTransmissionEsign(withAdditionalForms, isBranch);
        }

    }

    checkCivilStatusPath(form: ODCForm) {
        const isRefAccountPath = this.checkRefAccountPath(form);
        const isEnfChildPath = VmdConstants.CLT_TYPE_ENF_ES_QUALITES === form.cltType || VmdConstants.CLT_TYPE_ENF_REF === form.cltType;

        if (form.pleinEx && isRefAccountPath && !isEnfChildPath) {
            this.changeTabName('ODC_NAV_STEP_CIVIL_STATUS_OTHER_ACCOUNT_PLEINEX', 'ODC_NAV_STEP_REFERENCE');
        }

        if (isEnfChildPath) {
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH, false);
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SSD_PATH, false);
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_GPD_PATH, false);
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SFD_PATH, false);
        } else {
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH, true);
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SSD_PATH, true);
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_GPD_PATH, true);
            this.setSubTabDisable(NavigationConstants.CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SFD_PATH, true);
        }
    }

    checkLegalShareholderPath(form: ODCForm) {
        if (!form.gpd && !form.sfd && form.portfolioManagementMandateOnly) {
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_PLEIN_EX_PATH', true);

            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_SSD_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_SSD_PATH', true);

            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH', true);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_SSD_PATH', true);
        } else {
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_PLEIN_EX_PATH', false);

            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_SSD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_SSD_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_SSD_PATH', false);

            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH', false);
            this.setSubTabDisable('ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_SSD_PATH', false);
        }
    }

    forceTransmissionChoiceOnly(isBranch: boolean): void {
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH', false);

        this.setSubTabAttachmentsPersonalAccount(isBranch);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH', true);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH', true);
    }

    forceTransmissionEsign(withAdditionalForms: boolean = false, isBranch: boolean): void {

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH', false);

        this.setSubTabAttachmentsPersonalAccount(isBranch);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH', !withAdditionalForms);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH', false);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH', true);
    }

    forceTransmissionMail(withChoice: boolean = false, isBranch: boolean): void {
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH', !withChoice);

        this.setSubTabAttachmentsPersonalAccount(isBranch);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH', true);
        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH', true);

        this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH', false);
    }

    setSubTabAttachmentsPersonalAccount(isBranch: boolean) {
        const lbaActivated = this.isLBA();
        if (!this.isRecoveredRequest()) {
            if (isBranch) {
                this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_PATH', lbaActivated);
                this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_CAISSE_PATH', !lbaActivated);
            } else if (this.isWebContext()) {
                this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_PATH', lbaActivated);
                this.setSubTabDisable('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_PATH', !lbaActivated);
            }
        }
    }

    disableSubTabFinancialInfos(disable: boolean = true) {
        this.setSubTabDisable('ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PATH', disable);
    }

    getCurrentTab(): any {
        const routesConfig = AppRoutes.getStepRoutesConfig(this.stepRoutesConfigKey);
        return routesConfig[this.currentTab];
    }

    getCurrentSubTabs(): any[] {
        const currentTab = this.getCurrentTab();
        return currentTab ? currentTab.items : [];
    }

    getCurrentSubTab(): any {
        const currentSubTabs = this.getCurrentSubTabs();
        return (currentSubTabs && currentSubTabs.length > this.currentSubTab) ? currentSubTabs[this.currentSubTab] : null;
    }

    setSubTabDisable(pathKey: string, value: boolean) {

        for (const routeConfig of ODC_ROUTES_CONFIG) {
            for (const config of routeConfig.config) {
                if (config.items && config.items.length) {
                    for (const item of config.items) {

                        const path: string = item.path;

                        if (path === pathKey) {
                            item.disable = value;
                        }
                    }
                }
            }
        }
    }

    changeTabName(pathKey: string, newName: string) {

        for (const routeConfig of ODC_ROUTES_CONFIG) {
            for (const config of routeConfig.config) {
                if (config.label === pathKey) {
                    config.label = newName;
                }
            }
        }
    }

    private getPathSuffix(): string {
        if (this.stepRoutesConfigKey === VmdConstants.JOINT_ACCOUNT_ROUTES_CONFIG_KEY) {
            return '_JOINT_ACCOUNT_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.OTHER_ACCOUNT_ROUTES_CONFIG_KEY) {
            return '_OTHER_ACCOUNT_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY) {
            return '_JOINT_ACCOUNT_PLEIN_EX_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_PLEIN_EX_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY) {
            return '_OTHER_ACCOUNT_PLEIN_EX_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY) {
            return '_JOINT_ACCOUNT_GPD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_GPD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY) {
            return '_OTHER_ACCOUNT_GPD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY) {
            return '_JOINT_ACCOUNT_SSD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_SSD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY) {
            return '_OTHER_ACCOUNT_SSD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.ADD_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY) {
            return '_ADD_ACCOUNT_PLEIN_EX_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_UPDATE_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_UPDATE_SSD_PATH';
        } else if (this.stepRoutesConfigKey === VmdConstants.PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY) {
            return '_PERSONAL_ACCOUNT_UPDATE_GPD_PATH';
        }

        return '';
    }

    private checkRefAccountPath(form: ODCForm): boolean {
        if ((form.repId !== null) && (form.repId.length > 0)) {
            return (form.repId[form.repId.length - 1].toUpperCase() === 'R' || form.repId[form.repId.length - 1].toUpperCase() === 'D');
        }
        return false;
    }

    checkIfTogglePdfIsActivated() {
        this.setSubTabDisable('ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PATH', !this.isPDFLang());
        this.setSubTabDisable('ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_JOINT_ACCOUNT_PLEIN_EX_PATH', !this.isPDFLang());
        this.setSubTabDisable('ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH', !this.isPDFLang());
        this.setSubTabDisable('ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_OTHER_ACCOUNT_PATH', !this.isPDFLang());
    }

    checkOtherAccountSpecificConsentEligibility(form: ODCForm) {
        const cltType = form.cltType;
        const isOtherAccountNotEligible = VmdConstants.CLT_TYPE_CURATELLE === cltType
            || VmdConstants.CLT_TYPE_ENF_REF === cltType
            || VmdConstants.CLT_TYPE_ENF_ES_QUALITES === cltType
            || VmdConstants.CLT_TYPE_MANDATAIRE === cltType
            || VmdConstants.CLT_TYPE_SUCC === cltType
            || VmdConstants.CLT_TYPE_TUTELLE === cltType;

        this.setSubTabDisable('ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_OTHER_ACCOUNT_PLEIN_EX_PATH', isOtherAccountNotEligible);
    }

}
