<div class="table-responsive">
    <table class="table tableau-donnees table-striped text-center">
        <thead>
        <tr>
            <th class="col-md-2 text-center">{{ 'TRANSFER_FORM_SECURITY_DESCRIPTION' | translate }}</th>
            <th class="col-md-5 text-center">{{ 'REVIEW_PREVIOUS_VALUES' | translate }}</th>
            <th class="col-md-5 text-center">{{ 'REVIEW_NEW_VALUES' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let subPage of data.updateSummarySubcategory; let i = index">
            <tr>
                <td [colSpan]="3" [class]="'sous-titre'">
                    <h4 class="h3" [ngStyle]="{'margin':'0'}">
                        {{ subPage.subPageKey | summarySubtitle: pageStep | translate }}
                    </h4>
                </td>
            </tr>
            <ng-container>
                <ng-container *ngFor="let comparisonResult of subPage.comparisonResults; let i = index">
                    <tr>
                        <td title="{{ comparisonResult.fieldName | formatToSummaryQuestion | translate}}">
                            {{ comparisonResult.fieldName | formatToSummaryQuestion | translate | truncate : 50 }}
                        </td>
                        <td app-review-update-account-table-cell
                            [data]="transform(comparisonResult.externalSystemValue, comparisonResult.fieldName)">
                        </td>
                        <td app-review-update-account-table-cell
                            [data]="transform(comparisonResult.internalSystemValue, comparisonResult.fieldName, true)"
                            [highlight]="true">
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
        </tbody>
    </table>
</div>
