<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <app-error-list [form]="form" [ngFormRef]="ngForm" [context]="'app-transmission-attachments-lba-caisse'"></app-error-list>

    <div class="h5" tabindex="0" [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_LABEL_CAISSE' | translate"></div>
    <ul>
        <li [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_ITEM_IDENTITY_PIECE_LABEL_CAISSE' | translate"></li>
        <li [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_ITEM_CHECK_SAMPLE_LABEL' | translate"></li>
    </ul>

    <!-- Type doc 1 -->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form.get('firstIdentityInfos')"
        [submitted]="isFormSubmitted()"
        [label]="'USER_IDENTITY_PAPER' | translate : {index: 1}"
        [id]="'firstIdentityCode'"
        [name]="'firstIdentityCode'"
        [path]="'firstIdentityCode'"
        [list]="userIdentityCodeList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.code')"
        [attrDisabled]="true"
    ></app-odc-field>

    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form.get('firstIdentityInfos')"
        [submitted]="isFormSubmitted()"
        [label]="'USER_IDENTITY_NUMBER' | translate"
        [id]="'firstIdentityIDNumber'"
        [name]="'firstIdentityIDNumber'"
        [path]="'firstIdentityIDNumber'"
        [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.idNumber')"
        [attr]="{maxlength: 32}"
        [attrDisabled]="true"
    ></app-odc-field>

    <app-odc-field *ngIf="!showDateIssuanceExpiryFirstIdentityFileField"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_DATE' | translate"
                   [id]="'firstIdentityExpirationDate'"
                   [name]="'firstIdentityExpirationDate'"
                   [path]="'firstIdentityExpirationDate'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.expirationDate')"
                   [attr]="{maxlength: 10}"
                   [dateFormat]="true"
                   [attrDisabled]="true"
    ></app-odc-field>

    <app-odc-field *ngIf="showProvinceFirstIdentityFile"
                   [type]="constants.FIELD_TYPES.SELECT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_PROVINCE' | translate"
                   [id]="'firstIdentityProvince'"
                   [name]="'firstIdentityProvince'"
                   [controlName]="'firstIdentityProvince'"
                   [path]="'firstIdentityProvince'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.province')"
                   [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                   [list]="provincesList"
                   [attrDisabled]="true"
    ></app-odc-field>

    <app-odc-field *ngIf="showCountryFirstIdentityFile"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_COUNTRY' | translate"
                   [id]="'firstIdentityCountry'"
                   [name]="'firstIdentityCountry'"
                   [path]="'firstIdentityCountry'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.country')"
                   [attr]="{maxlength: 32}"
                   [countryCompleterCountries]="countries"
                   [attrDisabled]="true"
    ></app-odc-field>

    <app-input-file-field
        [form]="form"
        [index]="FIRST_ATTACHED_DOCUMENT_INDEX"
        [label]="'USER_IDENTITY_PAPER' | translate"
        [labelDocument]="'TRANSMISSION_ATTACHED_DOCUMENT_FRONT_LABEL_CAISSE' | translate"
        [labelDocumentBack]="'TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL_CAISSE' | translate"
        [path]="'attachedDocuments.' + FIRST_ATTACHED_DOCUMENT_INDEX"
        [submitted]="isFormSubmitted()"
        [isRequestSaving]="this.formService.isSaving"
        [hasBack]="isDriverLicenseFirstIdentityFileSelected"
    ></app-input-file-field>

    <!-- check sample -->
    <br>
    <h3>{{ 'TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL' | translate }}</h3>
    <app-input-file-field
        [form]="form"
        [index]="ATTACHED_DOCUMENT_CHECK_SAMPLE_INDEX"
        [showLabel]="false"
        [path]="'attachedDocuments.' + ATTACHED_DOCUMENT_CHECK_SAMPLE_INDEX"
        [submitted]="isFormSubmitted()"
        [isRequestSaving]="this.formService.isSaving"
    ></app-input-file-field>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [loading]="loading"></app-questionnaire-btn>

</form>
