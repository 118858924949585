import {Component, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

import {I18N_MESSAGES_EN} from '../../components/i18n/messages_en';
import {I18N_MESSAGES_FR} from '../../components/i18n/messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../../components/i18n/nav-messages_en';
import {I18N_NAV_MESSAGES_FR} from '../../components/i18n/nav-messages_fr';
import {I18N_BTN_MESSAGES_EN} from '../../components/i18n/btn-messages_en';
import {I18N_BTN_MESSAGES_FR} from '../../components/i18n/btn-messages_fr';
import {I18N_ERROR_MESSAGES_EN} from '../../components/i18n/error-messages_en';
import {I18N_ERROR_MESSAGES_FR} from '../../components/i18n/error-messages_fr';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {AddressCompleterService} from '../../components/services/address-completer.service';
import {FormService} from '../../components/services/form.service';
import {SessionService} from '../../components/services/session.service';
import {ModalService} from '../../components/services/modal.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {NavigationService} from '../../components/services/navigation.service';
import {Functions} from '../../components/utils/functions';

declare let $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styles: ['.modal-specific-text{padding: 0 20px;}']
})
export class LayoutComponent implements OnInit, OnDestroy {

    lang: string = VmdConstants.LANG_EN;

    esignLoading = false;
    popupVideoParam: { id: string, title: string } = null;

    private MODAL_SESSION_TIMEOUT = 'modal-session-timeout';
    private SESSION_TTL: number = Functions.minutesToMilliseconds(40);
    private TIME_INTERVAL_SESSION_EXPIRATION_CHECKING_IN_SECONDS: number = 30;
    private TIME_MINUTES_WARN_BEFORE_TIMEOUT: number = Functions.minutesToMilliseconds(2);
    private TIME_COUNTDOWN_IN_SECONDS: number = 30;

    private modalSessionExpirationOpened = false;
    countdown;

    private intervalSessionExpirationId = null;
    private intervalTimerRedirectIntervalId = null;
    private intervalTimerCountdownIntervalId = null;

    constructor(private el: ElementRef,
                private translate: TranslateService,
                @Inject(DOCUMENT) private document: any,
                private titleService: Title,
                private addressCompleterService: AddressCompleterService,
                private router: Router,
                public modalService: ModalService,
                private sessionService: SessionService,
                private formService: FormService,
                private navigationService: NavigationService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
    }

    ngOnInit() {
        this.init();

        this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            const lang = langChangeEvent.lang;

            this.useLang(lang);

            const windowAsAny = window as any;
            if (windowAsAny.OneTrust) {
                windowAsAny.OneTrust.changeLanguage(lang);
            }

            if (this.formService.isQuebecResident()) {
                this.modalService.triggerEnglishQuebecResidentModal();
            }
        });

        this.modalService.esignLoadingEmitted.subscribe((value: boolean) => {
            this.esignLoading = value;
        });

        this.modalService.openVideoPopupEmitted.subscribe((value: { id: string, title: string }) => {
            this.popupVideoParam = value;
            this.modalService.openModal(null, null, '#modal-video');
        });

        $(window).on('popstate', () => {
            const pdfModalId = '#modal-pdf';
            if ($(pdfModalId).is(':visible')) {
                $(pdfModalId).modal('hide');
            }
        });

        setTimeout(() => {
            $('#modal-video').on('show.bs.modal', (e) => {
                $('#player').prop('src', 'https://www.youtube.com/embed/' + this.popupVideoParam.id + '?rel=0&autoplay=1');
            });
            $('#modal-video').on('hide.bs.modal', (e) => {
                this.popupVideoParam = null;
                $('#player').prop('src', 'about:blank');
            });

            $('#modal-esign').on('hide.bs.modal', (e) => {
                this.modalService.emitEsignClosePopup();
            });

        }, 1000);

        this.intervalCheckSessionTimeout();
    }

    ngOnDestroy() {

        if (this.intervalSessionExpirationId) {

            clearInterval(this.intervalSessionExpirationId);
        }
    }

    init() {
        this.translate.setTranslation(VmdConstants.LANG_EN, I18N_MESSAGES_EN, true);
        this.translate.setTranslation(VmdConstants.LANG_EN, I18N_NAV_MESSAGES_EN, true);
        this.translate.setTranslation(VmdConstants.LANG_EN, I18N_BTN_MESSAGES_EN, true);
        this.translate.setTranslation(VmdConstants.LANG_EN, I18N_ERROR_MESSAGES_EN, true);
        this.translate.setTranslation(VmdConstants.LANG_FR, I18N_MESSAGES_FR, true);
        this.translate.setTranslation(VmdConstants.LANG_FR, I18N_NAV_MESSAGES_FR, true);
        this.translate.setTranslation(VmdConstants.LANG_FR, I18N_BTN_MESSAGES_FR, true);
        this.translate.setTranslation(VmdConstants.LANG_FR, I18N_ERROR_MESSAGES_FR, true);
        this.translate.setDefaultLang(VmdConstants.LANG_EN);
        const browserLang = this.translate.getBrowserLang();
        if (typeof this.translate.currentLang === 'undefined') {
            this.useLang(browserLang.match(VmdConstants.ALLOWED_LANGUAGES_REGEX) ? browserLang : VmdConstants.LANG_EN);
        }
    }

    useLang(lang: string) {
        lang = lang.match(VmdConstants.ALLOWED_LANGUAGES_REGEX) ? lang : VmdConstants.LANG_EN;

        this.lang = lang;

        this.document.documentElement.lang = lang;

        const titleEn = I18N_MESSAGES_EN.HEADER_TITLE_PART1 + I18N_MESSAGES_EN.HEADER_TITLE_SUFFIX;
        const titleFr = I18N_MESSAGES_FR.HEADER_TITLE_PART1 + I18N_MESSAGES_FR.HEADER_TITLE_SUFFIX;
        this.titleService.setTitle(lang === VmdConstants.LANG_EN ? titleEn : titleFr);

        if (this.el.nativeElement && this.el.nativeElement.parentElement && this.el.nativeElement.parentElement.nodeName === 'BODY') {
            this.el.nativeElement.parentElement.classList.remove(VmdConstants.LANG_EN);
            this.el.nativeElement.parentElement.classList.remove(VmdConstants.LANG_FR);
            this.el.nativeElement.parentElement.classList.add(lang);
        }

        moment.locale(lang === VmdConstants.LANG_FR ? VmdConstants.LANG_FR_CA : lang);

        this.addressCompleterService.language = lang;

        // Call translate.use after moment.locale update because of event subscribe
        if (this.translate.currentLang !== lang) {
            this.translate.use(lang);
        }
    }

    openModalSessionExpiration(): void {
        this.modalSessionExpirationOpened = true;
        this.modalService.openModal(null, null, '#' + this.MODAL_SESSION_TIMEOUT);
        this.startCountdown(this.TIME_COUNTDOWN_IN_SECONDS);
    }

    closeModalSessionExpiration(): void {

        if (this.isModalActive('modal-session-timeout')) {
            $('#' + this.MODAL_SESSION_TIMEOUT).modal('hide');
            this.invalidateSession();
        } else {
            this.resetExpirationModal();
        }
    }

    isModalActive(idModal: string): boolean {

        const selector = '#' + idModal;

        return $(selector).is(':visible');
    }

    private checkSessionTimeout(): void {

        if (!this.isRouteAtBeginning() && !this.sessionService.hasSessionInProgress()) {

            this.invalidateSession();
        } else if (!this.modalSessionExpirationOpened) {
            const lastSessionUpdate = this.sessionService.getLastSessionUpdate();
            if (lastSessionUpdate) {
                const minutesBeforeSessionDeath: number = this.SESSION_TTL - Math.floor(Date.now() - lastSessionUpdate);

                if (minutesBeforeSessionDeath <= 0) {
                    this.invalidateSession();
                } else if (minutesBeforeSessionDeath <= this.TIME_MINUTES_WARN_BEFORE_TIMEOUT) {
                    this.openModalSessionExpiration();
                }
            } else {
                this.invalidateSession();
            }
        }
    }

    private startCountdown(seconds: number) {
        this.countdown = seconds;

        this.intervalTimerCountdownIntervalId = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
            } else {
                this.onCountdownComplete();
            }
        }, Functions.secondsToMilliSeconds(1));
    }

    private onCountdownComplete() {
        this.closeModalSessionExpiration();
    }

    private isRouteAtBeginning() {

        const route: string = this.router.url;

        return route === '/begin' || route === '/avant-de-debuter';
    }

    extendSession(): void {
        this.sessionService.refreshSession().subscribe();
        this.resetExpirationModal();
    }

    private resetExpirationModal(): void {

        clearTimeout(this.intervalTimerRedirectIntervalId);
        clearInterval(this.intervalTimerCountdownIntervalId);

        this.modalSessionExpirationOpened = false;
    }

    private intervalCheckSessionTimeout(): void {

        this.intervalSessionExpirationId = setInterval(() => {

            if (!this.isRouteAtBeginning()) {
                this.checkSessionTimeout();
            }
        }, Functions.secondsToMilliSeconds(this.TIME_INTERVAL_SESSION_EXPIRATION_CHECKING_IN_SECONDS));
    }

    invalidateSession(): void {

        this.sessionService.resetSession().subscribe();

        this.resetExpirationModal();
        this.formService.initForm({});

        this.sessionService.endSession();

        // close opened modal and popover
        $('.modal').modal('hide');
        $('.help-link').popover('hide');


        let route: string;

        if (this.config.IS_UPDATE_ACCOUNT) {
            route = NavigationService.getDefaultUpdatingRoute(this.translate.currentLang === VmdConstants.LANG_EN, this.config.IS_GPD, this.config.IS_SSD);
        } else {
            route = NavigationService.getAppDefaultRoute(this.config.IS_PLEIN_EX, this.translate.currentLang === VmdConstants.LANG_EN);
        }

        this.router.navigate(['/', route]);
    }

    getBodyExpirationSession(): string {

        return this.translate.instant('EXPIRATION_SESSION_BODY_TIMEOUT_1');
    }
}
