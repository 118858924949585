<ng-container *ngIf="!!messageKey">
    <!-- Composante N3 https://ux-documentation.apps.cfzcea.dev.desjardins.com/documentation/composantes.html#tiroirs_arbre -->
    <div class="panel-group arbre">
        <div class="panel panel-tiroir">
            <div class="panel-heading collapsed" role="tab">
                <h3 class="panel-title">
                    <a href="#collapseA4" aria-expanded="false"  role="button" data-toggle="collapse" aria-expanded="false" aria-controls="collapseA4">
                        {{'BTN_HELP_WARNING' | translate}}
                    </a>
                </h3>
            </div>
            <div id="collapseA4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapseA4">
                <div class="panel-body">
                    <p [innerHTML]="messageKey | translate"></p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
