<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
        {{'USER_IDENTITY_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--userMetAtOffice-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'USER_IDENTITY_MEETING' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'userMetAtOffice'"
        [name]="'userMetAtOffice'"
        [path]="'userMetAtOffice'"
        [binding]="getRequesterPath('userMetAtOffice')"
    ></app-odc-field>

    <div *ngIf="form.get('userMetAtOffice').value" formGroupName="clientIdentity">

        <div *ngIf="isBranch()" class="row">
            <!--userIdentityAdvisorFamilyName--------------------------------->

            <div class="col-md-12">
                <!--userIdentityAdvisorFirstName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('clientIdentity')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_IDENTITY_ADVISOR_FIRST_NAME' | translate"
                    [id]="'userIdentityAdvisorFirstName'"
                    [name]="'userIdentityAdvisorFirstName'"
                    [path]="'userIdentityAdvisorFirstName'"
                    [binding]="getRequesterPath('userIdentityAdvisorFirstName')"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>

            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('clientIdentity')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_IDENTITY_ADVISOR_FAMILY_NAME' | translate"
                    [id]="'userIdentityAdvisorFamilyName'"
                    [name]="'userIdentityAdvisorFamilyName'"
                    [path]="'userIdentityAdvisorFamilyName'"
                    [binding]="getRequesterPath('userIdentityAdvisorFamilyName')"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>

            <div class="col-md-12">
                <!--userIdentityAdvisorBranchName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('clientIdentity')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_IDENTITY_ADVISOR_BRANCH_NAME' | translate"
                    [id]="'userIdentityAdvisorBranchName'"
                    [name]="'userIdentityAdvisorBranchName'"
                    [path]="'userIdentityAdvisorBranchName'"
                    [binding]="getRequesterPath('userIdentityAdvisorBranchName')"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>

            <div class="col-md-12">
                <!--userIdentityVerificationDate--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('clientIdentity')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_IDENTITY_ADVISOR_VERIFICATION_DATE' | translate"
                    [id]="'userIdentityVerificationDate'"
                    [name]="'userIdentityVerificationDate'"
                    [path]="'userIdentityVerificationDate'"
                    [binding]="getRequesterPath('userIdentityVerificationDate')"
                    [attr]="{maxlength: 10}"
                    [dateFormat]="true"
                ></app-odc-field>
            </div>

            <div class="col-md-12">
                <!--userIdentityAdvisorInstitutionName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('clientIdentity')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_IDENTITY_ADVISOR_INSTITUTION' | translate"
                    [id]="'userIdentityAdvisorInstitution'"
                    [name]="'userIdentityAdvisorInstitution'"
                    [path]="'userIdentityAdvisorInstitution'"
                    [binding]="getRequesterPath('userIdentityAdvisorInstitution')"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>

            <div class="col-md-12">
                <!--userIdentityAdvisorTransitName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('clientIdentity')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_IDENTITY_ADVISOR_TRANSIT' | translate"
                    [id]="'userIdentityAdvisorTransit'"
                    [name]="'userIdentityAdvisorTransit'"
                    [path]="'userIdentityAdvisorTransit'"
                    [binding]="getRequesterPath('userIdentityAdvisorTransit')"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>

        </div>

        <!--userIdentityCode--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form.get('clientIdentity')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_IDENTITY_PAPER' | translate"
            [id]="'userIdentityCode'"
            [name]="'userIdentityCode'"
            [controlName]="'userIdentityCode'"
            [path]="'userIdentityCode'"
            [binding]="getRequesterPath('userIdentityCode')"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [list]="identityPaperList"
            [filteredList]="allowedIdentityPaperList"
        ></app-odc-field>

        <!--userIdentityIDNumber-------------------------------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('clientIdentity')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_IDENTITY_NUMBER' | translate"
            [id]="'userIdentityIDNumber'"
            [name]="'userIdentityIDNumber'"
            [path]="'userIdentityIDNumber'"
            [binding]="getRequesterPath('userIdentityIDNumber')"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

        <!--userIdentityExpirationDate-------------------------------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('clientIdentity')"
            [submitted]="isFormSubmitted()"
            [label]="'USER_IDENTITY_DATE' | translate"
            [id]="'userIdentityExpirationDate'"
            [name]="'userIdentityExpirationDate'"
            [path]="'userIdentityExpirationDate'"
            [binding]="getRequesterPath('userIdentityExpirationDate')"
            [attr]="{maxlength: 10}"
            [dateFormat]="true"
        ></app-odc-field>

        <!--userIdentityProvince--------------------------------->
        <div *ngIf="displayIdentityProvince()">
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form.get('clientIdentity')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_IDENTITY_PROVINCE' | translate"
                [id]="'userIdentityProvince'"
                [name]="'userIdentityProvince'"
                [controlName]="'userIdentityProvince'"
                [path]="'userIdentityProvince'"
                [binding]="getRequesterPath('userIdentityProvince')"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [list]="provincesList"
            ></app-odc-field>
        </div>

        <!--userIdentityCountry-------------------------------------------------------->
        <div *ngIf="displayIdentityCountry()">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form.get('clientIdentity')"
                [submitted]="isFormSubmitted()"
                [label]="'USER_IDENTITY_COUNTRY' | translate"
                [id]="'userIdentityCountry'"
                [name]="'userIdentityCountry'"
                [path]="'userIdentityCountry'"
                [binding]="getRequesterPath('userIdentityCountry')"
                [attr]="{maxlength: 32}"
                [countryCompleterCountries]="countries"
            ></app-odc-field>
        </div>

    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>
