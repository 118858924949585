<form [formGroup]="form" ngForm novalidate (submit)="onSubmitForm()">

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-bill"></span>
        {{'TRANSFER_REQUEST_CHOICE_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--hasTransfers-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'TRANSFER_REQUEST_CHOICE_LABEL' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'hasTransfers'"
        [name]="'hasTransfers'"
        [path]="'hasTransfers'"
        [binding]="''"
    ></app-odc-field>

    <div *ngIf="form.get('hasTransfers').value && !isModalActive" formGroupName="transferTypes">
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="getTransferFieldName()"></app-error>
        <fieldset>
            <legend>
                <b>{{'TRANSFER_REQUEST_CHOICE_LIST_LABEL'|translate}}</b>
            </legend>
                <ng-container *ngFor="let transferType of transferTypes">
                    <div class="row" *ngIf="hasAccountType(transferType)">
                        <div class="col-md-12" [class.has-error]="isFieldInError(getTransferFieldName())">
                            <label class="c-input c-checkbox">
                                <input [attr.id]="transferType"
                                       [attr.name]="transferType"
                                       type="checkbox"
                                       formControlName="{{transferType}}"
                                       [appFormBinding]="transferType"
                                       (click)="clickTransferType(transferType)"
                                >
                                <span class="c-indicator"></span> {{ 'ACCOUNT_TYPE_LABEL_' + getAccountKey(transferType) | translate }}
                            </label>
                        </div>
                        <div *ngIf="isPleinEx()" class="col-md-12 transfer-number-copy-box">
                            <input type= "number"
                                   id="{{transferType + FIELD_NUMBER_COPY}}"
                                   name="{{transferType + FIELD_NUMBER_COPY}}"
                                   formControlName="{{transferType + FIELD_NUMBER_COPY}}"
                                   [appFormBinding]="transferType + FIELD_NUMBER_COPY"
                                   maxlength="2"
                                   min="{{MIN_NUMBER_COPY}}"
                                   max="{{MAX_NUMBER_COPY}}"
                                   class="transfer-number-copy-input"
                                   (blur)="blurTransferNumberCopy(transferType)"
                                   [attr.disabled]="hasTransferForType(transferType) ? null : ''"/>
                            <label for="{{transferType + FIELD_NUMBER_COPY}}">
                                {{'TRANSFER_REQUEST_CHOICE_COPY_LABEL' | translate}}
                            </label>
                        </div>
                    </div>
                </ng-container>
        </fieldset>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [loading]="loading"></app-questionnaire-btn>

    <div *ngIf="error !== null" class="alert alert-danger">
        {{'GLOBAL_ERROR_MSG'|translate}}
    </div>
</form>
