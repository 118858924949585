export const NavigationConstants = {
    CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH',
    CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SSD_PATH',
    CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_GPD_PATH: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_GPD_PATH',
    CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SFD_PATH: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SFD_PATH',

    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH',
    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH',
    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SFD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SFD_PATH',

    REFERRED_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH',
    REFERRED_ACCOUNT_JOINT_ACCOUNT_SSD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_SSD_PATH',
    REFERRED_ACCOUNT_JOINT_ACCOUNT_GPD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_GPD_PATH',
    REFERRED_ACCOUNT_JOINT_ACCOUNT_SFD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_SFD_PATH',

    REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH',
    REFERRED_ACCOUNT_OTHER_ACCOUNT_SSD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_SSD_PATH',
    REFERRED_ACCOUNT_OTHER_ACCOUNT_GPD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_GPD_PATH',
    REFERRED_ACCOUNT_OTHER_ACCOUNT_SFD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_SFD_PATH',

    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH',
    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH',
    REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SFD_PATH: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SFD_PATH',

    REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PLEIN_EX_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PLEIN_EX_PATH',
    REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SSD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SSD_PATH',
    REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_GPD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_GPD_PATH',
    REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SFD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SFD_PATH',

    REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH',
    REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SSD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SSD_PATH',
    REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_GPD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_GPD_PATH',
    REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SFD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SFD_PATH',

    REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH',
    REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SSD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SSD_PATH',
    REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_GPD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_GPD_PATH',
    REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SFD_PATH: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SFD_PATH',

    ODC_NAV_STEP_FINANCIAL_INFORMATION_GPD: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_GPD_PATH',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_SFD: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_SFD_PATH',
};
