<h2>
    {{ 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN' | translate }}
</h2>

<form (submit)="onEsignSubmit()" [formGroup]="form" class="clearfix" ngForm novalidate>

    <div *ngIf="isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_CONTACT_INFORMATION_TITLE1_CAISSE' | translate }}
            <br><br> {{ this.formService.getForm().requesters[0].userEmail }}</p>
        <p class="p-b-3">{{ 'ESIGN_CONTACT_INFORMATION_TITLE2_CAISSE' | translate }}</p>
    </div>

    <div *ngIf="!isCaisseContext()" class="h5" tabindex="0">{{ 'ESIGN_CONTACT_INFORMATION_TITLE' | translate }}</div>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div class="m-b-3">
        <!--esignUserPhoneChoice--------------------------------->
        <app-odc-field
            [binding]="'esignUserPhoneChoice'"
            [controlName]="'esignUserPhoneChoice'"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [form]="form"
            [id]="'esignUserPhoneChoice'"
            [label]="getEsignContactInformationUserPhoneLabel() | translate"
            [list]="userPhonesList"
            [name]="'esignUserPhoneChoice'"
            [path]="'esignUserPhoneChoice'"
            [submitted]="isFormSubmitted()"
            [type]="constants.FIELD_TYPES.SELECT"
        ></app-odc-field>

        <div *ngIf="form.get('esignUserPhoneChoice').value === otherValue" formGroupName="otherPhone">
            <!--esignUserPhoneOther------------------------------------------------>
            <app-odc-field
                [attr]="{maxlength: 12}"
                [binding]="'esignUserPhoneOther'"
                [controlName]="'esignUserPhoneOther'"
                [form]="form.get('otherPhone')"
                [id]="'esignUserPhoneOther'"
                [label]="'ESIGN_CONTACT_INFORMATION_USER_PHONE_SPECIFY_OTHER' | translate"
                [name]="'esignUserPhoneOther'"
                [path]="'esignUserPhoneOther'"
                [phoneFormat]="true"
                [submitted]="isFormSubmitted()"
                [type]="constants.FIELD_TYPES.TEXT"
            ></app-odc-field>
        </div>
    </div>
    <hr/>

    <div *ngIf="isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_GUIDE_CAISSE' | translate }}</p>
    </div>

    <div class="row m-t-5">
        <div class="col-md-6">
            <img class="left" height="144px" src="assets/img/digital_signature.png"
                 width="138px">
        </div>
        <div class="col-md-18 p-t-5">
            <button [attr.disabled]="(loading || isSaving) ? true : null"
                    [class.loading]="loading"
                    class="btn btn-primary btn-block loadable"
                    type="submit"
            >
                {{ 'BTN_SIGN' | translate }}
            </button>

            <div *ngIf="isErrorDetected()" class="has-error">
                <div class="bloc-erreur-generique">
                    <p>{{ (isEmailError() ? 'ERROR_ESIGN_INVALID_EMAIL' : 'ERROR_ESIGN') | translate }}</p>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_GUIDE3' | translate }}</p>
    </div>
    <div *ngIf="!isCaisseContext()" class="m-t-3" tabindex="0">
        <p class="p-b-3">{{ 'ESIGN_GUIDE' | translate }}</p>
        <p class="p-b-3">{{ 'ESIGN_GUIDE2' | translate }}</p>
        <p class="p-b-3">{{ 'ESIGN_GUIDE3' | translate }}</p>
    </div>

    <div class="btn-resp btn-resp-1colonne-inline m-t-4">
        <div class="btn-resp-col btn-resp-demi">
            <button (click)="navBack()" *ngIf="showBack()" [attr.disabled]="(loading || isSaving) ? true : null"
                    class="btn btn-default"
                    type="button">
                {{ 'BTN_BACK' | translate }}
            </button>
        </div>
    </div>

</form>
